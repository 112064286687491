// Framework and third-party non-ui
import * as React from "react";

// Hooks, context, and constants
import { request } from "utilities/requests";
import { useConfigContext } from "contexts";

// App components
import { OrdersList } from "./OrdersList";
import { OrderDetails } from "./OrderDetails";

// JSON & Styles
import { ButtonControlContainer, 
    ContentContainer, 
    DrawerContainer, 
    NavButton, 
    OptionsContainer, 
    SearchContainer, 
    TitleContainer,

} from "./Tracker-styled";

// Third-party components (buttons, icons, etc.)
import { TextField, Typography } from "@mui/material";
import { ButtonBox } from "components/ActionBar/SelectMap/SelectMap-styled";

const getMembers = (members) => {  
    let children = [];   
    return members.map(m => {    
        if (m.children && m.children.length) {      
            children = [...children, ...m.children];    
        }    
        return m;  
    }).concat(children.length ? getMembers(children) : children);
};

export const Tracker = ({
    orders={}, 
    onAddOrder=()=>{}, 
    onRemoveOrder=()=>{}, 
    onUpdateAllOrders=()=>{},
    selected=null, 
    onSelectOrder=()=>{}, 
    isMobile=false,
    listOrder, 
    setListOrder}) => {
    /** Internationalization */

    /** Styles */

    /** State */
    const [view, setView] = React.useState('search')
    const [searchValue, setSearchValue] = React.useState('')
    const [searchError, setSearchError] = React.useState(false)
    const ConfigContext = useConfigContext()

    const productTypes = ConfigContext.config.config.data.themes
    const allLayers = getMembers(ConfigContext.config.config.data.customizedLayers.children)
    const layerNameLookup = allLayers.reduce((acc, val)=>({...acc, [val.id]:val.name.split("<div")[0]}), {})
    
    /** Actions */
    const isValidUrl = url => {
        try{
            return Boolean(new URL(url))
        }catch(error){
            return false
        }
    }

    const searchOrder = async (orderId, url) => {

        const params = {
            "order_id": orderId
        }

        try{
            const response = await request(url, params)

            if(response.success){
                const data = response.data;
    
                const date = new Date(data.order.job_submitted);
                const expiration = new Date(date);
                expiration.setDate(expiration.getDate() + 60);
    
                const mapCount = data.maps.length
                let status = 0
    
    
                const maps = data.maps.map((m,idx)=>{
                    const statusLookup = {
                        "0": "In Queue", 
                        "1": "In Queue", 
                        "2": "Delivered",
                        "3": "In Queue",
                        "9": "Failed"
                    }
                    const mapStatus = m.map_status ? statusLookup[m.map_status] : "In Queue"
                    status = mapStatus === "Delivered" ? status + (1 / mapCount) : status
    
                    const productName = Object.keys(ConfigContext.config.config.data.themes).find(id => 
                        ConfigContext.config.config.data.themes[id].name === m.map_type
                    );
                    const displayName = ConfigContext.config.config.data.themes[productName].displayName;
    
                    return {
                        id: m.cell_id > 0 ? `On-Grid`: `${'Custom'}`,
                        name: m.map_sheet_name,
                        type: displayName,
                        format: m.export_format,
                        contour: m.contour_smoothing,
                        layersRemoved: m.layers_removed.map(l=>layerNameLookup[l]).join(", "),
                        expires: expiration.toDateString(),
                        status: mapStatus,
                        url: isValidUrl(m.signed_url) ? m.signed_url  : null
                    }
                })
    
                const order = {
                    id: data.order.order_id,
                    name: data.order.order_id,
                    status: status === 0 
                    ? "Confirmed" 
                    : status === 1 
                    ? "Delivered" 
                    : "Processing",
                    progress: Math.round(status * 100),
                    date: date.toDateString(),
                    maps: maps
                }
    
                return(order)
            }else{
                console.warn(response)
                return null
            }
        }catch(error){
            console.error(error)
            return null;
        }

    }

    const handleSearchInput = (event) => {
        const value = event.target.value
        setSearchValue(value)
        setSearchError(false)
    }

    const handleSearchOrder = async () => {


        const orderId = searchValue.trim()
        const url = ConfigContext.config.config.data.orderTrackingServiceUrl
        const params = {
            "order_id": orderId
        }

        const response = await request(url, params)

        if(response.success){
            const data = response.data;

            const date = new Date(data.order.job_submitted);
            const expiration = new Date(date);
            expiration.setDate(expiration.getDate() + 60);

            const mapCount = data.maps.length
            let status = 0


            const maps = data.maps.map((m,idx)=>{
                const statusLookup = {
                    "0": "In Queue", 
                    "1": "In Queue", 
                    "2": "Delivered",
                    "3": "In Queue",
                    "9": "Failed"
                }
                const mapStatus = m.map_status ? statusLookup[m.map_status] : "In Queue"
                status = mapStatus === "Delivered" ? status + (1 / mapCount) : status

                const productName = Object.keys(ConfigContext.config.config.data.themes).find(id => 
                    ConfigContext.config.config.data.themes[id].name === m.map_type
                );
                const displayName = ConfigContext.config.config.data.themes[productName].displayName;

                return {
                    id: m.cell_id > 0 ? `On-Grid`: `${'Custom'}`,
                    name: m.map_sheet_name,
                    type: displayName,
                    format: m.export_format,
                    contour: m.contour_smoothing,
                    layersRemoved: m.layers_removed.map(l=>layerNameLookup[l]).join(", "),
                    expires: expiration.toDateString(),
                    status: mapStatus,
                    url: isValidUrl(m.signed_url) ? m.signed_url  : null
                }
            })

            const order = {
                id: data.order.order_id,
                name: data.order.order_id,
                status: status === 0 
                ? "Confirmed" 
                : status === 1 
                ? "Delivered" 
                : "Processing",
                progress: Math.round(status * 100),
                date: date.toDateString(),
                maps: maps
            }

            onAddOrder(order)
            if(listOrder.indexOf(order.id) < 0)
                setListOrder([...listOrder, order.id])
            setSearchValue('')
    
            if(isMobile){
                onSelectOrder(order)
                setView('order')
            }
        }else{
            console.warn(response)
            setSearchError(true)
        }
    }

    const handleSelectOrder = (selection) => {
        onSelectOrder(selection)
        setView('order')
    }

    const handleKeyPress = (event)=> {
        if(event.keyCode === 13){
            handleSearchOrder()
        }
    }

    const handleReturn = ()=> {
        setView('search')
        onSelectOrder(null)
    }

    const handleRemoveOrder = (order) => {
        const id = order?.id ? order.id : selected
        onRemoveOrder(id)
    }

    const handleRefreshOrders = async () => {
        const updatedOrders = {}
        for(const order of Object.values(orders)){
            const update = await searchOrder(order.id, ConfigContext.config.config.data.orderTrackingServiceUrl)

            if(update){
                console.log(`Update order ${order.id}:`, update)
                updatedOrders[order.id] = {...update}
            }
        }
        onUpdateAllOrders(updatedOrders)
    }


    /** Effects */

    return(
    <DrawerContainer>
        <ContentContainer p={3} control={view === 'search'}>


            {(view === "search" || !isMobile) &&             
            <>
                <TitleContainer>
                    <Typography variant="h6" component="h2" color="textPrimary">
                        {"Track Your Order"}
                    </Typography>
                </TitleContainer>

                <TitleContainer>
                    <Typography color="textSecondary">{"Enter a confirmation number to track an order"}</Typography>
                </TitleContainer>

                <TitleContainer>
                    <SearchContainer>
                        <TextField
                            id='order-id-input'
                            label={searchError ? "Error" : "Order Confirmation Number"}
                            value={searchValue}
                            onChange={handleSearchInput}
                            fullWidth
                            size='small'
                            onKeyDown={handleKeyPress}
                            error={searchError}
                            helperText={searchError ? "Order not found." : ""}
                        />
                    </SearchContainer>
                </TitleContainer>
                <OptionsContainer>
                    <OrdersList 
                    listOrder={listOrder}
                    orders={listOrder.map(id=>orders[id])} 
                    onSelectOrder={handleSelectOrder} 
                    selected={selected}
                    isMobile={isMobile}
                    onRemove={handleRemoveOrder}
                    />
                </OptionsContainer>            
            </>}

            {view === "order" && isMobile && <>
            <OrderDetails 
                onReturn={handleReturn}
                order={orders[selected]} 
                maps={orders[selected].maps}
                productTypes={productTypes}
             />
            </>}

        </ContentContainer>

        {((isMobile && view !== "order") || (!isMobile)) &&        
        <ButtonControlContainer>
            <ButtonBox>
            {Object.keys(orders).length > 0 &&
            <NavButton
                onClick={handleRefreshOrders}
                variant="outlined"
                color="primary"
            >
                {'Refresh'}
            </NavButton>
            }
            <NavButton
                disabled={searchValue.length < 1}
                onClick={handleSearchOrder}
                variant="contained"
                color="primary"
            >
                {'Track'}
            </NavButton>
            </ButtonBox>
        </ButtonControlContainer>}

    </DrawerContainer>)
}