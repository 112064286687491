// Styling Utilities
import { styled } from "@mui/material/styles";

// Components 
import {
  Box,
  List,
  ListItem,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  ListItemText,
} from "@mui/material";

import DeleteIcon from "calcite-ui-icons-react/TrashIcon";
import ErrorIcon from 'calcite-ui-icons-react/ExclamationMarkCircleFIcon';

// Properties
const mediaBoxHeight = 128;

// Component Exports 

export const OptionsContainer = styled(Box)(({theme}) => ({
  width: "100%",
  overflow: "auto",
  height: "75%"
}))

export const StyledList = styled(List)(({theme}) => ({
  width: "100%",
  overflowX: "hidden",
  overflowY: "auto",
}))


export const StyledListItem = styled(ListItem)(({theme}) => ({
  paddingLeft: 0, 
  paddingRight: 0
}))

const CardWrapper = styled(Card)(({ theme, ...props }) => ({
  width: "100%",
  borderRadius: 0,
  border: props.selected ? "1px solid #005EA2" : "1px solid #DCDEE0",
  boxShadow: 0,
}));

export const StyledCard = styled(CardWrapper)(({theme}) => ({
  boxShadow: "none" 
}))

export const CardMediaContainer = styled(Box)(({theme}) => ({
  height: mediaBoxHeight 
}))

export const StyledCardMedia = styled(CardMedia)(({theme}) => ({
  position: "absolute" 
}))

export const StyledDeleteIconButton = styled(IconButton)(({theme}) => ({
  margin: 0,
  padding: 0,
  marginLeft: "auto",
  "&:hover": {
    background: 0,
    color: theme.palette.error.main,
  },
  colorPrimary: {
    color: theme.palette.grey.mediumLight,
  },
}))

export const StyledDeleteButton = ({...props}) => {
  <StyledDeleteIconButton {...props}>
    <DeleteIcon />
  </StyledDeleteIconButton>
}

export const StyledCardContent = styled(CardContent)(({theme}) => ({
  height: 100, overflow: "hidden" 
}))

export const StyledCardActions = styled(CardActions)(({theme}) => ({
  margin: theme.spacing(1)
}))

export const CardOptionsButton = styled(Box)(({theme}) => ({
  textTransform: "none",
  minWidth: 72,
  fontWeight: theme.typography.fontWeightBold,
  marginLeft: "auto",
}))

export const CardEditButton = styled(Box)(({theme}) => ({
  textTransform: "none",
  minWidth: 72,
  fontWeight: theme.typography.fontWeightBold,
}))

export const CardTitleContainer = styled(Box)(({theme}) => ({
  display: "flex",
}))

export const StyledIconButton = styled(IconButton)(({theme}) => ({
  padding: 0,
  margin: 0,
  paddingRight: theme.spacing(1),
  color: theme.palette.grey.mediumLight,
  "&:hover": {
    background: 0,
    color: theme.palette.blue.light,
  },
}))

export const StyledWarningIcon = styled(ErrorIcon)(({theme}) => ({
  color: theme.palette.error.main,
}))


export const StyledBulletListItem = styled(ListItem)(({theme}) => ({

  "&.MuiListItem-root":{
    border: "1px solid #D6D7D9",
    margin: "1%",
    display: "flex",
    flex: "0 0 48%",
    flexFlow: "column",
    alignItems: "flex-start",
  }
}))

export const SubList = styled(Box)(({theme})=>({
  marginLeft: 0
}))

export const StyledListItemText = styled(ListItemText)(({theme})=>({
  "& .MuiListItemText-primary":{
    display: "flex",
    alignItems: "center",
  }
}))