// Styling Utilities
import { styled } from "@mui/material/styles";

// Components
import {
  ToggleButtonGroup,
  ToggleButton,
  Popover,
  IconButton,
} from "@mui/material";

// Properties

// Component Exports

export const MapContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  width: "100%",
  margin: 0,
  padding: 0,
}));

export const WidgetContainer = styled("div")(
  ({ theme, ismobile, isdraweropen }) => ({
    display: "flex",
    background: "#ffffff",
    position: "absolute",

    zIndex: 1,

    top: ismobile === "false" ? 20 : null,
    left: ismobile === "false" ? (isdraweropen === "true" ? 20 : 20) : null,

    bottom: ismobile === "true" ? 0 : null,
    right: ismobile === "true" ? 0 : null,

    marginRight: "15px",
    marginBottom: "25px",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
  })
);

export const MobileWidgetContainer = styled("div")(({ theme }) => ({
  background: "#ffffff",
  borderRadius: 0,
}));

export const StyledPopover = styled(Popover)(({ theme }) => ({}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  display: "flex",
  background: "#ffffff",
  position: "absolute",
  borderRadius: 0,
  height: 32,
  width: 32,
  zIndex: 1,
  right: 0,
  bottom: 0,
  marginRight: "15px",
  marginBottom: "25px",
  boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
  padding: 0,
  "&:hover": {
    borderRadius: 0,
    backgroundColor: "#eaeaea",
    // color: "#00264C",
  },
}));

export const StyledMobileButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "row",
    },
    "& .MuiToggleButtonGroup-grouped": {
      "&.Mui-selected": {
        color: "white",
        backgroundColor: "#00264C",
      },
    },
  })
);

export const StyledButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  width: "100px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexDirection: "row",
  },
  "& .MuiToggleButtonGroup-grouped": {
    "&.Mui-selected": {
      color: "white",
      backgroundColor: "#00264C",
    },
  },
}));

export const StyledButton = styled(ToggleButton)(({ theme }) => ({
  // border: "none",
  paddingTop: theme.spacing(0.75),
  paddingBottom: theme.spacing(0.75),
  paddingLeft: theme.spacing(0.75),
  paddingRight: theme.spacing(0.75),

  color: theme.palette.grey.dark,
  display: "block",
  "&:hover": {
    borderRadius: 0,
    backgroundColor: "rgba(29, 90, 171, 0.25)",
    color: "#00264C",
    transparency: "0.5",
  },
  "&.Mui-selected": {
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "grey",
      color: "white",
    },
  },
}));

export const StyledMapLoader = styled("div")(({ theme }) => ({
  height: "100vh",
  width: "100vw",
  position: "absolute",
  backgroundColor: theme.palette.black.main,
  opacity: 0.4,
  zIndex: 2,
}));
