// Framework and third-party non-ui
import * as React from "react";

import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import Query from "@arcgis/core/rest/support/Query";
import Polygon from "@arcgis/core/geometry/Polygon";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

// Hooks, context, and constants
import {
  useCartContext,
  useConfigContext,
  useMapContext,
  useProductsContext,
  useUIContext,
} from "contexts";

// App components

// JSON & Styles
import {
  WidgetContainer,
  StyledButtonGroup,
  StyledButton,
  StyledIconButton,
  StyledPopover,
  MobileWidgetContainer,
  StyledMobileButtonGroup,
} from "components/Map/Map-styled";
import { useTheme } from "@mui/material/styles";

// Third-party components (buttons, icons, etc.)
import { useMediaQuery, Typography } from "@mui/material";
import AddInEditIcon from "calcite-ui-icons-react/AddInEditIcon";
import PencilSquareIcon from "calcite-ui-icons-react/PencilSquareIcon";
import MoveIcon from "calcite-ui-icons-react/MoveIcon";

import ToolboxIcon from "calcite-ui-icons-react/CursorMarqueeIcon"; //ProjectsIcon
import * as watchUtils from "@arcgis/core/core/watchUtils";

const Toolbox = ({ initTool, mode, mapTypeName }) => {
  /** Internationalization */

  /** Styles */
  const appTheme = useTheme();
  const isMobile = useMediaQuery(appTheme.breakpoints.down("sm"));

  /** State */
  const ConfigContext = useConfigContext();
  const MapContext = useMapContext();
  const ProductContext = useProductsContext();
  const CartContext = useCartContext();
  const UIContext = useUIContext();

  const { mapView } = MapContext.state;
  const {
    data: { contours, formats },
  } = ConfigContext.config.config;

  const mapType = ProductContext.state.products[mapTypeName]?.type;
  const maps = ProductContext.state.products[mapTypeName]?.maps;
  const cartMaps = CartContext.state.products[mapTypeName]?.maps;

  const [mapEventHandler, setMapEventHandler] = React.useState(null);
  const [tool, setTool] = React.useState(initTool);
  const [anchorEl, setAnchorEl] = React.useState(null);

  /**Actions */

  const handleAddMap = React.useCallback(
    (map) => {
      const addMap = (map) => {
        ProductContext.dispatch({
          type: "add maps",
          payload: {
            maps: [map],
            mapProduct: ProductContext.state.products[mapTypeName],
            cartProducts: CartContext.state.products,
            contours: contours,
            formats: formats,
          },
        });
      };

      // Search all maps for existing settings 
      const productId = ProductContext.state.products ? Object.keys(ProductContext.state.products).find(id=> Object.keys(ProductContext.state.products[id].maps)?.length > 0) : null;

      const mapId =  productId
      ? Object.keys(ProductContext.state.products[productId].maps)?.find(mapId => ProductContext.state.products[productId].maps[mapId].customizedLayers) : null

      const productLayers = mapId
        ? ProductContext.state.products[productId].maps[mapId].customizedLayers
        : null;

      const cartProductId =  CartContext.state.products ? Object.keys(CartContext.state.products).find(id=> Object.keys(CartContext.state.products[id].maps)?.length > 0) : null;
      const cartMapId = cartProductId ? Object.keys(CartContext.state.products[cartProductId].maps)?.find(mapId => CartContext.state.products[cartProductId].maps[mapId].customizedLayers) : null;

      const cartLayers = cartMapId
        ? CartContext.state.products[cartProductId].maps[cartMapId].customizedLayers
        : null;

      const layers = productLayers
        ? productLayers
        : cartLayers
        ? cartLayers
        : null;

      const customizedLayers = layers
        ? layers
        : ConfigContext.config.config.data.customizedLayers;
      map.customizedLayers = customizedLayers;
      addMap(map);
      isMobile && UIContext.setPopup({ id: "continue" });
    },
    [CartContext.state.products, ProductContext, contours, formats, mapTypeName]
  );

  const handleEditMap = (map) => {
    const editMap = (map) => {
      ProductContext.dispatch({
        type: "edit maps",
        payload: {
          maps: [map],
          mapProduct: ProductContext.state.products[mapTypeName],
          contours: contours,
          formats: formats,
        },
      });
    };

    if (mode === "edit cart") {
      // setSelectedProduct({ ...selectedProduct, AOI: { ...selectedProduct.AOI, [aoi.id]: { ...aoi } } });
    } else {
      editMap(map);
    }
  };

  const closeEventHandler = () => {
    if (mapEventHandler) {
      try {
        mapEventHandler.remove();
        setMapEventHandler(null);
      } catch (e) {
        console.warn(e);
      }
    }
  };

  const translatePolygon = (startPoint, currPoint, polygon) => {
    let dx = currPoint.x - startPoint.x;
    let dy = currPoint.y - startPoint.y;
    polygon.rings.forEach((ring) => {
      if (Array.isArray(ring[0])) {
        ring.forEach((coord) => {
          coord[0] += dx;
          coord[1] += dy;
        });
      } else {
        ring[0] += dx;
        ring[1] += dy;
      }
    });
  };

  const handleCleanup = () => {
    try {
      closeEventHandler();
      setTool(null);
    } catch (e) {
      console.warn(e);
    }
  };

  const handleSelectGrid = () => {
    setAnchorEl(null);
    if (tool === "grid") {
      handleCleanup();
      return;
    }
    closeEventHandler();
    setTool("grid");
  };

  const handleSelectCustomGrid = () => {
    setAnchorEl(null);
    if (tool === "custom") {
      handleCleanup();
      return;
    }
    closeEventHandler();
    setTool("custom");
  };

  const handleMoveItem = () => {
    setAnchorEl(null);
    if (tool === "pan") {
      handleCleanup();
      return;
    }
    closeEventHandler();
    setTool("pan");

    let moveGraphic = null;
    let tempGraphic = null;
    let origin = null;

    const handler = mapView?.on("drag", async (e) => {
      // if(!isLeftClick(e)){return}
      //1259
      if (e.action === "start") {
        const response = await mapView.hitTest(e);
        const responseGraphic = response?.results[0]?.graphic;

        if (responseGraphic) {
          e.stopPropagation();

          if (responseGraphic.attributes.custom) {
            moveGraphic = moveGraphic ? moveGraphic : responseGraphic;
            if (moveGraphic?.geometry?.spatialReference?.isWGS84) {
              moveGraphic.geometry = webMercatorUtils.geographicToWebMercator(
                moveGraphic.geometry
              );
            }
            origin = mapView.toMap(e);
          }
        }
      } else if (e.action === "update") {
        if (moveGraphic) {
          e.stopPropagation();
          // if(mapView.graphics.includes(selectedGraphic)){
          // remove graphic (this is original graphic not modified select)
          //   mapView.graphics.remove(selectedGraphic);
          // }
          if (tempGraphic) {
            mapView.graphics.remove(tempGraphic);
            tempGraphic = null;
          }
          tempGraphic = moveGraphic?.clone();
          if (tempGraphic?.geometry && origin) {
            translatePolygon(origin, mapView.toMap(e), tempGraphic.geometry);
            mapView.graphics.add(tempGraphic);
          }
        }
      } else if (e.action === "end") {
        if (tempGraphic?.geometry) {
          // const layer = `${mapType.url.service}/${mapType.url.sublayer}`;
          // const featureLayer = new FeatureLayer({ url: layer });
          const featureLayer = new FeatureLayer({
            url: "https://carto.nationalmap.gov/arcgis/rest/services/govunits/MapServer/22",
          });
          const query = new Query({
            outFields: [],
            returnGeometry: false,
            geometry: tempGraphic.geometry,
            spatialRelationship: Query.SPATIAL_REL_INTERSECTS,
          });
          MapContext.dispatch({
            type: "set loading",
            payload: { isLoading: true },
          });

          featureLayer.queryFeatures(query).then((response) => {
            const { mapId } = tempGraphic?.attributes || {};
            const selectedMap = maps[mapId];
            handleEditMap({
              ...selectedMap,
              geometry:
                response.features?.length > 0
                  ? tempGraphic?.geometry
                  : moveGraphic.geometry,
            });

            moveGraphic = null;
            tempGraphic = null;
            origin = null;
            MapContext.dispatch({
              type: "set loading",
              payload: { isLoading: false },
            });

            if (!(response.features?.length > 0)) {
              UIContext.setPopup({
                id: "move warning",
                title: "Error: Unable to select map at this location",
                content:
                  "OnDemand Topo map products can only be requested within the bounds of a State or Territory of the United States where The National Map data exists.",
              });
            }
          });
        }
      }
    });

    setMapEventHandler(handler);
  };

  const isLeftClick = (e) => {
    let isLeftButton = false;
    if ("button" in e) isLeftButton = e.button === 0;
    else if ("which" in e) isLeftButton = e.button === 0;
    return isLeftButton;
  };

  const handleToolboxClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToolboxCollapse = () => {
    setAnchorEl(null);
  };

  /** Effects */

  // TODO: Set in the handleSelect and remove effect
  // Check tool selection and type
  React.useEffect(() => {
    const updateTool = () => {
      if (tool === "grid") {
        const clickHandler = mapView.on("click", async (event) => {
          if (!isLeftClick(event)) {
            return;
          }

          MapContext.dispatch({
            type: "set loading",
            payload: { isLoading: true },
          });

          const gridLv = mapView.layerViews.items.find(
            (lv) => lv.layer.title === mapType.name
          );

          await watchUtils.whenFalseOnce(gridLv, 'updating')

          let query = gridLv.layer.createQuery();
          query.geometry = event.mapPoint;
          query.spatialRelationship = "intersects";
          query.returnGeometry = true;
          query.outFields = ["*"];

          const response = MapContext.gridState.isGridDisplayScale
            ? await mapView.hitTest(event)
            : await gridLv.layer.queryFeatures(query);

          const results = MapContext.gridState.isGridDisplayScale
            ? response.results
            : response.features;

          const gridResults = MapContext.gridState.isGridDisplayScale
            ? results.filter(
                (r) => r.graphic.layer?.title === gridLv.layer.title
              )
            : results;

          if (gridResults?.length > 0) {
            const graphic = gridResults[0]?.graphic
              ? gridResults[0].graphic
              : gridResults[0];
            const mapItem = graphic;

            const query = gridLv.layer.createQuery();
            query.where = `cell_id='${mapItem.attributes.cell_id}'`;
            query.outFields = ["cell_id"];
            query.returnGeometry = true;
            query.outSpatialReference = mapItem.geometry.spatialReference;

            let response = null;
            try {
              response = await gridLv.layer.queryFeatures(query);
            } catch (e) {
              console.error(e);
            }

            const gridItem =
              response?.features?.length > 0 ? response.features[0] : null;

            const isDuplicate =
              (maps && maps[`${mapItem.attributes.cell_id}grid`]) ||
              (cartMaps && cartMaps[`${mapItem.attributes.cell_id}grid`])
                ? true
                : false;

            if (isDuplicate) {
              UIContext.setPopup({
                id: "move warning",
                title: "Warning: Unable to select duplicate maps",
                content:
                  "Duplicate OnDemand Topo grid selections are not allowed at this time. This includes selections in the cart.",
              });
            } else if (!gridItem) {
              UIContext.setPopup({
                id: "move warning",
                title: "Error: Unable to select map at this location",
                content:
                  "OnDemand Topo map products can only be requested within the bounds of a State or Territory of the United States where The National Map data exists.",
              });
            } else {
              handleAddMap({
                geometry: gridItem.geometry,
                cell: {
                  state: mapItem.attributes?.primary_state,
                  mapSheetName: mapItem.attributes?.cell_name,
                  id: mapItem.attributes?.cell_id,
                },
              });
            }
          } else {
            UIContext.setPopup({
              id: "move warning",
              title: "Error: Unable to select map at this location",
              content:
                "OnDemand Topo map products can only be requested within the bounds of a State or Territory of the United States where The National Map data exists.",
            });
          }

          MapContext.dispatch({
            type: "set loading",
            payload: { isLoading: false },
          });
        });

        return clickHandler;
      }
      if (tool === "custom" && mapView) {
        const clickHandler = mapView.on("click", async (event) => {
          if (!isLeftClick(event)) {
            return;
          }

          const { mapPoint } = event;
          const projMapPoint = webMercatorUtils.webMercatorToGeographic(
            mapPoint.clone()
          );

          MapContext.dispatch({
            type: "set loading",
            payload: { isLoading: true },
          });

          const gridLv = mapView.layerViews.items.find(
            (lv) => lv.layer.title === mapType.name
          );

          await watchUtils.whenFalseOnce(gridLv, 'updating')

          let query = gridLv.layer.createQuery();
          query.geometry = event.mapPoint;
          query.spatialRelationship = "intersects";
          query.returnGeometry = true;
          query.outFields = ["*"];

          const response = MapContext.gridState.isGridDisplayScale
            ? await mapView.hitTest(event)
            : await gridLv.layer.queryFeatures(query);

          const results = MapContext.gridState.isGridDisplayScale
            ? response.results
            : response.features;

          const gridResults = MapContext.gridState.isGridDisplayScale
            ? results.filter(
                (r) => r.graphic.layer?.title === gridLv.layer.title
              )
            : results;

          if (gridResults?.length > 0) {
            const graphic = gridResults[0]?.graphic
              ? gridResults[0].graphic
              : gridResults[0];
            const mapItem = graphic;

            if (mapItem) {
              const customGeom = webMercatorUtils.webMercatorToGeographic(
                mapItem.geometry.clone()
              );

              const { center } = customGeom.extent;
              const xOffset = center.x - projMapPoint.x;
              const yOffset = center.y - projMapPoint.y;

              // update extent location
              const rings = customGeom.rings.map((ring) => {
                const resRing = ring.map((coord) => {
                  const resCoord = [coord[0] - xOffset, coord[1] - yOffset];
                  return resCoord;
                });
                return resRing;
              });
              customGeom.rings = rings;

              // create polygon
              const customPolygon = new Polygon(customGeom.toJSON());

              // TODO: Config
              const validArea = new FeatureLayer({
                url: "https://carto.nationalmap.gov/arcgis/rest/services/govunits/MapServer/22",
              });
              let query = validArea.createQuery();
              query.geometry = customPolygon;
              query.spatialRelationship = "intersects";
              query.returnGeometry = false;
              query.outFields = [];

              const { features } = await validArea.queryFeatures(query);
              const isValidArea = features.length > 0;

              if (isValidArea) {
                handleAddMap({
                  geometry:
                    webMercatorUtils.geographicToWebMercator(customPolygon),
                  cell: {
                    state: "", //empty
                    mapSheetName: mapType?.displayName, //default name in cart
                    id: "", //none
                  },
                  custom: true,
                });
              } else {
                UIContext.setPopup({
                  id: "move warning",
                  title: "Error: Unable to select map at this location",
                  content:
                    "OnDemand Topo map products can only be requested within the bounds of a State or Territory of the United States where The National Map data exists.",
                });
              }
            }
          } else {
            UIContext.setPopup({
              id: "move warning",
              title: "Error: Unable to select map at this location",
              content:
                "OnDemand Topo map products can only be requested within the bounds of a State or Territory of the United States where The National Map data exists.",
            });
          }

          MapContext.dispatch({
            type: "set loading",
            payload: { isLoading: false },
          });
        });
        return clickHandler;
      }
      if (tool === "pan") {
      }

      return null;
    };

    const eventHandler = updateTool();

    return () => {
      try {
        eventHandler.remove();
      } catch (e) {}
    };
  }, [
    handleAddMap,
    mapType?.displayName,
    mapType?.url.service,
    mapView,
    tool,
    MapContext.gridState.isGridDisplayScale,
  ]);

  React.useEffect(() => {
    const updateTool = () => {
      if (!mapType?.name) {
        setTool(null);
      } else {
        setTool("custom");
      }
    };
    updateTool();
  }, [mapType]);


  React.useEffect(()=>{
    return ()=>{
      if(tool === "pan"){
        try {
          mapEventHandler.remove();
          setMapEventHandler(null);
        } catch (e) {
          console.warn(e);
        }
      }

    }
  },[mapEventHandler, mapType, tool])

  return (
    <>
      {mode !== "edit cart" && !isMobile && (
        <WidgetContainer
          ismobile={isMobile.toString()}
          isdraweropen={UIContext.state.drawer.isOpen.toString()}
        >
          <StyledButtonGroup
            role="group"
            variant="text"
            color="primary"
            aria-label="map selection toolbox"
            orientation={isMobile ? "horizontal" : "vertical"}
          >
            {/* Select a grid */}
            <StyledButton
              selected={tool === "grid"}
              onClick={handleSelectGrid}
              value="Grid Selection"
              aria-label="grid selection button"
              disabled={!mapType?.name}
            >
              <div>
                <AddInEditIcon size={24} />
              </div>
              <div style={{ lineHeight: 1.2 }}>
                <Typography
                  align="center"
                  variant="caption"
                  style={{ lineHeight: 1.2, letterSpacing: 0 }}
                >
                  Select by Grid(s)
                </Typography>
              </div>
            </StyledButton>

            {/* Select a custom area */}
            <StyledButton
              selected={tool === "custom"}
              onClick={handleSelectCustomGrid}
              value="Custom Area Selection"
              aria-label="custom grid selection button"
              disabled={!mapType?.name}
            >
              <div>
                <PencilSquareIcon size={24} />
              </div>
              <div style={{ lineHeight: 1.2 }}>
                <Typography
                  align="center"
                  variant="caption"
                  style={{ lineHeight: 1.2, letterSpacing: 0 }}
                >
                  Custom Select
                </Typography>
              </div>
            </StyledButton>

            {/* Pan Map */}
            <StyledButton
              disabled={!maps || Object.keys(maps).length < 1}
              selected={tool === "pan"}
              onClick={handleMoveItem}
              value="Move"
              aria-label="move selection button"
            >
              <div>
                <MoveIcon size={24} />
              </div>
              <div style={{ lineHeight: 1.2 }}>
                <Typography
                  align="center"
                  variant="caption"
                  style={{ lineHeight: 1.2, letterSpacing: 0 }}
                >
                  Move
                </Typography>
              </div>
            </StyledButton>
          </StyledButtonGroup>
        </WidgetContainer>
      )}

      {mode !== "edit cart" && isMobile && (
        <>
          <StyledIconButton
            onClick={handleToolboxClick}
            style={tool ? { color: "white", backgroundColor: "#00264C" } : {}}
          >
            {tool === "custom" && <PencilSquareIcon />}
            {tool === "grid" && <AddInEditIcon />}
            {tool === "pan" && <MoveIcon />}
            {!tool && <ToolboxIcon />}
          </StyledIconButton>
          <StyledPopover
            open={anchorEl ? true : false}
            anchorEl={anchorEl}
            onClose={handleToolboxCollapse}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "rigth",
            }}
            PaperProps={{ style: { borderRadius: 0 } }}
          >
            <MobileWidgetContainer>
              <StyledMobileButtonGroup
                role="group"
                variant="text"
                color="primary"
                aria-label="map selection toolbox"
                orientation={isMobile ? "horizontal" : "vertical"}
              >
                {/* Select a grid */}
                <StyledButton
                  selected={tool === "grid"}
                  onClick={handleSelectGrid}
                  value="Grid Selection"
                  aria-label="grid selection button"
                >
                  <div>
                    <AddInEditIcon size={24} />
                  </div>
                  <div style={{ lineHeight: 1.2 }}>
                    <Typography
                      align="center"
                      variant="caption"
                      style={{ lineHeight: 1.2, letterSpacing: 0 }}
                    >
                      Select by Grid(s)
                    </Typography>
                  </div>
                </StyledButton>

                {/* Select a custom area */}
                <StyledButton
                  selected={tool === "custom"}
                  onClick={handleSelectCustomGrid}
                  value="Custom Area Selection"
                  aria-label="custom grid selection button"
                >
                  <div>
                    <PencilSquareIcon size={24} />
                  </div>
                  <div style={{ lineHeight: 1.2 }}>
                    <Typography
                      align="center"
                      variant="caption"
                      style={{ lineHeight: 1.2, letterSpacing: 0 }}
                    >
                      Custom Select
                    </Typography>
                  </div>
                </StyledButton>

                {/* Pan Map */}
                <StyledButton
                  disabled={!maps || Object.keys(maps).length < 1}
                  selected={tool === "pan"}
                  onClick={handleMoveItem}
                  value="Move"
                  aria-label="move selection button"
                >
                  <div>
                    <MoveIcon size={24} />
                  </div>
                  <div style={{ lineHeight: 1.2 }}>
                    <Typography
                      align="center"
                      variant="caption"
                      style={{ lineHeight: 1.2, letterSpacing: 0 }}
                    >
                      Move
                    </Typography>
                  </div>
                </StyledButton>
              </StyledMobileButtonGroup>
            </MobileWidgetContainer>
          </StyledPopover>
        </>
      )}
    </>
  );
};

export default Toolbox;
