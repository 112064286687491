import * as geoprocessor from "@arcgis/core/rest/geoprocessor";
import esriRequest from "@arcgis/core/request";
import config from "config";

export const gpRequest = async (url, params = {}, options = {}) => {
  let jobInfo = undefined;
  let completeJob = undefined;
  try {
    // Request
    jobInfo = await geoprocessor.submitJob(url, params, options);
    console.log(`Begin job: ${jobInfo.jobId}.`);

    // Wait
    try {
      const { jobId } = jobInfo || {};
      completeJob = await jobInfo.waitForJobCompletion();
      const { jobStatus } = completeJob || {};
      console.log(`Job ${jobId} completed with status: ${jobStatus}.`);

      // Get results
      try {
        const { jobStatus } = completeJob || {};

        if (jobStatus === "job-succeeded") {
          const results = await completeJob.fetchResultData(
            "emailcount",
            options
          );

          return {
            error: false,
            jobINfo: completeJob,
            results: results,
          };
        } else {
          return {
            error: true,
            jobInfo: completeJob,
            errorMessage: {},
          };
        }
      } catch (error) {
        if (error.message === "Invalid or missing input parameters.") {
          try {
            const results = await completeJob.fetchResultData(
              "emailcount",
              options
            );
            return {
              error: false,
              jobInfo: completeJob,
              results: results,
            };
          } catch (e) {}
        }

        console.error(error);
        return {
          error: true,
          jobInfo,
          errorMessage: error,
        };
      }
    } catch (error) {
      console.error(error);
      return {
        error: true,
        jobInfo,
        errorMessage: error,
      };
    }
  } catch (error) {
    console.error(error);
    return {
      error: true,
      jobInfo,
      errorMessage: error,
    };
  }
};

//TODO: config.success config.results: {...data}
export const configRequest = async () => {
  try {
    const configUrl = config.environment.dynamicConfigUrl;
    const response = await esriRequest(configUrl);
    const { data } = response;
    console.log(response);

    return {
      success: true,
      ...data,
    };
  } catch (error) {
    console.log("ERROR");
    console.error(error);
    return {
      success: false,
      data: null,
      release: null,
      error: error?.message,
    };
  }
};

export const notificationRequest = async (url) => {
  try {
    const response = await esriRequest(url);

    const notifications = response?.data?.notifications ? response.data.notifications : [];
    const preview = response?.data?.preview || ""
    const curDate = new Date();

    const filteredNotifications = notifications.filter((notification) => {
      const startDate = new Date(notification.startTime);
      const endDate = new Date(notification.endTime);

      return (
        curDate.getTime() >= startDate.getTime() &&
        curDate.getTime() <= endDate.getTime()
      );
    });

    return {
      success: true,
      data: filteredNotifications,
      preview: preview
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      data: null,
      error: error?.message,
    };
  }
};

export const request = async (url, params) => {
  try{
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    const raw = JSON.stringify(params)
    const response = await esriRequest(
      url, {
        body: raw,
        headers: headers,
        method: "post"
      });

    return {success: true, ...response}
  }catch(err){
    console.error(err)
    return {success: false, ...err}
  }
}
