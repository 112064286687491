// Framework and third-party non-ui
import * as React from "react";
// import { useTranslation } from "react-i18next";

// Hooks, context, and constants
import { useUIContext } from "contexts";

// App components

// JSON & Styles
import { Popup } from "./Popup";

// Third-party components (buttons, icons, etc.)
import { Button } from "@mui/material";

// TODO: improve styling

export const Continue = ({ isOpen, onClose }) => {
  /** Internationalization */
  // const { t } = useTranslation();

  /** State */
  const UIContext = useUIContext();

  /** Actions */

  /** Effects */

  const handleClosePopup = () => {
    onClose();
  };

  const handleCompleteSelection = () => {
    UIContext.toggleDrawer({ isOpen: true, tab: "product maps" });

    onClose();
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClosePopup}
      title="Map Added"
      control={
        <>
          <Button variant="outlined" onClick={handleClosePopup}>
            Yes
          </Button>
          <Button variant="contained" onClick={handleCompleteSelection}>
            No
          </Button>
        </>
      }
    >
      <div> Would you like to select more maps? </div>
    </Popup>
  );
};
