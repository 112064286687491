// Framework and third-party non-ui
import * as React from "react";
// import { useTranslation } from "react-i18next";

// Hooks, context, and constants

// App components

// JSON & Styles
import {
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalDialog,
  ModalActions,
} from "./Modal-styled";

// Third-party components (buttons, icons, etc.)
import { Backdrop, Fade, IconButton, Typography, Button } from "@mui/material";
import CloseIcon from "calcite-ui-icons-react/XIcon";

// TODO: improve styling

export const Notifications = ({ open, setOpen, message }) => {
  /** Internationalization */
  // const { t } = useTranslation();

  /** Styles */

  /** State */
  const notifications = message?.map((notification, idx) => {
    const htmlTitle = notification.title;
    const htmlBody = notification.body;

    const id = idx;
    return (
      <div key={id}>
        {/* Sanitize html if source is no longer on the same server */}
        <div
          style={{
            color: "#303030",
            fontWeight: 500,
            textDecoration: "underline",
          }}
          dangerouslySetInnerHTML={{ __html: htmlTitle }}
        />
        <div
          style={{ color: "#565C65" }}
          dangerouslySetInnerHTML={{ __html: htmlBody }}
        />
      </div>
    );
  });

  const title = "Notifications";
  const content = notifications;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ModalContainer
      aria-labelledby="notifications modal"
      aria-describedby="notifications modal"
      role="main"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalContent>
          {/* Header */}
          <ModalHeader>
            <Typography
              variant="h5"
              component="h1"
              gutterBottom
              color="textPrimary"
            >
              {title}
            </Typography>
            <IconButton
              style={{ position: "absolute", right: 0 }}
              onClick={handleClose}
              aria-label="close modal button"
              disableRipple
            >
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          {/* Content */}
          <ModalDialog>{content}</ModalDialog>
          {/* Actions */}
          <ModalActions>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Close
            </Button>
          </ModalActions>
        </ModalContent>
      </Fade>
    </ModalContainer>
  );
};
