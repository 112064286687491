// Framework and third-party non-ui
import * as React from "react";
import { useTranslation } from "react-i18next";

// Hooks, context, and constants
import { useCartContext } from "contexts";

// App components
import ItemCard from "./ItemCard";

// JSON & Styles
import { TitleContainer } from "../Cart-styled";
import { OptionsContainer, StyledList, StyledListItem } from "./Items-styled";

// Third-party components (buttons, icons, etc.)
import { Typography, ListItemText, Box } from "@mui/material";
import { CustomButton, ListItemFooter } from "../Edit/Edits-styled";

const Items = ({onEditMaps}) => {
  /** Internationalization */
  const { t } = useTranslation();

  /** Styles */

  /** State */
  const { state: {products}, getTotalMapCount } = useCartContext();
  const [productList, setProductList] = React.useState([]);

  /** Actions */
  const handleEditMaps = (product) => {
    onEditMaps(product)
  }

  /** Effects */

  React.useEffect(() => {
    const getList = () => {

      if (products) {
        const list = Object.values(products);
        setProductList(list);
      }
    };
    getList();
  }, [products]);



  let scrollKey = null
  return (
    <>
      {/* Title */}
      <TitleContainer>
        <Typography variant="h6" component="h2" color="textPrimary">
          {t("cart.title")}
        </Typography>
        {/* <Tooltip title={t("cart.tooltip")} arrow aria-label={null}>
          <div style={{marginLeft: "auto"}}>
            <StyledInfoIcon />
          </div>
        </Tooltip> */}
      </TitleContainer>
      {/* Subtitle */}
      <TitleContainer>
        <Typography color="textSecondary">{t("cart.note")}</Typography>
      </TitleContainer>
      {/* Items */}

      <OptionsContainer style={{ flex: "11 11 1px", height: "100%"}}>
        {/* Themes */}
        
        <StyledList>
          {/* Themes */}
          { getTotalMapCount() > 0 && productList.filter(e=>Object.keys(e.maps).length).map((item) => {
            if(!scrollKey)
              scrollKey = item.productId
            return (
              <StyledListItem alignItems="flex-start" key={item.productId} id={item.productId}>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <ItemCard
                        item={item}
                        onEditMaps={handleEditMaps}
                      />
                    </React.Fragment>
                  }
                />
              </StyledListItem>
            );
          })}
        </StyledList>


        
      </OptionsContainer>

      <TitleContainer>
              {getTotalMapCount() > 0 ? (
                <ListItemFooter>
                <CustomButton
                variant="contained"
                disableElevation
                style={{ width: "100%", borderRadius: 0 }}
                onClick={() => {
                  document.getElementById(scrollKey).scrollIntoView();
                }}
                >
                Back to Top
                </CustomButton>
                </ListItemFooter>
                ) : (
                <></>
            )}
      </TitleContainer>

    </>
  );
};

export default Items;
