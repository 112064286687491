// Framework and third-party non-ui
import * as React from "react";

// Hooks, context, and constants

// App components
import { OrderFilter } from "./OrderFilter";
import { OrderTable } from "./OrderTable";
import { OrderCards } from "./OrderCards";
import { ProgressIndicator } from "./ProgressIndicator";


// JSON & Styles
import { TitleContainer, DetailsContainer } from "./Tracker-styled";

// Third-party components (buttons, icons, etc.)
import { IconButton, Typography } from "@mui/material";
import ArrowLeftIcon from "calcite-ui-icons-react/ArrowLeftIcon";

export const OrderDetailsContainer = ({
    onReturn, title, subtitle, isMobile, children
}) => {

return(<>
    <TitleContainer>                                  
        {isMobile && <IconButton onClick={onReturn}> <ArrowLeftIcon/></IconButton>}
        <div>
            <Typography variant="h6" component="h2" color="textPrimary">
            {title}
            </Typography>
            <Typography color="textSecondary">
            {subtitle}
            </Typography>
        </div>
    </TitleContainer>


    <DetailsContainer sx={{display: "flex", flexDirection: "column", paddingBottom: 0}}>
        {children}
    </DetailsContainer>
    </>)
}

export const OrderDetails = ({order={}, maps=[], initialView='card', onReturn=()=>{}, isMobile=true, productTypes}) => {
    /** Internationalization */

    /** Styles */

    /** State */
    const [selectedView, setSelectedView]= React.useState(initialView)
    const [filteredMaps, setFilteredMaps]= React.useState(maps)


    /** Actions */
    const handleFilterMaps = React.useCallback((items=[]) => {
        setFilteredMaps(items)
    },[]);
    
    const handleUpdateView = (view) => {
        setSelectedView(view)
    }

    /** Effects */

    return (
    <OrderDetailsContainer
            onReturn={onReturn} 
            title={order.name} 
            subtitle={`Order Date: ${order.date}`} 
            isMobile={isMobile}
        >
        <ProgressIndicator step={order.status} progress={order.progress}/>
        <OrderFilter 
            maps={maps} 
            onFilter={handleFilterMaps} 
            onChangeView={handleUpdateView} 
            view= {selectedView}
        />
        <Typography> {filteredMaps.length} products </Typography>
        {selectedView === 'table' && <OrderTable maps={filteredMaps} />}
        {selectedView === 'card' && <OrderCards maps={filteredMaps} productTypes={productTypes} />}

    </OrderDetailsContainer>)
}