
import EsriMap from "@arcgis/core/Map";
import Basemap from "@arcgis/core/Basemap";
// import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import TileLayer from "@arcgis/core/layers/TileLayer";
import TileInfo from "@arcgis/core/layers/support/TileInfo";
import Locator from "@arcgis/core/tasks/Locator";

import Expand from "@arcgis/core/widgets/Expand";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import Search from "@arcgis/core/widgets/Search";
import Home from "@arcgis/core/widgets/Home";
import ScaleBar from "@arcgis/core/widgets/ScaleBar";
import MapView from "@arcgis/core/views/MapView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const app = {
  view: null,
  widgets: null,
};

/**
* Initialize the WebMap used in the application
* @returns Promise<`esri/WebMap`>
*/
export const initMap = (MapContext) => {
  const map = new EsriMap({
    // source the basemap from MapContext
    basemap: MapContext.baseMap,
  });
  // any time the basemap of the map changes, reset the basemap in MapContext
  // so we can use it when we recreate the map when the drawer closes and opens in mobile
  map.watch('basemap', (basemap) => {
    MapContext.setBaseMap(basemap)
  } )

  return map;
};

/**
* Initialize the MapView for the application
* @param {`esri/views/MapView`} view
* @returns Promise<void>
*/
export const initView = ({ mapItem, mapRef, config, isMobile, MapContext }) => {
  const layers = Object.keys(config.data.themes).map(
    (id) => config.data.themes[id],
  );

  // TODO: default outfields configurable
  const featureLayers = layers.map((layer) => {
    const fl = new FeatureLayer({
      url: `${layer.url.service}/${layer.url.sublayer}`,
      visible: true,
      title: layer.name, //TODO this could also be layer.name or layer.displayName
      opacity: 0.5,
      labelsVisible: true,
      outFields: ["cell_id", "primary_state", "cell_name", "objectid"],
    });

    // const fl = new MapImageLayer({
    //   url: `${layer.url.service}`,
    //   sublayers: [{ id: layer.url.sublayer }],
    //   visible: false,
    //   title: layer.name, //TODO this could also be layer.name or layer.displayName
    //   opacity: 0.5,
    // });
    mapItem.add(fl);
    return fl;
  });

  const {
    data: { basemaps },
    preferences: { maxSearchResults },
  } = config;

  const view = new MapView({
    map: mapItem,
    container: mapRef.current,
    center: {
      x: -11054390.836159619,
      y: 4275436.9416761035,
      spatialReference: { wkid: 102100 },
    },
    scale: 73957190.94894437,
    constraints: {
      lods: TileInfo.create().lods,
      rotationEnabled: false,
    },
  });

  view.when(() => {
    if (MapContext.selectedMapGeometry) {
      view.goTo(MapContext.selectedMapGeometry);
    }
  });

  view.watch('stationary', (stationary) => {
    if (stationary && view.extent) {
      MapContext.setSelectedMapGeometry(view.extent)
    }
  });
  // All basemaps added to the gallery need to have the same spatial reference. (https://developers.arcgis.com/javascript/latest/api-reference/esri-widgets-BasemapGallery.html)
  const source = basemaps.map(({ value, url, labels, thumbnailUrl }, index) => {
    const localBasemap = new Basemap({
      baseLayers: [
        new TileLayer({
          url: url,
        }),
      ],
      referenceLayers: labels
        ? [
            new TileLayer({
              url: labels,
            }),
          ]
        : [],
      title: value,
      id: index,
      thumbnailUrl: thumbnailUrl,
    });
    return localBasemap;
  });

  const basemapGallery = new BasemapGallery({
    view: view,
    container: document.createElement("div"),
    source: source,
  });
 const basemapGalleryExpand = new Expand({
    view: view,
    content: basemapGallery.container,
    expandIconClass: "esri-icon-basemap",
    expandTooltip: "Basemap Gallery",
    collapseTooltip: "Basemap Gallery",
  });

  // esri-icon-pan2
  //
  //

  if (app.widgets?.search) {
    document.getElementById("search-widget-container")?.remove();
    view.ui.remove(app.widgets.search);
  }
  const searchWidgetContainer = document.createElement(
    "search-widget-container",
  );
  searchWidgetContainer.setAttribute("id", "search-widget-container");

  const search = new Search({
    view: view,
    container: searchWidgetContainer,
    enableInfoWindow: false,
    popupEnabled: false,
    includeDefaultSources: false,

    sources: [
      {
        locator: new Locator({
          url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
        }),
        singleLineFieldName: "SingleLine",
        outFields: ["Addr_type", "Match_addr", "StAddr", "City"],
        name: "ArcGIS World Geocoding Service",
        placeholder: "Find address or place",
        // withinViewEnabled: true,
        suggestionsEnabled: true,
        countryCode: "US",
        maxResults: maxSearchResults,
        maxSuggestions: maxSearchResults,
      },
    ],
  });

  const home = new Home({
    view: view,
  });
  const scaleBar = new ScaleBar({
    view: view,
  });

  view.popup.autoOpenEnabled = false;

  // Add widgets to the ui
  if (!isMobile) {
    view.ui.add(search, "top-right");
  } else {
    if (searchWidgetContainer?.style) {
      searchWidgetContainer.style.display = "flex";
      searchWidgetContainer.style.width = "100%";
    }
    document
      .getElementById("Map")
      .insertAdjacentElement("afterbegin", searchWidgetContainer);
  }

  view.ui.move("zoom", "top-right");

  if (app.widgets?.home) {
    view.ui.remove(app.widgets.home);
  }
  view.ui.add(home, "top-right");

  if (app.widgets?.basemapGalleryExpand) {
    view.ui.remove(app.widgets.basemapGalleryExpand);
  }
  view.ui.add(basemapGalleryExpand, "top-right");

  if (app.widgets?.scaleBar) {
    view.ui.remove(app.widgets.scaleBar);
  }
  view.ui.add(scaleBar, "bottom-left");

  // view.on("click", () => {
  //   console.log(view);
  // });
  app.view = view;
  app.widgets = {
    search,
    home,
    basemapGalleryExpand,
    scaleBar,
  };

  return { view, featureLayers };
};
