// Styling Utilities
import { styled } from "@mui/material/styles";

// Components

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardMedia,
  FormControlLabel,
  Checkbox,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Slider,
  CardContent,
  Card,
  Step,
  CardHeader,
} from "@mui/material";

import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";

import InfoIcon from "calcite-ui-icons-react/InformationFIcon";
import DeleteIcon from "calcite-ui-icons-react/TrashIcon";
import CreateIcon from "calcite-ui-icons-react/PencilIcon";
import ZoomInIcon from "calcite-ui-icons-react/MagnifyingGlassPlusIcon";
import ExclamationMarkTriangleIcon from "calcite-ui-icons-react/ExclamationMarkTriangleIcon";
import AddIcon from "calcite-ui-icons-react/PlusIcon";
import RemoveIcon from "calcite-ui-icons-react/MinusIcon";

// Properties
const mediaBoxHeight = 128;

// Component Exports

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  marginBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.grey.light}`,
  borderRadius: "0px !important",

  "&:hover": {},
  "&.Mui-selected": {
    "&:hover": {},
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme, ...props }) => ({
  background: props.selected ?  theme.palette.blue.light : theme.palette.background,
  height: 15,
  minHeight: theme.spacing(4),
  paddingRight: 5,
  

  "&.Mui-expanded": {
    height: 15,
    minHeight: theme.spacing(4),
  },
  "&.Mui-content": {
    alignItems: "center",
    color: theme.palette.text.default,
  },
  "&.Mui-expandIcon": {
    padding: theme.spacing(1),
  },

  "&	.MuiAccordionSummary-content": {
    ".MuiFormControlLabel-root": {
      // width: "275px", TODO: Another solution for text overflow
      width: "100%",
      marginRight: 0,
    },
  },
}));

export const StyledAccordionSummaryMap = styled(AccordionSummary)(
  ({ theme }) => ({
    background: theme.palette.background,
    height: 15,
    minHeight: theme.spacing(4),

    "&.Mui-expanded": {
      height: 15,
      minHeight: theme.spacing(4),
    },
    "&.Mui-content": {
      alignItems: "center",
      color: theme.palette.text.default,
    },
    "&.Mui-expandIcon": {
      padding: theme.spacing(1),
    },

    "&	.MuiAccordionSummary-content": {
      ".MuiFormControlLabel-root": {
        width: "175px",
        marginRight: 0,
      },
    },
  })
);

export const StyledFormControl = styled(FormControlLabel)(({ theme }) => ({
  width: 150,
  "&:hover": {},
  "& .MuiFormControlLabel-label": {
    display: "grid",
    "&:hover": {},
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  boxShadow: "none",
  marginBottom: 0,
  borderTop: `1px solid ${theme.palette.grey.light}`,
  padding: 0,
  "&:hover": {},
  "&.Mui-selected": {
    "&:hover": {},
  },
}));

export const CardMediaContainer = styled(Box)(({ theme }) => ({
  height: mediaBoxHeight,
  display: "flex",
  "&.Mui-selected": {},
}));

export const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  "&.Mui-media": {
    objectFit: "fill",
  },
}));

export const StyledInfoIcon = styled(InfoIcon)(({ theme, ...props }) => ({
  color: props.inverse ? theme.palette.background.default : theme.palette.blue.light,
  marginLeft: "auto",
  display: "flex",
}));

export const StyledZoomIcon = styled(ZoomInIcon)(({ theme }) => ({
  color: theme.palette.blue.light,
  marginLeft: "auto",
  display: "flex",
}));

export const StyledPencilIcon = styled(CreateIcon)(({ theme }) => ({
  color: theme.palette.blue.light,
  marginLeft: "auto",
  display: "flex",
}));

export const StyledTrashIcon = styled(DeleteIcon)(({ theme }) => ({
  color: theme.palette.blue.light,
  marginLeft: "auto",
  display: "flex",
}));

export const StyledErrorIcon = styled(ExclamationMarkTriangleIcon)(
  ({ theme }) => ({
    color: theme.palette.error.main,
    marginLeft: "auto",
    display: "flex",
  })
);

export const StyledAddIcon = styled(AddIcon)(({ theme }) => ({
  color: theme.palette.blue.light,
  stroke: theme.palette.blue.light,
  strokeWidth: "2px",
  marginLeft: "auto",
  display: "flex",
}));

export const StyledRemoveIcon = styled(RemoveIcon)(({ theme }) => ({
  color: theme.palette.blue.light,
  stroke: theme.palette.blue.light,
  strokeWidth: "2px",
  marginLeft: "auto",
  display: "flex",
  paddingRight: "2px",
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  margin: 0,
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "block",
  alignItems: "center",
  paddingBottom: theme.spacing(1),
}));

export const ContentTitle = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  paddingBottom: theme.spacing(1),
}));

export const ContentDescription = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  paddingBottom: theme.spacing(1),
}));

export const StyledFormatControl = styled(FormControl)(({ theme }) => ({
  color: theme.palette.text.default,
}));

export const FormatSelect = (props) => {
  return (
    <StyledFormatControl
      variant="outlined"
      fullWidth={true}
      margin="dense"
      hiddenLabel={true}
      size="small"
    >
      <Select {...props} />
    </StyledFormatControl>
  );
};

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.text.default,
}));

export const ContourSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.grey.medium,

  "&.MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "4px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  "&.MuiSlider-active": {},
  "&.MuiSlider-valueLabel": {
    left: "calc(-50% + 4px)",
  },
  "&.MuiSlider-track": {
    height: 8,
    borderRadius: 4,
  },
  "&.MuiSlider-rail": {
    height: 8,
    borderRadius: 4,
  },
  "&.MuiSlider-mark": {},

  ".MuiSlider-markLabel": {
    color: theme.palette.blue.light,
  },
}));

export const StyledOrderCard = styled(Card)(({ theme, ...props }) => ({
  marginBottom: theme.spacing(2),
  border: props.selected ?'3px solid #005EA2' : '1px solid rgba(0,0,0,0.25)',
  borderRadius: 0,
  boxShadow: 'none'
}));

export const OrderCardTitle = styled(CardHeader)(({ theme }) => ({
  width: "100%",
  display: "flex",
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(0.5),
  paddingTop: theme.spacing(0.5),
  backgroundColor: 'rgba(240, 240, 240, 1)',
  alignItems: 'center'
}));

export const OrderCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(1),
  width: "100%",
  display: "flex",
  flexDirection: "column",
  paddingBottom: theme.spacing(0.5),
  paddingTop: theme.spacing(0.5),
}));

export const StyledConnector = styled(StepConnector)(({ theme, ...props }) => ({
  display: 'flex',
  width: props.id === 0 ? "0%" : '100%',
  padding: 0,
  left: 0,
  right: 0,
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 5,
    display: 'block',
    position: 'relative'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'rgba(0, 113, 80, 0.5)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#007150',
      padding: 0
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

export const StyledStep = styled(Step)(({ theme, ...props }) => ({
  display: 'flex',
  width: props.id === 0 ? "0%" : '100%',
  padding: 0,
  paddingRight: props.id === 0 ? 0 : 10,
  flex: props.id === 0 ? 0 : 1,

}));