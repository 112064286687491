// Framework and third-party non-ui
import * as React from "react";
// import { useTranslation } from "react-i18next";

// Hooks, context, and constants

// App components

// JSON & Styles
import {
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalDialog,
  ModalActions,
  ContentTextBox,
} from "./Modal-styled";

// Third-party components (buttons, icons, etc.)
import {
  Backdrop,
  Fade,
  IconButton,
  Typography,
  Button,
} from "@mui/material";

import CloseIcon from "calcite-ui-icons-react/XIcon";
// import FilledCircleIcon from "calcite-ui-icons-react/CircleFIcon";

export const LayerDetails = ({ isOpen, onClose, title, content }) => {
  /** Internationalization */
  // const { t } = useTranslation();

  /** Styles */

  /** State */

  /** Actions */
  const handleClose = () => {
    onClose();
  };

  /** Effects */

  return (
    <ModalContainer
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="layer selection details modal"
      aria-describedby="information on custom layer selection"
      role="main"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 100,
      }}
    >
      <Fade in={isOpen}>
        <ModalContent>
          {/* Header */}
          <ModalHeader>
            <Typography
              variant="h5"
              component="h1"
              gutterBottom
              color="textPrimary"
            >
              {title}
            </Typography>
            <IconButton
              style={{ position: "absolute", right: 0 }}
              onClick={handleClose}
              aria-label="close modal button"
            >
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          {/* Content */}
          <ModalDialog>
            <ContentTextBox>
              <Typography color="text.default" sx={{ display: "block" }}>
                {content}
              </Typography>
            </ContentTextBox>
          </ModalDialog>
          {/* Actions */}
          <ModalActions>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Close
            </Button>
          </ModalActions>
        </ModalContent>
      </Fade>
    </ModalContainer>
  );
};
