// Styling Utilities
import { styled } from "@mui/material/styles";

// Components
import { AppBar, Toolbar, Typography, Box } from "@mui/material";

// Properties
const headerHeight = 100;
const subHeaderHeight = 45;

// Component Exports
export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: theme.palette.blue.main,
  height: headerHeight,
  justifyContent: "space-between",
  lineHeight: "normal",
  fontWeight: 400,
  display: "flex",
  width: "100%",
  position: "static",
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingRight: theme.spacing(3),
  height: headerHeight,
}));

export const StyledSubToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme.palette.grey.dark,
  paddingRight: theme.spacing(3),
  height: subHeaderHeight,
  minHeight: theme.spacing(1),

  "@media (min-width: 600px)": {
    minHeight: 0,
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  margin: 0,
  padding: 0,
  lineHeight: 1,
}));

export const HeaderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

export const TitleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const SubtitleBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const LinkBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginLeft: "auto",
}));
