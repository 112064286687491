// Framework and third-party non-ui
import * as React from "react";

// Hooks, context, and constants

// App components

// JSON & Styles

// Third-party components (buttons, icons, etc.)

export const OrderOverlay = ({isDrawerOpen, children}) => {
    /** Internationalization */

    /** Styles */

    /** State */

    /** State */
    

    return(<div style={{
        position: "absolute",
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection:"column",
        zIndex: 1,
        height: '100%',
        width: '100%',
        background: '#E5E5E5',
        display: 'flex',
        // paddingBottom:'82px',
        // marginTop: '-50px', 
        // change for banner open
        paddingLeft: 0,
    }}>
        {children}
    </div>)
}