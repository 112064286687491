// Framework and third-party non-ui

import * as React from "react";

// Hooks, context, and constants

// App components

// JSON & Styles

// Third-party components (buttons, icons, etc.)
// import CheckCircleIcon from "calcite-ui-icons-react/CheckCircleFIcon"
import {Step, StepLabel, Stepper } from "@mui/material";

export const ProgressIndicator = ({step='Confirmed'}) => {
    /** Internationalization */

    /** Styles */

    /** State */
    const steps = [
        {id: 'Confirmed', name: 'Confirmed'}, 
        {id: 'Processing', name: 'Processing'}, 
        {id: 'Delivered', name: 'Delivered'}, 
        {id: 'Expired', name: 'Expired'}
    ]
    const active = steps.findIndex(s=>s.id === step) + 1

    /** Actions */

    /** Effects */

    return (<div style={{padding: '20px 0px 0px 0px'}}>
        <Stepper
            activeStep={active}
            alternativeLabel
        >
            {steps.map((s, i)=><Step key={s.id} id={s.id}>
                <StepLabel 
                StepIconProps={{style:
                    {color: i < active ? "#007150" : "#A9AEB1"}
                }}
                // StepIconProps={{style:
                //     {color: i <= active ? 
                //         (i === active && s.name === "Expired") ? "#A9AEB1" :"#007150" 
                //         : "#A9AEB1"}
                // }}
                componentsProps={{label: {style: {color:"black", fontSize: 12}}}}
                >{s.name}</StepLabel>
            </Step>)}
        </Stepper>
    </div>)
}