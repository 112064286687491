// Framework and third-party non-ui
import * as React from "react";
import { useTranslation } from "react-i18next";

// Hooks, context, and constants

// App components
import themeImages from "assets/img/productThemes";

// JSON & Styles
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledCardMedia,
  CardMediaContainer,
  StyledInfoIcon,
  StyledIconButton,
} from "./Card-Styled";

// Third-party components (buttons, icons, etc.)
import { AccordionActions, Typography, Tooltip } from "@mui/material";

import AddIcon from "calcite-ui-icons-react/PlusIcon";
import RemoveIcon from "calcite-ui-icons-react/MinusIcon";

const ProductCard = ({
  selected,
  theme,
  handleToggleSelection,
  handleViewTypeDetails,
  isCollapsible = true, 
  ...props
}) => {
  /** Internationalization */
  const { t } = useTranslation();

  /** Styles */

  /** State */

  /** Actions */
  const handleExpandCard = () => {
    if(!isCollapsible) return
    handleToggleSelection();
  };

  const handleViewMoreInfo = (event) => {
    event.stopPropagation();
    handleViewTypeDetails(theme);
  };

  return (
    <StyledAccordion
      expanded={selected}
      onChange={handleExpandCard}
      style={selected ? { border: "3px solid #005EA2" } : {}}
      id={props.id}
    >
      <StyledAccordionSummary
        id={`ProductCard-${theme.name.replace(/\s/g, "")}`}
        aria-label={`product card - ${theme.displayName}`}
        aria-controls={`expand-${theme.displayName.replace(/\s/g, "")}`}
        expandIcon={!isCollapsible ? <></> : selected ? <RemoveIcon /> : <AddIcon />}
        role="form"
        sx={{ padding: 0 }}
      >
        {/* // width: "275px", TODO: Another solution for text overflow */}
        <label style={{ marginRight: 0 }}>
          <Typography
            gutterBottom
            variant="body1"
            component="h3"
            style={{
              color: "#1B1B1B",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              margin: 0,
              paddingLeft: "5px",
            }}
          >
            {theme.displayName}
          </Typography>
        </label>

        <AccordionActions style={{ padding: 0, marginLeft: "auto" }}>
          <Tooltip title={t("product.themeTab.info")} arrow>
            <StyledIconButton
              aria-label={`view more info ${theme.displayName}`}
              alt="View More Info"
              onClick={handleViewMoreInfo}
            >
              <StyledInfoIcon fontSize="small" />
            </StyledIconButton>
          </Tooltip>
        </AccordionActions>
      </StyledAccordionSummary>

      <StyledAccordionDetails style={{ display: "block" }}>
        <CardMediaContainer>
          <StyledCardMedia
            component="img"
            alt={theme.displayName}
            height="128"
            image={themeImages[theme.image]}
            title={theme.displayName}
          />
        </CardMediaContainer>
        {props.children}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default ProductCard;
