// Styling Utilities
import { styled } from "@mui/material/styles";

// Components
import { Box, Button } from "@mui/material";
import InfoIcon from "calcite-ui-icons-react/InformationFIcon";

// Properties
const buttonContainerHeight = 70;

// Component Exports

export const DrawerContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  padding: 10,
  paddingBottom: 72,
  height: "inherit",
  scrollBehavior: "smooth",
  display: "flex",
  flexDirection: "column",
}));

export const ButtonControlContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  alignItems: "center",
  display: "flex",
  width: "100%",
  height: buttonContainerHeight,
  bottom: 0,
  backgroundColor: theme.palette.background.default,
  boxShadow:
    "0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)",
}));

export const NavButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    marginRight: theme.spacing(2),
  },
}));

export const ButtonBox = styled(Box)(() => ({
  marginLeft: "auto",
}));

export const TitleContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
}));

export const OptionsContainer = styled(Box)(({ theme }) => ({
  flex: "11 11 1px",
  height: "100%",
  width: "100%",
  overflow: "auto",
}));

export const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.blue.light,
  marginLeft: "auto",
}));
