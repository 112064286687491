// Styling Utilities
import { styled } from "@mui/material/styles";

// Components
import {
  Modal,
  Box,
  List,
  ListItem,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";

// Properties

// Component Exports

// export const useStyles = makeStyles((theme) => ({
//   paper: {
//     backgroundColor: theme.palette.background.paper,
//     border: "none",
//     // boxShadow: theme.shadows[5],
//     boxShadow: "none",
//     padding: theme.spacing(2, 4, 3),
//     height: 400,
//     width: 600,
//     display: "flex",
//     flexDirection: "column",
//   },
//   modal: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
// }));

export const ModalContainer = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  display: "flex",
  flexDirection: "column",

  backgroundColor: theme.palette.background.paper,
  border: "none",
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  height: 400,
  width: 600,
  [theme.breakpoints.down("md")]: {
    height: "50vh",
    width: "75vw",
  },
}));

export const ModalHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  position: "relative",
}));

export const ModalDialog = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  heigth: "75%",
  overflow: "auto",
}));

export const ModalActions = styled(Box)(({ theme }) => ({
  display: "flex",
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginTop: "auto",
}));

export const StyledList = styled(List)(({ theme }) => ({
  width: "100%",
  overflowX: "hidden",
  overflowY: "auto",
  height: "auto",
  marginTop: theme.spacing(2),
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
}));

export const ContentTextBox = styled(Box)(({ theme }) => ({
  paddingBottom: 25,
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  minWidth: 150,
  "& .MuiDialog-paper": {
    borderRadius: 0,
    minWidth: 150,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const StyledDialogTitle = styled(Box)(({ theme }) => ({
  m: 0,
  p: 2,
  display: "flex",
  justifyContent: "center",
  position: "relative",
  alignItems: "center",
  color: theme.palette.blue.darkest,
  padding: 16,
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  color: theme.palette.black.main,
  fontWeight: 500,
}));

export const StyledDialogActions = styled(DialogActions)(() => ({}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: "auto",
  padding: "0 5px 0 5px",
  color: (theme) => theme.palette.grey[500],
  "&:hover": { background: 0, color: theme.palette.blue.darkest },
  position: "absolute",
  right: 0,
}));
