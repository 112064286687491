// Framework and third-party non-ui
import * as React from "react";
import { useTranslation } from "react-i18next";

// Hooks, context, and constants
import {
  useProductsContext,
  useUIContext,
  useCartContext,
  useMapContext,
  useConfigContext,
} from "contexts";

// App components
import { ProductCard, MapCard } from "components/Card";

// JSON & Styles
import {
  DrawerContainer,
  ContentContainer,
  ButtonControlContainer,
  NavButton,
  TitleContainer,
  OptionsContainer,
  StyledList,
  StyledListItem,
  ButtonBox,
  CardBox,
  CustomButton,
  ListItemFooter,
} from "./SelectMap-styled";
import { useTheme } from "@mui/material/styles";

// Third-party components (buttons, icons, etc.)
import {
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
  useMediaQuery,
} from "@mui/material";
import LayersIcon from "calcite-ui-icons-react/LayersIcon";

export const Maps = ({ onChangeView }) => {
  /** Internationalization */
  const { t } = useTranslation();

  /** Styles */

  /** State */

  const appTheme = useTheme();
  const isMobile = useMediaQuery(appTheme.breakpoints.down("sm"));

  const ProductsContext = useProductsContext();
  const UIContext = useUIContext();
  const CartContext = useCartContext();
  const MapContext = useMapContext();
  const ConfigContext = useConfigContext()

  const { config } = useConfigContext();
  const {
    data: { contours, formats },
  } = config.config;

  const products = ProductsContext.state.products;
  const visibleProducts = ProductsContext.getSelectedProducts();

  const [selectedProduct,  setSelectedProduct] = React.useState(
    Object.keys(products).find(p=>products[p].selected === true) ? products[Object.keys(products).find(p=>products[p].selected === true)]: null
  );

  Object.keys(products).find(p=>products[p].selected === true)

  const mapCount = CartContext.getTotalMapCount();
  const disableButtons =
    !selectedProduct ||
    !Object.keys(products[selectedProduct.productId].maps).find(
      (key) => products[selectedProduct.productId].maps[key].checked
    );
  const disableCartButton = mapCount > 0 ? false : true;

  const [expandButtonText, setExpandButtonText] = React.useState("Toggle All");
  const [selectButtonText, setSelectButtonText] = React.useState("Select All");

  /** Actions */
  const handleAddToCart = () => {
    const cartProducts = Object.keys(products[selectedProduct.productId].maps)
      .map((mapId) => products[selectedProduct.productId].maps[mapId])
      .filter((map) => map.checked);

    const limit = ConfigContext.config.config.preferences.cartLimit

    if(cartProducts.length + mapCount > limit){

      UIContext.setPopup({
        id: "map limit warning",
        title: "Error: Cart Limit Exceeded",
        content:
          `The cart can hold a maximum of ${limit} map products. Please reduce the number of maps being added to the cart, remove maps from the cart, or check out your current cart prior to adding more maps.`,
      });

      return
    }


    CartContext.dispatch({
      type: "add products",
      payload: {
        products: [
          {
            ...selectedProduct,
            type: selectedProduct.type,
            maps: cartProducts,
          },
        ],
        formats: formats,
      },
    });
    ProductsContext.dispatch({
      type: "remove maps",
      payload: {
        maps: cartProducts,
        mapProduct: selectedProduct,
        contours,
        formats,
      },
    });

    isMobile &&
      UIContext.toggleDrawer({ isOpen: true, tab: "cart", content: "cart" });
  };

  const handleDeleteMaps = () => {
    const remove = () => {
      const deleteMaps = Object.keys(products[selectedProduct.productId].maps)
        .map((mapId) => products[selectedProduct.productId].maps[mapId])
        .filter((map) => map.checked);
      ProductsContext.dispatch({
        type: "remove maps",
        payload: {
          maps: deleteMaps,
          mapProduct: selectedProduct,
          contours,
          formats,
        },
      });
    };

    const sessionStorageState = sessionStorage.getItem("usgs-dynamap-storage");
    if (sessionStorageState) {
      const { showDeleteWarning } = JSON.parse(sessionStorageState);
      if (showDeleteWarning === false) {
        remove();
      } else {
        UIContext.setPopup({
          id: "delete map warning",
          content: { handleDeleteMaps: remove },
        });
      }
    } else {
      UIContext.setPopup({
        id: "delete map warning",
        content: { handleDeleteMaps: remove },
      });
    }
  };

  const handleGoToCart = () => {
    UIContext.toggleDrawer({ isOpen: true, tab: "cart", content: "cart" });
  };

  const handleSelectProduct = (product) => {
    if (product.productId === selectedProduct?.productId) {
      //compress and remove grid
      setSelectedProduct(null);
    } else {
      //highlight and compress others
      setSelectedProduct(product);
    }
  };

  const handleToggleAllMaps = () => {
    const mapProduct = products[selectedProduct.productId];
    const collapsed = Object.keys(mapProduct.maps).find(
      (mapId) => mapProduct.maps[mapId].expanded === false
    );

    const allMapsExpanded = collapsed ? false : true;

    if (allMapsExpanded) {
      const updatedMaps = Object.keys(mapProduct.maps).map((mapId) => {
        return { ...mapProduct.maps[mapId], expanded: false };
      });

      ProductsContext.dispatch({
        type: "edit maps",
        payload: {
          maps: updatedMaps,
          mapProduct: mapProduct,
          contours,
          formats,
        },
      });
    } else {
      const updatedMaps = Object.keys(mapProduct.maps).map((mapId) => {
        return { ...mapProduct.maps[mapId], expanded: true };
      });

      ProductsContext.dispatch({
        type: "edit maps",
        payload: {
          maps: updatedMaps,
          mapProduct: mapProduct,
          contours,
          formats,
        },
      });
    }
  };

  const handleToggleMap = (map, mapProduct) => {
    const expanded = !map.expanded;
    const updatedMap = { ...map, expanded };
    ProductsContext.dispatch({
      type: "edit maps",
      payload: {
        maps: [updatedMap],
        mapProduct: mapProduct,
        contours,
        formats,
      },
    });
  };

  const handleCheckAllMaps = (maps, product) => {
    const notChecked = Object.keys(maps).find(
      (key) => maps[key].checked === false
    );
    const updatedMaps = Object.keys(maps).map((key) => {
      return { ...maps[key], checked: notChecked ? true : false };
    });
    ProductsContext.dispatch({
      type: "edit maps",
      payload: { maps: updatedMaps, mapProduct: product, contours, formats },
    });
  };

  const handleViewTypeDetails = (productType) => {
    UIContext.setPopup({
      id: "theme details",
      content: { theme: productType },
    });
  };

  const handleToggleGridVisibility = () => {
    MapContext.setLayerVisibility(!MapContext.gridState.isGridVisible);
  };

  const handleZoomToGrid = () => {
    const layers = MapContext.state.mapView.layerViews.items;
    const gridLayer = layers.find(
      (layer) => layer.layer.title === selectedProduct.productId
    );
    if (gridLayer) {
      MapContext.state.mapView.scale = gridLayer.layer.minScale;
      MapContext.state.mapView.zoom = MapContext.state.mapView.zoom + 1;
      MapContext.setLayerVisibility(true);
    }
  };

  /** Effects */
  React.useEffect(() => {
    const updateExpandButtonText = () => {
      if (selectedProduct) {
        const mapProduct = products[selectedProduct.productId];
        const collapsed = Object.keys(mapProduct.maps).find(
          (mapId) => mapProduct.maps[mapId].expanded === false
        );

        const allMapsExpanded = collapsed ? false : true;
        const text = allMapsExpanded ? "Hide Options" : "Show Options";
        setExpandButtonText(text);
      }
    };
    updateExpandButtonText();
  }, [products, selectedProduct]);

  React.useEffect(() => {
    const updateSelectButtonText = () => {
      if (selectedProduct) {
        const mapProduct = products[selectedProduct.productId];
        const notChecked = Object.keys(mapProduct.maps).find(
          (key) => mapProduct.maps[key].checked === false
        );

        const text = notChecked ? "Select All" : "Unselect All";
        setSelectButtonText(text);
      }
    };
    updateSelectButtonText();
  }, [products, selectedProduct]);

  // open mobile popup TODO: move to toolbox
  // React.useEffect(() => {
  //   const handleMobilePopup = () => {
  //     if (!selectedProduct) {
  //       return;
  //     }
  //     const productMapCount = Object.keys(
  //       products[selectedProduct.productId]?.maps
  //     ).length;

  //     if (isMobile && productMapCount !== mapSelectionCount) {
  //       setMapSelectionCount(productMapCount);

  //       if (!UIContext.state.drawer.isOpen) {
  //         UIContext.setPopup({ id: "continue" });
  //       }
  //     }
  //   };
  //   handleMobilePopup();
  // }, [
  //   UIContext.state.drawer.isOpen,
  //   isMobile,
  //   mapSelectionCount,
  //   products,
  //   selectedProduct,
  //   UIContext,
  // ]);

  // set selected product
  React.useEffect(() => {
    if (selectedProduct) {
      MapContext.setMapType(
        selectedProduct.type?.name,
        Object.values(
          ProductsContext.state.products[selectedProduct.type?.name].maps
        )
      );
    } else {
      MapContext.setMapType(null, []);
    }

    return () => {};
  }, [MapContext, selectedProduct, ProductsContext.state.products]);

  let scrollKey = null
  return (
    <DrawerContainer>
      <ContentContainer p={3}>
        {/* Title */}
        <TitleContainer>
          <Typography variant="h6" component="h2" color="textPrimary">
            {t("product.aoiTab.header")}
          </Typography>
          {/* <Tooltip title={t("product.aoiTab.tooltip")} arrow>
                    <div style={{marginLeft: "auto"}} aria-label={null}>
                    <StyledInfoIcon />
                    </div>
                    </Tooltip> */}
        </TitleContainer>

        {/* Subtitle */}
        <TitleContainer>
          <Typography color="textSecondary">
            {t("product.aoiTab.note")}
          </Typography>
        </TitleContainer>

        <TitleContainer>
          {visibleProducts.length > 0 && (
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={handleToggleGridVisibility}
                    checked={MapContext.gridState.isGridVisible}
                    disabled={!MapContext.gridState.isGridDisplayScale}
                  />
                }
                label={
                  <div 
                  style={{ 
                    color: MapContext.gridState.isGridDisplayScale ? 
                    MapContext.gridState.isGridVisible ? '#1D5AAB' : 'grey'
                    : '#1D5AAB',
                    cursor: 'pointer'}}
                  onClick={() => {
                    if(!MapContext.gridState.isGridDisplayScale){
                      handleZoomToGrid();
                      MapContext.setLayerVisibility(false);
                    }
                    return
                  }}
                  >
                    {MapContext.gridState.isGridDisplayScale ? "Display Grid" : "Zoom in to display Grid"}
                  </div>
                }
              /> 
            </FormGroup>
          )}
        </TitleContainer>

        <OptionsContainer>
          {/* Themes */}
          {visibleProducts.length > 0 && (
            <StyledList>
              {Object.keys(products).filter(key=>products[key].selected).map((key) => {
                const product = products[key];
                const { type, maps } = product;
                // const selected =
                //   product.productId === selectedProduct?.productId;

                return (
                  <StyledListItem alignItems="flex-start" key={key}>
                    {/* Product with Type and Maps */}
                    <ProductCard
                      key={key}
                      id={key}
                      selected={true}
                      theme={type}
                      handleToggleSelection={() => {
                        handleSelectProduct(product);
                      }}
                      handleViewTypeDetails={handleViewTypeDetails}
                      isCollapsible={false}
                    >
                      {Object.keys(maps).length > 0 ? (
                        <CardBox>
                          <CustomButton
                            onClick={() => {
                              handleCheckAllMaps(maps, product);
                            }}
                            variant="text"
                          >
                            {selectButtonText}
                          </CustomButton>
                          <CustomButton
                            onClick={handleToggleAllMaps}
                            variant="text"
                          >
                            {expandButtonText}
                          </CustomButton>
                          <CustomButton
                            // style={{ marginLeft: "auto" }}
                            onClick={() => {
                              onChangeView("layers", selectedProduct);
                            }}
                            variant="text"
                          >
                            <LayersIcon
                              size={20}
                              style={{ paddingRight: "4px" }}
                            />
                            Edit Layers
                          </CustomButton>
                        </CardBox>
                      ) : (
                        <CardBox>
                          <Typography>Select a map to begin</Typography>
                        </CardBox>
                      )}
                      {Object.keys(maps)
                        .reverse()
                        .map((mapId) => {
                          scrollKey = key
                          const productMap = maps[mapId];
                          return (
                            <MapCard
                              key={mapId}
                              expanded={productMap.expanded}
                              map={productMap}
                              product={product}
                              handleToggleMap={handleToggleMap}
                            />
                          );
                        })}
                    </ProductCard>
                  </StyledListItem>
                );
              })}
            </StyledList>
          )}
        </OptionsContainer>

        <TitleContainer>
        {scrollKey ? (
          <ListItemFooter>
            <CustomButton
              variant="contained"
              disableElevation
              style={{ width: "100%", borderRadius: 0 }}
              onClick={() => {
                document.getElementById(scrollKey).scrollIntoView();
              }}
            >
              Back to Top
            </CustomButton>
          </ListItemFooter>
        ) : (
          <></>
        )}
        </TitleContainer>
      </ContentContainer>

      <ButtonControlContainer>
        <ButtonBox>
          <NavButton
            disabled={disableButtons}
            onClick={handleDeleteMaps}
            variant="outlined"
            color="error"
          >
            Delete
          </NavButton>
          <NavButton
            disabled={disableButtons}
            onClick={handleAddToCart}
            variant="contained"
            color="primary"
          >
            Add
          </NavButton>
          {!isMobile && (
            <NavButton
              disabled={disableCartButton}
              onClick={handleGoToCart}
              variant="contained"
              color="secondary"
            >
              Go to Cart
            </NavButton>
          )}
        </ButtonBox>
      </ButtonControlContainer>
    </DrawerContainer>
  );
};
