// Framework and third-party non-ui
import * as React from "react";

// Hooks, context, and constants

// App components

// JSON & Styles
import { SearchContainer } from "./Tracker-styled";

// Third-party components (buttons, icons, etc.)
import { Chip, IconButton, TextField } from "@mui/material";
import TableIcon from "calcite-ui-icons-react/TableIcon";
import GridIcon from "calcite-ui-icons-react/GridIcon";
// import SearchIcon from "calcite-ui-icons-react/SearchIcon"

export const OrderFilter = ({maps, onFilter, onChangeView, view}) => {
    /** Internationalization */

    /** Styles */

    /** State */
    const [searchTerm, setSearchTerm] = React.useState('')
    const [filter, setFilter] = React.useState('all')
    const filters = React.useMemo(() => {
        return {
            "all": {
                'name': 'All',
                'filter': null
            },
            "done": {      
                'field': "status",      
                'name': 'Delivered',
                'filter': null
            },
            "queue": {        
                'field': "status",          
                'name': 'In Queue', 
                'filter': null
            },
            "failed": {
                'field': "status",      
                'name': 'Failed', 
                'filter': null
            },       
            "100k": {
                'field': "type",      
                'name': '100K Topo', 
                'filter': null
            },
            "7.5": {
                'field': "type",      
                'name': '7.5-Minute Topo', 
                'filter': null
            },
            "ongrid": {
                'field': "id",      
                'name': 'On-Grid', 
                'filter': null
            },
            "custom": {
                'field': "id",      
                'name': 'Custom', 
                'filter': null
            },        
            "productionpdf": {
                'field': "format",      
                'name': 'Production PDF', 
                'filter': null
            },        
            "tiff": {
                'field': "format",      
                'name': 'TIFF', 
                'filter': null
            },
        }
    },[])
    const filtersList = Object.keys(filters)




    /** Actions */
    const handleSearchFilter = React.useCallback((event) => {
        setSearchTerm(event.target.value)
    },[])

    const handleStatusFilter = React.useCallback((status) => {
        setFilter(status)
    },[])

    const handleSetView = (option) => {
        onChangeView(option)
    }

    /** Effects */
    React.useEffect(()=>{
        //** Filter maps */
        const filterMaps = () => {
            const searchResults = searchTerm?.length > 1 
            ? maps?.filter(m=>m.name.toLowerCase().includes(searchTerm.toLowerCase())) 
            : maps;
            const filterResults = filter === "all" 
            ? searchResults 
            : searchResults?.filter(m=> m[filters[filter]?.field] === filters[filter]?.name)
            onFilter(filterResults)
        }
        filterMaps()
    }, [searchTerm, filter, maps, onFilter, filters])

    return <>

    <SearchContainer>
        <TextField
            label="Search..."
            value={searchTerm}
            onChange={handleSearchFilter}
            fullWidth
            size="small"
        />
        <IconButton aria-label="select card view" 
        color={view === 'card' ? 'primary' : 'secondary'}
        onClick={()=>{handleSetView('card')}}
        >
            <GridIcon/>
        </IconButton>
        <IconButton aria-label="select table view" 
        color={view === 'table' ? 'primary' : 'secondary'}
        onClick={()=>{handleSetView('table')}}
        
        >
            <TableIcon/>
        </IconButton>
    </SearchContainer>
    <SearchContainer
    sx={{paddingTop: 0}}
    >
        {filtersList.map(f=><Chip 
        key={f} 
        label={filters[f].name} 
        onClick={()=>{handleStatusFilter(f)}}
        sx={{
            marginRight: 1,
            backgroundColor: f === filter ? "#005EA2": "rgba(0, 0, 0, 0.08)",
            color: f === filter ? "white": "black",
            // TODO: fix hover
            '&:hover': {
                background: "#005EA2",
                color: "white"
            }

        }}
        // color={ f === filter ? "primary": "secondary"}
        />)}
    </SearchContainer>

    </>
}