// Framework and third-party non-ui
import * as React from "react";
// import { useTranslation } from "react-i18next";

// Hooks, context, and constants
import { useConfigContext } from "contexts";

// App components
import contourImages from "assets/img/productContours";

// JSON & Styles

import {
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalDialog,
  ModalActions,
} from "./Modal-styled";

// Third-party components (buttons, icons, etc.)
import {
  Backdrop,
  Fade,
  IconButton,
  Typography,
  Button,
  Box,
} from "@mui/material";
import CloseIcon from "calcite-ui-icons-react/XIcon";


export const ContourDetails = ({ open, setOpen }) => {
  /** Internationalization */
  // const { t } = useTranslation();

  /** Styles */
  const textStyle = { color: "#1B1B1B", display: "block" };

  /** State */
  const { config } = useConfigContext()
  const {
    data: { contours },
  } = config.config;

  const title = "Contour Smoothness Guide";

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ModalContainer
      aria-labelledby="contour details modal"
      aria-describedby="contour details modal"
      role="main"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalContent>
          {/* Header */}
          <ModalHeader>
            <Typography variant="h5" component="h1" gutterBottom color="textPrimary">
              {title}
            </Typography>
            <IconButton
              style={{ position: "absolute", right: 0 }}
              onClick={handleClose}
              aria-label="close modal button"
              disableRipple
            >
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          {/* Content */}
          <ModalDialog>
            {contours.map((contour) => {
              return (
                <Box style={{ display: "flex", margin: "5% 0" }} key={contour.id}>
                  <Box style={{ width: "100%" }}>
                    <Typography color="primary" style={{ fontWeight: 700 }}>
                      {contour.title}
                    </Typography>
                    <Typography style={textStyle} variant="caption">
                      {contour.description}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={contourImages[contour.png]}
                      alt={contour.png}
                      style={{ height: "auto", width: "100%" }}
                    />
                  </Box>
                </Box>
              );
            })}
          </ModalDialog>
          {/* Actions */}
          <ModalActions>
            <Button
              disableRipple
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              Close
            </Button>
          </ModalActions>
        </ModalContent>
      </Fade>
    </ModalContainer>
  );
};
