// Framework and third-party non-ui
import * as React from "react";
import { useTranslation } from "react-i18next";
import { validateInput } from "utilities/validateInput";

// Hooks, context, and constants
import {
  useProductsContext,
  useMapContext,
  useUIContext,
  useConfigContext,
} from "contexts";

// App components

// JSON & Styles
import {
  StyledAccordion,
  StyledAccordionSummaryMap,
  StyledAccordionDetails,
  StyledInfoIcon,
  StyledFormControl,
  StyledCheckbox,
  StyledIconButton,
  StyledZoomIcon,
  StyledTrashIcon,
  //   StyledPencilIcon,
  StyledErrorIcon,
  StyledAddIcon,
  StyledRemoveIcon,
  ContentContainer,
  ContentTitle,
  ContentDescription,
  FormatSelect,
  StyledMenuItem,
  ContourSlider,
} from "./Card-Styled";

// Third-party components (buttons, icons, etc.)
import {
  AccordionActions,
  Typography,
  Tooltip,
  TextField,
  Chip,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const searchTree = (node, field, value) => {
  if (node[field] === value) {
    return node;
  } else if (Array.isArray(node.children)) {
    let result = null;
    for (let i = 0; i < node.children.length; i++) {
      result = searchTree(node.children[i], field, value);
      if (result) return result;
    }
    return result;
  }
  return null;
};

const MapName = ({
  name,
  isEditable,
  setIsEditable,
  handleUpdateName,
  isInputError,
  setIsInputError,
  setInputErrorMessage,
}) => {
  /** Internationalization */

  /** Styles */

  /** State */

  /** Actions */
  const handleOnBlur = React.useCallback(
    (event) => {
      const validateResponse = validateInput(event.target.value, "mapName");
      const isInputError = validateResponse === true ? false : true;
      const message = isInputError ? validateResponse.join(" ") : "";

      setInputErrorMessage(message);
      setIsInputError(isInputError);

      if (!isInputError) {
        setIsEditable(false);
      }
    },
    [setInputErrorMessage, setIsEditable, setIsInputError]
  );

  const handleEditNameKeyPress = React.useCallback(
    (event) => {
      if (event.key === "Enter") {
        const validateResponse = validateInput(event.target.value, "mapName");
        const isInputError = validateResponse === true ? false : true;
        const message = isInputError ? validateResponse.join(" ") : "";

        setInputErrorMessage(message);
        setIsInputError(isInputError);

        if (!isInputError) {
          setIsEditable(false);
        }
      }
    },
    [setInputErrorMessage, setIsEditable, setIsInputError]
  );

  const handleEditName = React.useCallback(
    (event) => {
      setIsInputError(false);
      setInputErrorMessage("");
      handleUpdateName(event);
    },
    [handleUpdateName, setInputErrorMessage, setIsInputError]
  );

  return (
    <>
      {isEditable ? (
        <TextField
          style={{ maxWidth: "80%" }}
          id="map-name-editor"
          inputProps={{ "aria-label": "map name input" }}
          defaultValue={name}
          onChange={handleEditName}
          onKeyPress={handleEditNameKeyPress}
          onBlur={(event) => {
            handleOnBlur({
              ...event,
              target: { value: event.currentTarget.value },
            });
          }}
          onFocus={(event) => event.stopPropagation()}
          onClick={(event) => event.stopPropagation()}
          error={isInputError}
          variant="standard"
        />
      ) : (
        <Typography
          gutterBottom
          variant="body1"
          component="h3"
          style={{
            color: "#1B1B1B",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            margin: 0,
            paddingLeft: "5px",
          }}
        >
          {name}
        </Typography>
      )}
    </>
  );
};

const MapCard = ({
  expanded = false,
  map,
  product,
  handleToggleMap,
  mode,
  ...props
}) => {
  /** Internationalization */
  const { t } = useTranslation();

  /** Styles */

  /** State */
  const [isExpanded, setIsExpanded] = React.useState(expanded);

  const [isNameEditable, setIsNameEditable] = React.useState(false);
  const [isNameInputError, setIsNameInputError] = React.useState(false);
  const [inputErrorMessage, setInputErrorMessage] = React.useState("");

  const isCustomized =
    map.customizedLayers && searchTree(map.customizedLayers, "selected", false)
      ? true
      : false;

  const { dispatch } = useProductsContext();
  const MapContext = useMapContext();
  const UIContext = useUIContext();
  const { config } = useConfigContext();
  const {
    data: { contours, formats },
  } = config.config;

  let min = map.contour?.slider;
  let max = map.contour?.slider;

  const appTheme = useTheme();
  const isMobile = useMediaQuery(appTheme.breakpoints.down("sm"));

  const contourOptions = config.config?.data?.contours.map((contour) => {
    if (min > contour.slider) {
      min = contour.slider;
    }
    if (max < contour.slider) {
      max = contour.slider;
    }
    return {
      value: contour.slider,
      label: contour.label,
      id: contour.id,
    };
  });

  /** Actions */
  const handleExpandCard = () => {
    setIsExpanded(!isExpanded);
    handleToggleMap(map, product);
  };

  const handleUpdateChecked = (event) => {
    event.stopPropagation();
    dispatch({
      type: "edit maps",
      payload: {
        maps: [{ ...map, checked: !map.checked }],
        mapProduct: product,
        contours,
        formats,
      },
    });
  };

  const handleZoom = async (event) => {
    event.stopPropagation();


    UIContext.toggleDrawer({
      isOpen: isMobile ? false : true,
      tab: UIContext.state.drawer.mainView,
      content: UIContext.state.drawer.subView,
    });


    MapContext.setSelectedMapGeometry(map.geometry.extent)
   if (!isMobile) {MapContext.state.mapView.goTo(map.geometry.extent)}
  };

  // const handleToggleNameEditable = (event) => {
  //     event.stopPropagation()
  //     if(!isNameInputError){
  //         setIsNameEditable(!isNameEditable)
  //     }
  // }

  const handleUpdateName = (event) => {
    if (mode === "edit") {
      props.handleEditMaps({
        maps: [{ ...map, name: event.target.value }],
        mapProduct: product,
      });
    } else {
      dispatch({
        type: "edit maps",
        payload: {
          maps: [{ ...map, name: event.target.value }],
          mapProduct: product,
          contours,
          formats,
        },
      });
    }
  };

  const handleRemove = (event) => {
    event.stopPropagation();

    const remove = () => {
      if (mode === "edit") {
        props.handleRemoveMaps({ maps: [map], mapProduct: product });
      } else {
        dispatch({
          type: "remove maps",
          payload: { maps: [map], mapProduct: product, contours, formats },
        });
      }
    };

    const sessionStorageState = sessionStorage.getItem("usgs-dynamap-storage");
    if (sessionStorageState) {
      const { showDeleteWarning } = JSON.parse(sessionStorageState);
      if (showDeleteWarning === false) {
        remove();
      } else {
        UIContext.setPopup({
          id: "delete map warning",
          content: { handleDeleteMaps: remove },
        });
      }
    } else {
      UIContext.setPopup({
        id: "delete map warning",
        content: { handleDeleteMaps: remove },
      });
    }
  };

  const handleUpdateFormat = (event) => {
    const selectedFormat = config.config?.data?.formats.find(
      (value) => value.id === event.target.value
    );
    if (mode === "edit") {
      props.handleEditMaps({
        maps: [{ ...map, format: selectedFormat }],
        mapProduct: product,
      });
    } else {
      dispatch({
        type: "edit maps",
        payload: {
          maps: [{ ...map, format: selectedFormat }],
          mapProduct: product,
          contours,
          formats,
        },
      });
    }
  };

  const handleUpdateContour = (event, value) => {
    const selectedContour = config.config?.data?.contours.find(
      (contour) => contour.slider === value
    );

    if (mode === "edit") {
      props.handleEditMaps({
        maps: [{ ...map, contour: selectedContour }],
        mapProduct: product,
      });
    } else {
      dispatch({
        type: "edit maps",
        payload: {
          maps: [{ ...map, contour: selectedContour }],
          mapProduct: product,
          contours,
          formats,
        },
      });
    }
  };

  const handleViewContourDetails = (event) => {
    //openContourDetailsModal
    UIContext.setPopup({ id: "contour details" });
  };

  const contourvaluetext = (value) => {
    const selected = config.config?.data?.contours.find(
      (contour) => contour.slider === value
    );
    return `contour scale: ${selected.scale}`;
  };

  /** Effects */

  React.useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <div style={{ width: "100%", display: "flex", paddingTop: "10px" }}>
      <StyledAccordion
        style={{ width: "inherit" }}
        expanded={isExpanded}
        onChange={handleExpandCard}
      >
        {/* Title Bar */}
        <StyledAccordionSummaryMap
          id={`MapCard-${map.mapId}`}
          aria-label={`map card - ${map.mapId}`}
          aria-controls={`expand-${map.mapId}`}
          expandIcon={
            isExpanded ? (
              <Tooltip title={"Hide map options"} arrow>
                <div>
                  <StyledRemoveIcon />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title={"Show map options"} arrow>
                <div>
                  <StyledAddIcon />
                </div>
              </Tooltip>
            )
          }
          role="form"
        >
          {mode === "edit" ? (
            <span style={{display:"grid", width: 175}} title={map.name}>
            <MapName
              name={map.name}
              isEditable={isNameEditable}
              setIsEditable={setIsNameEditable}
              handleUpdateName={handleUpdateName}
              setIsInputError={setIsNameInputError}
              setInputErrorMessage={setInputErrorMessage}
              isInputError={isNameInputError}
            />
            </span>
          ) : (
            <StyledFormControl
              aria-label={`select map ${map.mapId}`}
              onClick={handleUpdateChecked}
              control={<StyledCheckbox color="primary" checked={map.checked} />}
              title={map.name}
              label={
                <MapName
                  name={map.name}
                  isEditable={isNameEditable}
                  setIsEditable={setIsNameEditable}
                  handleUpdateName={handleUpdateName}
                  setIsInputError={setIsNameInputError}
                  setInputErrorMessage={setInputErrorMessage}
                  isInputError={isNameInputError}
                />
              }
              style={{ margin: 0 }}
            />
          )}

          <AccordionActions style={{ padding: 0, marginLeft: "auto" }}>
            {isNameInputError && (
              <Tooltip title={inputErrorMessage} arrow>
                <div style={{ marginRight: 8 }}>
                  <StyledErrorIcon fontSize="small" />
                </div>
              </Tooltip>
            )}

            {/* <Tooltip title={"Enable editing of map name"} arrow> 
                    <StyledIconButton
                        aria-label={`update map selection name ${map.mapId}`}
                        alt="Edit Name"
                        onClick={handleToggleNameEditable}
                    >
                        <StyledPencilIcon fontSize="small"/>
                    </StyledIconButton>
                    </Tooltip> */}

            <Tooltip title={"Pan and zoom to map product extent"} arrow>
              <StyledIconButton
                aria-label={`zoom to map selection ${map.mapId}`}
                alt="Zoom"
                onClick={handleZoom}
              >
                <StyledZoomIcon fontSize="small" />
              </StyledIconButton>
            </Tooltip>

            <Tooltip title={"Delete map"} arrow>
              <StyledIconButton
                aria-label={`remove map selection ${map.mapId}`}
                alt="Remove"
                onClick={handleRemove}
              >
                <StyledTrashIcon fontSize="small" />
              </StyledIconButton>
            </Tooltip>
          </AccordionActions>
        </StyledAccordionSummaryMap>

        {/* Advance Options */}
        <StyledAccordionDetails>
          {isCustomized && (
            <ContentContainer
              sx={{ paddingLeft: 2, paddingTop: 2, paddingBottom: 0 }}
            >
              <Chip
                label="User Defined Content"
                color="success"
                variant="outlined"
              />
            </ContentContainer>
          )}
          <ContentContainer sx={{ paddingLeft: 2, paddingTop: 2 }}>
            {/* Format */}
            <ContentTitle>
              <Typography color="textPrimary">
                {t("cart.options.format")}
              </Typography>
            </ContentTitle>
            <ContentDescription>
              <FormatSelect
                labelId={`product-print-option-${map.mapId}`}
                id={`product-print-option-single-${map.mapId}`}
                value={map.format.id}
                onChange={handleUpdateFormat}
              >
                {config.config?.data?.formats.map((format) => {
                  return (
                    <StyledMenuItem
                      dense
                      key={format.id}
                      id={format.id}
                      value={format.id}
                    >
                      {format.display}
                    </StyledMenuItem>
                  );
                })}
              </FormatSelect>
            </ContentDescription>

            {/* Contour Slider */}
            {map.contour && !product.type.deactivateContourSelection &&
              <>
                <ContentTitle sx={{ display: "flex" }}>
                  <Typography color="textPrimary">
                    {t("cart.options.contour")}
                  </Typography>
                  <Tooltip title={t("cart.options.contourTooltip")} arrow>
                    <StyledIconButton
                      style={{ marginLeft: "auto" }}
                      aria-label={`view contour details ${map.mapId}`}
                      alt="Details"
                      onClick={handleViewContourDetails}
                    >
                      <StyledInfoIcon fontSize="small" />
                    </StyledIconButton>
                  </Tooltip>
                </ContentTitle>
                <ContentDescription
                  sx={{
                    display: "flex",
                    padding: "0px 25px",
                  }}
                >
                  <ContourSlider
                    aria-label="contour slider"
                    getAriaValueText={contourvaluetext}
                    value={map.contour.slider}
                    marks={contourOptions}
                    step={null}
                    min={min}
                    max={max}
                    onChangeCommitted={handleUpdateContour}
                  />
                </ContentDescription>
              </>
            }
          </ContentContainer>
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>
  );
};

export default MapCard;
