// Framework and third-party non-ui
import * as React from "react";
import { styled } from "@mui/material/styles";

// Hooks, context, and constants

// App components

// JSON & Styles

// Third-party components (buttons, icons, etc.)
import { Typography, Container } from "@mui/material";
import ExclamationMarkTriangleIcon from "calcite-ui-icons-react/ExclamationMarkTriangleIcon";

export const StyledErrorIcon = styled(ExclamationMarkTriangleIcon)(({theme})=> ({
    color: theme.palette.error.main,
    display: "flex"
  }))

const LoaderError = () => {
    return <Container  style={{ display: "flex", flexDirection: "column", height: "100vh", alignItems: "center", justifyContent: "center" }}>
        {/* <StyledErrorIcon/> */}
        <Typography variant="h4" component="h3" style={{padding: "25px"}} color="error">
           Something went wrong...
        </Typography>
        <Typography variant="body1" component="p" style={{color: "black"}}>
           The topoBuilder application is currently down. Please wait and try again later.
        </Typography>
    </Container>
}

export default LoaderError