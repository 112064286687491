// Styling Utilities
import { styled } from "@mui/material/styles";

// Components
import { Container, Box, Link } from "@mui/material";

// Properties

// Component Exports

export const StyledFooter = styled("footer")(({ theme }) => ({
  background: " #00264c",
  paddingBottom: 0,
  position: "relative",
  width: "100%",
  borderTop: "1px solid #E5E5E5",
  zIndex: 10,
}));

export const LinkContainer = styled(Container)(({ theme }) => ({
  margin: 0,
  paddingLeft: "12px !important",
  paddingRight: "12px !important",
  maxWidth: "10000px !important",
}));

export const LinkBoxTop = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  flexWrap: "wrap",
  overflowY: "hidden",
  whiteSpace: "nowrap",
  display: "block",
  [theme.breakpoints.down("xs")]: {
    overflowX: "scroll",
  },
}));

export const LinkBoxBottom = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  borderTop: "1px solid white",
  marginBottom: theme.spacing(1),

  overflowY: "hidden",
  whiteSpace: "nowrap",
  display: "block",
  [theme.breakpoints.down("xs")]: {
    overflowX: "scroll",
  },
}));

export const StyledLinkFirst = styled(Link)(({ theme }) => ({
  color: "white",
  paddingRight: theme.spacing(1),
  fontSize: 12,
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: "white",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  borderLeft: "1px solid white",
  fontSize: 12,
}));
