// Framework and third-party non-ui
import * as React from "react";
// import { useTranslation } from "react-i18next";

// Hooks, context, and constants

// App components

// JSON & Styles
import {
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
  StyledIconButton,
} from "./Modal-styled";

// Third-party components (buttons, icons, etc.)
import CloseIcon from "calcite-ui-icons-react/XIcon";
import { Typography } from "@mui/material";

export const Popup = ({ isOpen, onClose, title, children, control }) => {
  /** Internationalization */
  // const { t } = useTranslation();

  /** State */

  /** Actions */
  const handleClosePopop = () => {
    onClose();
  };

  /** Effects */
  return (
    <StyledDialog
      onClose={handleClosePopop}
      aria-labelledby="popup"
      open={isOpen}
    >
      <StyledDialogTitle>
        <Typography variant="h6" component="h2">
          {title}
        </Typography>

        <StyledIconButton onClick={handleClosePopop} aria-label="close popup">
          <CloseIcon size={24} />
        </StyledIconButton>
      </StyledDialogTitle>
      <StyledDialogContent dividers>{children}</StyledDialogContent>
      <StyledDialogActions>{control}</StyledDialogActions>
    </StyledDialog>
  );
};
