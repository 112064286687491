import * as React from "react";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "utilities/localStorageTools";

const CartContext = React.createContext();

/** 
products = {
productId: {type: {...}, maps: {mapId:{...}}, selectedMaps: [], isSelected: false}, ...
}
*/
const init = () => {
  return {
    products: {},
    status: {},
    error: {},
  };
};

const cartReducer = (state, action) => {
  const { type, payload } = action;
  const { products, mapTypes, maps, mapProduct, formats } = payload || {};

  switch (type) {
    case "add products": {
      if (!products || products.length < 1) {
        throw new Error(`Missing products input in payload: ${mapTypes}`);
      }
      // if product with identical type exists add maps to product
      const updatedProducts = products.reduce((productObj, product) => {
        const productId = product.productId;
        const name = product.type.name;
        const includePrint = product.type.defaultIncludePrint;
        const format = formats.find(
          (format) => format.id === product.type.defaultFormat
        );

        const newMaps = product.maps.reduce((mapsObj, map) => {
          const newMap = {
            ...map,
            expanded: true,
            mapId: `${map.mapId}`,
          };

          return { [newMap.mapId]: newMap, ...mapsObj };
        }, {});

        const updatedProduct = {
          ...product,
          name,
          format,
          includePrint,
          maps: { ...newMaps, ...productObj[productId]?.maps },
        };
        return { ...productObj, [productId]: updatedProduct };
      }, state.products);

      setLocalStorageItem("cart", { ...state, products: updatedProducts });
      return { ...state, products: updatedProducts };
    }
    case "edit products": {
      if (!products || products.length < 1) {
        throw new Error(`Missing products input in payload: ${mapTypes}`);
      }


      setLocalStorageItem("cart", {
        ...state,
        products: { ...state.products, ...products },
      });
      return { ...state, products: { ...state.products, ...products } };
    }
    case "edit maps": {
      if (!maps || maps.length < 1) {
        throw new Error(`Missing maps input in payload: ${maps}`);
      }
      if (!mapProduct) {
        throw new Error(`Missing mapProduct input in payload: ${mapProduct}`);
      }

      const { productId } = mapProduct;
      const productState = state.products[productId];
      const productMaps = productState.maps;

      const updatedMaps = maps.reduce((obj, map) => {
        const { mapId } = map;
        const updatedMaps = { ...obj, [mapId]: map };

        return updatedMaps;
      }, productMaps);

      const updatedProducts = {
        ...state.products,
        [productId]: { ...productState, maps: updatedMaps },
      };
      setLocalStorageItem("cart", { ...state, products: updatedProducts });
      return { ...state, products: updatedProducts };
    }
    case "remove maps": {
      if (!maps || maps.length < 1) {
        throw new Error(`Missing maps input in payload: ${maps}`);
      }
      if (!mapProduct) {
        throw new Error(`Missing mapProduct input in payload: ${mapProduct}`);
      }

      const { productId } = mapProduct;
      const productState = state.products[productId];
      const productMaps = productState.maps;

      const remainingMaps = maps.reduce((obj, map) => {
        const { mapId } = map;
        const { [mapId]: remove, ...remainingMaps } = obj;
        return remainingMaps;
      }, productMaps);

      const updatedProducts = {
        ...state.products,
        [productId]: { ...productState, maps: remainingMaps },
      };
      setLocalStorageItem("cart", { ...state, products: updatedProducts });
      return { ...state, products: updatedProducts };
    }
    case "reset": {
      const store = init();
      setLocalStorageItem("cart", store);
      return init();
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

export const CartContextProvider = ({ children }) => {
  const storedState = getLocalStorageItem("cart");
  const initState =
    storedState?.success && storedState?.data?.products
      ? storedState.data
      : { products: {}, status: {}, error: {} };

  /** State */
  const [state, dispatch] = React.useReducer(cartReducer, initState);

  /** Utility Functions */

  const getTotalMapCount = React.useCallback(() => {
    const products = Object.entries(state.products);
    const reducer = (count, entry) => count + Object.keys(entry[1].maps).length;
    const mapCount = products.reduce(reducer, 0);
    return mapCount;
  }, [state.products]);

  const value = { state, dispatch, getTotalMapCount };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCartContext = () => {
  const cartContext = React.useContext(CartContext);
  if (!cartContext)
    throw new Error(
      "Cannot use `useCartContext` outside of a CartContextProvider"
    );
  return cartContext;
};
