// Framework and third-party non-ui
import * as React from "react";
import { useTheme } from "@mui/material/styles";

// Hooks, context, and constants
import { useCartContext, useUIContext } from "contexts";

// App components
import Cart from "./Cart";
import { SelectMapType } from "./SelectMapType";
import { SelectMap } from "./SelectMap";
import { Help } from "./Help";
import Tracker from "components/Tracker";

// JSON & Styles
import {
  StyledListItem,
  StyledListItemIcon,
  ControllerDrawer,
  ContentDrawer,
  StyledIconButton,
} from "./ActionBar-styled";

// Third-party components (buttons, icons, etc.)
import { Divider, List, Typography, useMediaQuery, Chip } from "@mui/material";

import NumberCircle1Icon from "calcite-ui-icons-react/NumberCircle1Icon";
import NumberCircle2Icon from "calcite-ui-icons-react/NumberCircle2Icon";
import NumberCircle3Icon from "calcite-ui-icons-react/NumberCircle3Icon";
import QuestionIcon from "calcite-ui-icons-react/QuestionIcon";
import ChevronsLeftIcon from "calcite-ui-icons-react/ChevronsLeftIcon";
import ChevronsRightIcon from "calcite-ui-icons-react/ChevronsRightIcon";
import CubeIcon from "calcite-ui-icons-react/CubeIcon"


const ActionBar = ({ 
  isBannerOpen, 
  orders,
  setOrders,
  selectedOrder,
  setSelectedOrder,
  listOrder, 
  setListOrder
}) => {
  /** Internationalization */
  // const { t } = useTranslation();

  /** Styles */

  /** State */
  const UIContext = useUIContext();
  const { getTotalMapCount } = useCartContext();

  const mapCount = getTotalMapCount();

  const appTheme = useTheme();
  const isMobile = useMediaQuery(appTheme.breakpoints.down("sm"));

  /** Actions */
  const handleToggleDrawer = () => {
    UIContext.toggleDrawer({
      isOpen: !UIContext.state.drawer.isOpen,
      tab: UIContext.state.drawer.mainView,
      content: UIContext.state.drawer.subView,
    });
  };

  const handleAddOrder = (order) => {
    setOrders({...orders, [order.id]: {...order}})
  }

  const handleUpdateAllOrders = (updates) => {
    setOrders(updates)
  }

  const handleRemoveOrder = (order) => {
    const { [order]: removeOrder, ...remainingOrders} = orders;
    setSelectedOrder(null)
    setOrders({...remainingOrders})
    setListOrder(listOrder.filter(id=>id !== order))
  }

  const handleSelectOrder = (order) => {
    setSelectedOrder(order?.id)
  }

  /** Effects */
  React.useEffect(() => {
    const setVisible = () => {
      if (isMobile && UIContext.state.drawer.isOpen) {
        const esriElements = document.getElementsByClassName("esri-component");
        for (let item of esriElements) {
          item.style.visibility = "hidden";
        }
      } else {
        const esriElements = document.getElementsByClassName("esri-component");
        for (let item of esriElements) {
          item.style.visibility = "visible";
        }
      }
    };
    setVisible();
  }, [UIContext.state.drawer.isOpen, isMobile]);

  return (
    <>
      {/* Control */}
      <ControllerDrawer variant="permanent" open={false}>
        <div>
          <Divider />
          <List>
            {/* MapType */}
            <StyledListItem
              onClick={() => {
                UIContext.toggleDrawer({
                  isOpen: !(
                    UIContext.state.drawer.isOpen &&
                    UIContext.state.drawer.mainView === "product type"
                  ),
                  tab: "product type",
                  content: "product type",
                });
              }}
              selected={UIContext.state.drawer.mainView === "product type"}
            >
              <StyledListItemIcon
                open={UIContext.state.drawer.mainView === "product type"}
              >
                <NumberCircle1Icon />
              </StyledListItemIcon>
              <Typography noWrap> Map Types </Typography>
            </StyledListItem>

            {/* Maps */}
            <StyledListItem
              onClick={() => {
                UIContext.toggleDrawer({
                  isOpen: !(
                    UIContext.state.drawer.isOpen &&
                    UIContext.state.drawer.mainView === "product maps"
                  ),
                  tab: "product maps",
                  content: "product maps",
                });
              }}
              selected={UIContext.state.drawer.mainView === "product maps"}
            >
              <StyledListItemIcon
                open={UIContext.state.drawer.mainView === "product maps"}
              >
                <NumberCircle2Icon />
              </StyledListItemIcon>
              <Typography noWrap> Maps </Typography>
            </StyledListItem>
            {/* Cart */}
            <StyledListItem
              onClick={() => {
                UIContext.toggleDrawer({
                  isOpen: !(
                    UIContext.state.drawer.isOpen &&
                    UIContext.state.drawer.mainView === "cart"
                  ),
                  tab: "cart",
                  content:
                    UIContext.state.drawer.mainView === "cart"
                      ? UIContext.state.drawer.subView
                      : "cart",
                });
              }}
              selected={UIContext.state.drawer.mainView === "cart"}
            >
              <StyledListItemIcon
                open={UIContext.state.drawer.mainView === "cart"}
              >
                <NumberCircle3Icon />
              </StyledListItemIcon>
              <Typography noWrap> Cart </Typography>
              <Chip
                color="success"
                size="small"
                label={mapCount}
                sx={{
                  fontWeight: "bold",
                  marginLeft: "8px",
                  height: "20px",
                  width: "20px",
                  "& .MuiChip-label": {
                    overflow: "inherit",
                    textOverflow: "inherit",
                    whiteSpace: "inherit",
                  },
                }}
              />
            </StyledListItem>
            {/* <Divider /> */}

          </List>
          {/* Expand / Collapse */}
        </div>
        <div
          style={{
            alignContent: "flex-end",
            height: "auto",
            marginTop: "auto",
          }}
        >
                      {/* Tracker */}
                      <StyledListItem
              onClick={() => {
                UIContext.toggleDrawer({
                  isOpen: !(
                    UIContext.state.drawer.isOpen &&
                    UIContext.state.drawer.mainView === "track"
                  ),
                  tab: "track",
                  content: "track",
                });
              }}
              selected={UIContext.state.drawer.mainView === "track"}
            >
              <StyledListItemIcon
                open={UIContext.state.drawer.mainView === "track"}
              >
                <CubeIcon />
              </StyledListItemIcon>
              <Typography noWrap> Track </Typography>
            </StyledListItem>
            {/* Help */}
            <StyledListItem
              onClick={() => {
                UIContext.toggleDrawer({
                  isOpen: !(
                    UIContext.state.drawer.isOpen &&
                    UIContext.state.drawer.mainView === "help"
                  ),
                  tab: "help",
                  content: "help",
                });
              }}
              selected={UIContext.state.drawer.mainView === "help"}
            >
              <StyledListItemIcon
                open={UIContext.state.drawer.mainView === "help"}
              >
                <QuestionIcon />
              </StyledListItemIcon>
              <Typography noWrap> Help </Typography>
            </StyledListItem>
          <StyledIconButton
            aria-label="close drawer"
            size="large"
            onClick={handleToggleDrawer}
            title={
              UIContext.state.drawer.isOpen
                ? "Hide Task Panel"
                : "Expand Task Panel"
            }
          >
            {UIContext.state.drawer.isOpen ? (
              <ChevronsLeftIcon style={{ stroke: "green", strokeWidth: "2" }} />
            ) : (
              <ChevronsRightIcon
                style={{ stroke: "green", strokeWidth: "2" }}
              />
            )}
          </StyledIconButton>
        </div>
      </ControllerDrawer>
      {/* Content */}
      <ContentDrawer
        variant="permanent"
        open={UIContext.state.drawer.isOpen}
        isbanneropen={isBannerOpen.toString()}
        // PaperProps={
        //   {style: {position: 'absolute'}}
        // }
      >
        {UIContext.state.drawer.mainView === "product type" && (
          <SelectMapType />
        )}
        {UIContext.state.drawer.mainView === "product maps" && <SelectMap />}
        {UIContext.state.drawer.mainView === "cart" && <Cart />}
        {UIContext.state.drawer.mainView === "help" && <Help />}
        {UIContext.state.drawer.mainView === "track" && <Tracker 
          isMobile={isMobile} 
          orders={orders} 
          onAddOrder={handleAddOrder}
          onUpdateAllOrders={handleUpdateAllOrders}
          onRemoveOrder={handleRemoveOrder}
          selected={selectedOrder}
          onSelectOrder={handleSelectOrder}
          listOrder={listOrder}
          setListOrder={setListOrder}
        />}
      </ContentDrawer>
    </>
  );
};
export default ActionBar;
