// Framework and third-party non-ui
import * as React from "react";
// import { useTranslation } from "react-i18next";

// Hooks, context, and constants
import {  useProductsContext, useUIContext } from "contexts";

// App components
import { Maps } from "./Maps";
import { Layers } from "./Layers";

// JSON & Styles

// Third-party components (buttons, icons, etc.)

export const SelectMap = () => {
  /** Internationalization */
  // const { t } = useTranslation();
  /** State */

  const ProductsContext = useProductsContext();
  const UIContext = useUIContext()

  const initProductCount = ProductsContext.getProductCount();
  const visibleProducts = ProductsContext.getSelectedProducts();

  const [selectedProduct, setSelectedProduct] = React.useState(
    initProductCount === 1 && visibleProducts.length === 1
      ? ProductsContext.state.products[
          Object.keys(ProductsContext.state.products)[0]
        ]
      : null
  );

  const [subView, setSubView] = React.useState("maps");
  const [updateSubView, setUpdateSubView] = React.useState("maps");
  /** Actions */
  const handleChangeView = (view, product) => {
    product && setSelectedProduct(product);
    setUpdateSubView(view);
  };

  /** Effects */
  React.useEffect(() => {
    const handleUpdate = () => {

      if (updateSubView !== subView) {
        setSubView(updateSubView);
        if(updateSubView === 'layers') UIContext.setToolbox({isOpen: false})
        else  UIContext.setToolbox({isOpen: true})
      }
      else if(!UIContext.state.drawer.isOpen && subView === 'layers' && UIContext.state.toolbox.isOpen ){
        UIContext.setToolbox({isOpen: false})
      }
    };
    handleUpdate();
  }, [subView, updateSubView, selectedProduct, UIContext]);

  return (
    <>
      {subView === "maps" && (
        <Maps
          onChangeView={handleChangeView}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
      )}
      {subView === "layers" && (
        <Layers
          onSave={() => {
            handleChangeView("maps");
          }}
          mapType={selectedProduct}
        />
      )}
    </>
  );
};
