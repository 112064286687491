import React, { createContext, useContext, useState, useCallback } from "react";

const UIContext = createContext();

export const UIContextProvider = ({ children }) => {
  // ----- Action Bar -----
  const [drawer, setDrawer] = React.useState({
    isOpen: true,
    mainView: "product type", // product type || product maps || cart
    subView: "",
  });
  const [toolbox, setToolbox] = React.useState({
    isOpen: false,
    product: null,
  });
  const [popup, setPopup] = React.useState({
    id: "",
    title: null,
    content: null,
    control: null,
  });

  // modal
  const [loading, setLoading] = useState({ status: false, message: null });

  const toggleDrawer = useCallback(
    ({ isOpen, tab, content }) => {
      setDrawer({ ...drawer, isOpen, mainView: tab, subView: content });

      if (tab === "product maps" && content !== 'layers') {
        setToolbox({ isOpen: true });
      } else {
        setToolbox({ isOpen: false });
      }
    },
    [drawer]
  );

  const setDrawerContent = useCallback(
    (subView) => {
      setDrawer({ ...drawer, subView: subView });
    },
    [drawer]
  );

  const state = { drawer, toolbox, popup, loading };
  const value = {
    state,
    toggleDrawer,
    setDrawerContent,
    setLoading,
    setToolbox,
    setPopup,
  };

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};

export const useUIContext = () => {
  const uiContext = useContext(UIContext);
  if (!uiContext)
    throw new Error("Cannot use `useUIContext` outside of a UIContextProvider");
  return uiContext;
};
