// Framework and third-party non-ui
import * as React from "react";
import ReactDOM from "react-dom";

import "./i18n";
import "@fontsource/public-sans";
// import reportWebVitals from "./reportWebVitals";

// Hooks, context, and constants
import {
  UIContextProvider,
  MapContextProvider,
  CartContextProvider,
  ProductsContextProvider,
  ConfigContextProvider
} from "contexts";

// App components
import App from "./App/index.js";

// JSON & Styles
import CustomTheme from "CustomTheme";
import "./index.css";
// Third-party components (buttons, icons, etc.)
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={CustomTheme}>

        <ConfigContextProvider>
        <UIContextProvider>
          <ProductsContextProvider>
            <CartContextProvider>
              <MapContextProvider>
                <CssBaseline />
                <App />
              </MapContextProvider>
            </CartContextProvider>
          </ProductsContextProvider>
        </UIContextProvider>
        </ConfigContextProvider>

    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
