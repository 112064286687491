import React, { createContext, useContext, useState } from "react";

const ConfigContext = createContext();

export const ConfigContextProvider = ({ children }) => {
    // ----- App Configurations -----
    const [config, setConfig] = useState({ loading: true, config: {} });

    //   TODO: Set configurations here 
  return (
    <ConfigContext.Provider
      value={{
          config,
          setConfig
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext = () => {
  const configContext = useContext(ConfigContext);
  if (!configContext)
    throw new Error(
      "Cannot use `useConfigContext` outside of a ConfigContextProvider"
    );
  return configContext;
};
