// Framework and third-party non-ui
import * as React from "react";
// import { useTranslation } from "react-i18next";

// Hooks, context, and constants

// App components

// JSON & Styles
import {
  SubList,
  StyledBulletListItem,
  StyledListItemText,
} from "./Items-styled";

// Third-party components (buttons, icons, etc.)
import { CartItemCard } from "components/Card";

// TODO: Card text overflow
const searchTree = (node, field, value) => {
  console.log('node', node)
  if (!node){return}
  if (node[field] === value) {
    return node;
  } else if (Array.isArray(node.children)) {
    let result = null;
    for (let i = 0; i < node.children.length; i++) {
      result = searchTree(node.children[i], field, value);
      if (result) return result;
    }
    return result;
  }
  return null;
};

const ItemCard = ({ item, onEditMaps=()=>{} }) => {

  /** Internationalization */
  // const { t } = useTranslation();

  /** Styles */

  /** State */

  /** Actions */
  const handleEditMaps = (product) => {
    onEditMaps(product)
  }

  /** Effects */

  // return (
  //   <List
  //     subheader={
  //       <Typography sx={{ color: "text.default", fontWeight: 800 }}>
  //         {" "}
  //         {item.type.displayName}{" "}
  //       </Typography>
  //     }
  //     dense
  //   >
  //     {/* <Typography sx={{color: "#000000", fontWeight: 800}}> {item.type} </Typography> */}
  //     {Object.values(item.maps).map((map, index) => {
  //       const isCustomized = searchTree(map.customizedLayers, "selected", false)
  //         ? true
  //         : false;

  //       return (
  //         <StyledBulletListItem dense key={index}>
  //           <StyledListItemText sx={{ color: "text.default" }}>
  //             <ListItemIcon sx={{ minWidth: 15 }}>
  //               <FilledCircleIcon size={7} />
  //             </ListItemIcon>{" "}
  //             {map.name}
  //           </StyledListItemText>
  //           <SubList>
  //             <StyledListItemText sx={{ color: "text.default" }}>
  //               {" "}
  //               <ListItemIcon sx={{ minWidth: 15 }}>
  //                 <CircleIcon size={7} />
  //               </ListItemIcon>{" "}
  //               {map.custom ? "Custom Extent" : "On-Grid"}{" "}
  //             </StyledListItemText>
  //             <StyledListItemText sx={{ color: "text.default" }}>
  //               {" "}
  //               <ListItemIcon sx={{ minWidth: 15 }}>
  //                 <CircleIcon size={7} />
  //               </ListItemIcon>{" "}
  //               {map.contour.label} Contour Smoothing{" "}
  //             </StyledListItemText>
  //             <StyledListItemText sx={{ color: "text.default" }}>
  //               {" "}
  //               <ListItemIcon sx={{ minWidth: 15 }}>
  //                 <CircleIcon size={7} />
  //               </ListItemIcon>{" "}
  //               {map.format.value} Format{" "}
  //             </StyledListItemText>
  //             {isCustomized && (
  //               <StyledListItemText sx={{ color: "text.default" }}>
  //                 <ListItemIcon sx={{ minWidth: 15 }}>
  //                   <CircleIcon size={7} />
  //                 </ListItemIcon>
  //                 User Defined Content
  //               </StyledListItemText>
  //             )}
  //           </SubList>
  //         </StyledBulletListItem>
  //       );
  //     })}
  //   </List>
  // );
  return <CartItemCard
  product={item}
  onEditMaps={handleEditMaps}
  >          <div style={{
    display: "flex",
    flexdirection: "row",
    flexWrap: "wrap",
    width: "100%",
    flex: 2,
  }}>
   {Object.values(item.maps).map((map, index) => {
        const isCustomized = searchTree(map.customizedLayers, "selected", false)
          ? true
          : false;

        return (

          <StyledBulletListItem dense key={index}>
            <StyledListItemText sx={{ color: "text.default" }}>
              {map.name}
            </StyledListItemText>
            <SubList>
              <StyledListItemText sx={{ color: "text.disabled" }}>
                {map.custom ? "Custom Extent" : "On-Grid"}{" "}
              </StyledListItemText>
              {map.contour && !item.type.deactivateContourSelection &&
                <StyledListItemText sx={{ color: "text.disabled" }}>
                  {map.contour.label} Contour Smoothing{" "}
                </StyledListItemText>
              }
              <StyledListItemText sx={{ color: "text.disabled" }}>
                {map.format.value} Format{" "}
              </StyledListItemText>
              {isCustomized && (
                <StyledListItemText sx={{ color: "text.disabled" }}>
                  User Defined Content
                </StyledListItemText>
              )}
            </SubList>
          </StyledBulletListItem>
         
        );
    })} </div>
  </CartItemCard>
};

export default ItemCard;
