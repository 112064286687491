// Styling Utilities
import { styled } from "@mui/material/styles";

// Components
import { Box, Button, List, ListItem } from "@mui/material";

import InfoIcon from "calcite-ui-icons-react/InformationFIcon";

// Properties
const productPaddingTop = 5;
const buttonContainerHeight = 70;

// Component Exports

export const DrawerContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  paddingTop: productPaddingTop,
  paddingLeft: theme.spacing(18),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(5),
  },
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  padding: 10,
  paddingBottom: 60,
  height: "inherit",
}));

export const ListItemFooter = styled(Box)(({ theme }) => ({
  width: "100%",
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  padding: 3,
  fontSize: 12,
  fontWeight: 700,
}));

export const ButtonControlContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  alignItems: "center",
  display: "flex",
  width: `calc(100% - ${theme.spacing(18)})`,
  [theme.breakpoints.down("sm")]: {
    width: `calc(100% - ${theme.spacing(5)})`,
  },
  height: buttonContainerHeight,
  bottom: 0,
  backgroundColor: theme.palette.background.default,
  boxShadow:
    "0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)",
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
}));

export const OptionsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  overflow: "auto",
  height: "75%",
}));

export const NavButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    marginRight: theme.spacing(2),
  },
  // variant="contained" color="primary"
}));

export const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.blue.light,
  marginLeft: "auto",
}));

export const StyledList = styled(List)(({ theme }) => ({
  width: "100%",
  overflowX: "hidden",
  overflowY: "auto",
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
}));

export const ButtonBox = styled(Box)(({ theme }) => ({
  marginLeft: "auto",
}));

export const CardBox = styled(Box)(({ theme }) => ({
  padding: "10px 10px 0px 10px",
  display: "flex",
  justifyContent: "space-around",
}));
