// Framework and third-party non-ui
import * as React from "react";

// Hooks, context, and constants

// App components

// JSON & Styles

// Third-party components (buttons, icons, etc.)
import { Card, CardContent, Chip, Grid, Typography, IconButton } from "@mui/material";

import CopyToClipboardIcon from "calcite-ui-icons-react/CopyToClipboardIcon";
import DownloadToIcon from "calcite-ui-icons-react/DownloadToIcon"

export const OrderMapCard = ({map}) => {
    /** Internationalization */

    /** Styles */

    /** State */
    const status = {
        "Delivered": "success",
        "Failed": "error"
    }

    const displayContour = map.displayContour

//     "all": {
//         'name': 'All',
//         'filter': null
//     },
//     "done": {            
//         'name': 'Done',
//         'filter': null
//     },
//     "processing": {            
//         'name': 'In Queue', 
//         'filter': null
//     },
//     "failed": {
//         'name': 'Failed', 
//         'filter': null
// },

    /** Actions */

    /** Effects */

    return <Card variant="outlined" sx={{marginBottom: '15px', borderRadius: 0}}>
        <CardContent sx={{
            padding: "5px 10px", 
            background: "rgba(240, 240, 240, 1)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
            }}>
            <Typography> {map.name} </Typography>
            <Chip label={map.status} size="small" color={status[map.status]}/>
        </CardContent>

        <CardContent>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                <div>
                    <Typography sx={{color: '#171717'}}> {map.id} </Typography>
                    <Typography sx={{fontStyle: 'italic' , color: '#71767A', fontSize:12}}> Expires: {map.expires} </Typography>
                </div>
                </Grid>
                <Grid item xs={6} style={{display: "flex"}}>
                <div style={{ marginLeft: "auto", right: 0,}}>
                {map.url &&
                    <div>
                    
                    <IconButton 
                    style={{
                        border: "1px solid #005EA2",
                        borderRadius: 4,
                        color: '#005EA2',
                        marginRight: 5
                    }}
                    variant={"contained"} 
                    onClick={()=>{
                        navigator.clipboard.writeText(map.url)
                    }}
                    title={'Copy Download Url'}
                    > <CopyToClipboardIcon/> </IconButton>

                    <IconButton 
                    style={{
                        border: "1px solid #005EA2",
                        borderRadius: 4,
                        color: '#005EA2'
                    }}
                    variant={"contained"} 
                    onClick={()=>{
                        window.open(map.url, '_blank')
                    }}
                    title={'Download Map'}
                    > <DownloadToIcon/> </IconButton>


                    {/* <Button variant={"contained"} onClick={()=>{
                        window.open(map.url, '_blank')
                    }}> {"Download"} </Button> */}
                    
                    </div>
                    }
                    
                </div>
                </Grid>

                <Grid item xs={6}>
                <div>
                    <Typography sx={{color: '#71767A', fontSize:12}}> {"Product Type"} </Typography>
                    <Typography sx={{color: '#171717', fontSize:14}}> {map.type} </Typography>
                </div>
                </Grid>
                <Grid item xs={6}>
                <div>
                    <Typography sx={{color: '#71767A', fontSize:12}}> {"Product Format"} </Typography>
                    <Typography sx={{color: '#171717', fontSize:14}}>{map.format} </Typography>
                </div>
                </Grid>

                <Grid item xs={6}>
                <div>
                    <Typography sx={{color: '#71767A', fontSize:12}}> {'Layers Removed'} </Typography>
                    <Typography sx={{color: '#171717', fontSize:14}}> {map.layersRemoved} </Typography>
                </div>
                </Grid>
                <Grid item xs={6}>
                <div>
                    {displayContour &&
                    <>
                    <Typography sx={{color: '#71767A', fontSize:12}}> {'Contour Smoothness'} </Typography>
                    <Typography sx={{color: '#171717', fontSize:14}}> {map.contour} </Typography>
                    </>
                    }
                </div>
                </Grid>

            </Grid>

        </CardContent>

    </Card>
}