// Framework and third-party non-ui
import * as React from "react";
// import { useTranslation } from "react-i18next";

// Hooks, context, and constants
import { useCartContext, useProductsContext, useUIContext } from "contexts";

// App components
import FolderTree from "components/FolderTree";

// JSON & Styles
import {
  ButtonControlContainer,
  ContentContainer,
  DrawerContainer,
  NavButton,
  OptionsContainer,
  StyledFormGroup,
  TitleContainer,
  ButtonBox,
  StyledInfoIcon,
  StyledIconButton,
} from "./SelectMap-styled";

// Third-party components (buttons, icons, etc.)
import { Typography, IconButton, Tooltip } from "@mui/material";

import ArrowIcon from "calcite-ui-icons-react/ArrowLeftIcon";

export const Layers = ({ onSave, mapType }) => {
  /** Internationalization */
  // const { t } = useTranslation();

  /** State */
  const ProductsContext = useProductsContext();
  const UIContext = useUIContext();
  const CartContext = useCartContext();

  const maps = ProductsContext.state.products[mapType.productId].maps;
  const [layers, setLayers] = React.useState(
    maps[Object.keys(maps)[0]]?.customizedLayers
  );

  /** Actions */

  const handleSave = () => {


    Object.entries(ProductsContext.state.products).forEach(([productid, product]) => {
      const updatedMaps = Object.keys(product.maps).map((id) => {
      return { ...product.maps[id], customizedLayers: layers };
      });

      if(updatedMaps?.length > 0){
        ProductsContext.dispatch({
          type: "edit maps",
          payload: {
            maps: updatedMaps,
            mapProduct: product,
          },
        });
      }
    });


    const updatedCart = Object.fromEntries(
      Object.entries(CartContext.state.products).map(([productid, product]) => {
        const update = Object.fromEntries(
          Object.entries(product.maps).map(([mapid, map]) => {
            return [
              mapid,
              {
                ...map,
                customizedLayers: layers,
              },
            ];
          })
        );
        return [productid, { ...product, maps: update }];
      })
    );

    CartContext.dispatch({
      type: "edit products",
      payload: { products: updatedCart },
    });



    onSave();
  };

  const handleBack = () => {
    onSave("maps");
  };

  /** Effects */

  return (
    <DrawerContainer>
      <ContentContainer p={3}>
        {/* Title */}

        <TitleContainer>
          <IconButton aria-label="back to map selection" onClick={handleBack}>
            <ArrowIcon />
          </IconButton>

          <Typography variant="h6" component="h2" color="textPrimary">
            Layers
          </Typography>

          <Tooltip title="Select for more details" arrow aria-label={null}>
            <StyledIconButton
              style={{ marginLeft: "auto", display: "flex" }}
              aria-label={`view layer selection details`}
              alt="Details"
              onClick={() => {
                UIContext.setPopup({
                  id: "layer details",
                  title: "Edit Layers Guide ",
                  content:
                    "The Edit Layers function allows for removal of specific map data from the delivered product. Removed (unchecked) data content will not be available in the map product upon delivery, regardless of data coverage. Layer removal corresponds to the OnDemand Topo Legend organization.",
                });
              }}
            >
              <StyledInfoIcon />
            </StyledIconButton>
          </Tooltip>
        </TitleContainer>

        {/* Subtitle */}
        <TitleContainer>
          <Typography color="textSecondary">
            Only checked layers will be included
          </Typography>
        </TitleContainer>

        <OptionsContainer>
          <StyledFormGroup>
            <FolderTree tree={layers} setTree={setLayers} />
          </StyledFormGroup>
        </OptionsContainer>
      </ContentContainer>

      <ButtonControlContainer>
        <ButtonBox>
          <NavButton onClick={handleSave} variant="contained" color="primary">
            Save
          </NavButton>
        </ButtonBox>
      </ButtonControlContainer>
    </DrawerContainer>
  );
};
