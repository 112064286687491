// Framework and third-party non-ui
import * as React from "react";
import { useTranslation } from "react-i18next";

// Hooks, context, and constants

// App components
import themeImages from "assets/img/productThemes";

// JSON & Styles
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledCardMedia,
  CardMediaContainer,
  StyledInfoIcon,
  StyledFormControl,
  StyledIconButton,
} from "./Card-Styled";

// Third-party components (buttons, icons, etc.)
import { AccordionActions, Typography, Tooltip, Button } from "@mui/material";

import AddIcon from "calcite-ui-icons-react/PlusIcon";
import RemoveIcon from "calcite-ui-icons-react/MinusIcon";

const ProductTypeCard = ({
  selected,
  theme,
  handleToggleSelection,
  handleViewTypeDetails,
  ...props
}) => {
  /** Internationalization */
  const { t } = useTranslation();

  /** Styles */

  /** State */
  const [isExpanded, setIsExpanded] = React.useState(true);

  /** Actions */
  const handleExpandCard = (event) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleSelectProduct = (event) => {
    event.stopPropagation();
    handleToggleSelection(theme);
  };

  const handleViewMoreInfo = (event) => {
    event.stopPropagation();
    handleViewTypeDetails(theme);
  };

  return (
    <StyledAccordion
      expanded={isExpanded}
      onChange={handleSelectProduct}
      style={selected ? { border: "3px solid #005EA2" } : {}}
    >
      <StyledAccordionSummary
        id={`ProductCard-${theme.name.replace(/\s/g, "")}`}
        aria-label={`product card - ${theme.displayName}`}
        aria-controls={`expand-${theme.displayName.replace(/\s/g, "")}`}
        expandIcon={isExpanded ? 
        <RemoveIcon onClick={handleExpandCard} style={{color: selected ? "white" : "#1B1B1B"}}/> 
        : <AddIcon onClick={handleExpandCard} style={{color: selected ? "white" : "#1B1B1B"}}/>}
        role="form"
        selected={selected}
      >
        <StyledFormControl
          aria-label={`select product type ${theme.displayName}`}
          onClick={handleSelectProduct}
          onFocus={(event) => {
            event.stopPropagation();
          }}
          control={<></>}
          label={
            <Typography
              gutterBottom
              variant="body1"
              component="h3"
              style={{
                color: selected ? "white" : "#1B1B1B",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                margin: 0,
                paddingLeft: "5px",
              }}
            >
              {theme.displayName}
            </Typography>
          }
        />

        <AccordionActions style={{ padding: 0, marginLeft: "auto" }}>
          <Tooltip title={t("product.themeTab.info")} arrow>
            <StyledIconButton
              aria-label={`view more info ${theme.displayName}`}
              alt="View More Info"
              onClick={handleViewMoreInfo}
            >
              <StyledInfoIcon fontSize="small" inverse={selected}/>
            </StyledIconButton>
          </Tooltip>
        </AccordionActions>
      </StyledAccordionSummary>

      <StyledAccordionDetails style={{ display: "block" }}>
        <Button
          style={{ padding: 0, width: "100%" }}
          onClick={handleSelectProduct}
        >
          <CardMediaContainer>
            <StyledCardMedia
              component="img"
              alt={theme.displayName}
              height="128"
              image={themeImages[theme.image]}
              title={theme.displayName}
            />
          </CardMediaContainer>
        </Button>
        {props.children}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default ProductTypeCard;
