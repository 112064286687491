
export function validateInput(input, type){
    const validate = require("validate.js")
    const constraints = {
        email:{
            email: true
        },
        mapName: {
            presence: true,
            format: {
                pattern: "^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$",
                message: "contains invalid characters. Please remove special characters and leading/trailing spaces."

            },
            length: {
                minimum: 1,
                maximum: 39,
                message: "must be between 1 and 39 characters."
            }
        },
        customName:  {
            presence: true,
            format: {
                pattern: "^[A-Za-z0-9]+(?: +[A-Za-z0-9]+)*$",
                message: "contains invalid characters. Please remove special characters and leading/trailing spaces."

            },
            length: {
                minimum: 1,
                maximum: 39,
                message: "must be between 1 and 39 characters."
            }
        },
    }

    const response = validate({[type]: input}, constraints);
    return response[type] ? response[type] : true;
}