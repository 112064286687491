// Framework and third-party non-ui
import * as React from "react";
import { useTranslation } from "react-i18next";

// Hooks, context, and constants
import { useProductsContext, useUIContext, useConfigContext } from "contexts";

// App components
import { ProductTypeCard } from "components/Card";

// JSON & Styles
import { useTheme } from "@mui/material/styles";
import {
  DrawerContainer,
  ContentContainer,
  ButtonControlContainer,
  NavButton,
  TitleContainer,
  OptionsContainer,
  StyledList,
  StyledListItem,
} from "./SelectMapType-styled";

// Third-party components (buttons, icons, etc.)
import { Typography, useMediaQuery } from "@mui/material";
import { ButtonBox } from "../SelectMap/SelectMap-styled";

export const SelectMapType = () => {
  /** Internationalization */
  const { t } = useTranslation();

  /** Styles */

  /** State */
  const { config } = useConfigContext();
  const {
    data: { contours, formats },
  } = config.config;
  const {
    state: { products },
    dispatch,
    getSelectedProducts,
  } = useProductsContext();

  const UIContext = useUIContext();

  const [isNextButtonDisabled, setIsNextButtonDisabled] = React.useState(true);
  const [themeList, setThemeList] = React.useState([]);

  const appTheme = useTheme();
  const isMobile = useMediaQuery(appTheme.breakpoints.down("sm"));

  const nextButtonText = "Next";

  /** Actions */
  const handleButtonController = () => {
    //isMobile
    UIContext.toggleDrawer({
      isOpen: isMobile ? false : true,
      tab: "product maps",
    });
  };

  const handleSelectProductType = (item) => {
    if (products[item.name]) {

      const updatedProducts = Object.keys(products).map(p=>{
        if(products[p].productId === item.name){
          return {...products[p], selected: !products[item.name].selected}
        }
        else if(!products[item.name].selected){
          return {...products[p], selected: false}
        }
        else{
        return {...products[p]}
        }
          
      })

      dispatch({
        type: "edit products",
        payload: {
          products: updatedProducts,
        },
      });
    } else {

      //add
      const updatedProducts = Object.keys(products).map(p=>{
        return {...products[p], selected: false}      
      })
      console.log(updatedProducts)

      dispatch({
        type: "add products",
        payload: { mapTypes: [item], contours, formats, selected: true },
      });

      if(products?.length>0){
        dispatch({
          type: "edit products",
          payload: {
            products: updatedProducts,
          },
        });
      }

    }
  };

  const handleViewTypeDetails = (productType) => {
    UIContext.setPopup({
      id: "theme details",
      content: { theme: productType },
    });
  };

  /** Effects */
  React.useEffect(() => {
    const selectedProducts = getSelectedProducts();
    if (selectedProducts.length > 0) {
      setIsNextButtonDisabled(false);
    } else {
      setIsNextButtonDisabled(true);
    }
  }, [getSelectedProducts, products]);

  React.useEffect(() => {
    const getThemeList = () => {
      if (!config.loading) {
        const themes = config?.config?.data?.themes
          ? config?.config?.data?.themes
          : [];
        setThemeList(themes);

        if (Object.keys(themes).length === 1) {
          const productType = themes[Object.keys(themes)[0]];

          dispatch({
            type: "add products",
            payload: {
              mapTypes: [productType],
              contours,
              formats,
              selected: true,
            },
          });
        }else{

          dispatch({
            type: "add products",
            payload: {
              mapTypes: Object.keys(themes).map(id=>themes[id]),
              contours,
              formats,
              selected: false,
            },
          });

        }
      }
    };
    getThemeList();
  }, [config, contours, dispatch, formats]);

  return (
    <DrawerContainer>
      <ContentContainer p={3}>
        {/* Title */}
        <TitleContainer>
          <Typography variant="h6" component="h2" color="textPrimary">
            {t("product.themeTab.header")}
          </Typography>
        </TitleContainer>
        {/* Subtitle */}
        <TitleContainer>
          <Typography color="textSecondary">
            {t("product.themeTab.note")}
          </Typography>
        </TitleContainer>

        <OptionsContainer>
          {/* Themes */}
          <StyledList>
            {/* Themes */}
            {Object.keys(themeList).map((key) => {
              const item = themeList[key];

              const selected = products[item.name]?.selected ? true : false;

              return (
                <StyledListItem alignItems="flex-start" key={key}>
                  <ProductTypeCard
                  selected={selected}
                  theme={item}
                  handleToggleSelection={handleSelectProductType}
                  handleViewTypeDetails={handleViewTypeDetails}
                  />
                </StyledListItem>
              );
            })}
          </StyledList>
        </OptionsContainer>
      </ContentContainer>

      <ButtonControlContainer>
        <ButtonBox>
          <NavButton
            disabled={isNextButtonDisabled}
            onClick={handleButtonController}
            variant="contained"
            color="primary"
          >
            {nextButtonText}
          </NavButton>
        </ButtonBox>
      </ButtonControlContainer>
    </DrawerContainer>
  );
};
