// Styling Utilities
import { styled } from "@mui/material/styles";

// Components
import { ListItem, ListItemIcon, Drawer, IconButton } from "@mui/material";

// Properties

// Component Exports

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  height: theme.spacing(8),
  width: theme.spacing(18),
  [theme.breakpoints.down("sm")]: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: theme.spacing(1),
  },
  backgroundColor: theme.palette.background.secondary,
  color: theme.palette.black.secondary,
  "&:hover": {
    background: theme.palette.grey.light,
    opacity: 0.75,
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.blue.main,
    "&:hover": {
      backgroundColor: theme.palette.grey.medium,
    },
    color: theme.palette.background.default,
  },
}));

export const StyledListItemIcon = styled(ListItemIcon)(
  ({ theme, ...props }) => ({
    color: props.open
      ? theme.palette.background.default
      : theme.palette.black.secondary,
    minWidth: theme.spacing(4),
  })
);

export const ContentDrawer = styled(Drawer)(({ theme, ...props }) => ({
  zIndex: 2,
  position: "relative",
  height: "100%",
  // paddingBottom: props.isbanneropen === "true" ? 150 : 100,

  // paddingLeft: theme.spacing(18),
  [theme.breakpoints.down("sm")]: {
    // paddingLeft: theme.spacing(5),
  },

  "& .MuiDrawer-paper": {
    zIndex: 2,
    height: "calc(100%)",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 40px)",
      height: "calc(100%)",
    },
    [theme.breakpoints.up("sm")]: props.open
      ? {
          width: 360,
        }
      : {
          width: theme.spacing(9),
        },

    position: "relative",

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: props.open ? "#FFFFFF" : "#EEEEEE",

    overflowX: props.open ? "" : "hidden",
    width: props.open ? "100%" : "0 !important",
  },
}));

export const ControllerDrawer = styled(Drawer)(({ theme }) => ({
  "&.MuiDrawer-root": {
    zIndex: 3,
    width: theme.spacing(18),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(5),
    },
  },

  "& .MuiDrawer-paper": {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    zIndex: 3,
    overflowX: "hidden",
    background: "#EEEEEE",
    width: theme.spacing(18),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(5),
    },
    height: "100%",
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: "#007150",
  borderRadius: 0,
  width: "100%",
  justifyContent: "left",
}));
