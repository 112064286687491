// Styling Utilities
import { styled } from "@mui/material/styles";

// Components
import {
  Box,
  Button
} from "@mui/material";

// import InfoIcon from "calcite-ui-icons-react/InformationFIcon";

const buttonContainerHeight = 70;

export const DrawerContainer = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
}));

export const ContentContainer = styled(Box)(({ theme, ...props }) => ({
  padding: 10,
  paddingBottom: 90,
  height: "inherit",
  scrollBehavior: "smooth",
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
}));

export const OptionsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  overflow: "auto",
  height: "75%",
}));

export const DetailsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  overflow: "auto",
  height: "100%",
}));

export const ButtonControlContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  alignItems: "center",
  display: "flex",
  width: "100%",
  height: buttonContainerHeight,
  bottom: 0,
  backgroundColor: theme.palette.background.default,
  boxShadow:
    "0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)",
}));

export const NavButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    marginRight: theme.spacing(2),
  },
  // variant="contained" color="primary"

  "&.MuiButton-containedSecondary": {
    backgroundColor: theme.palette.green.main,
    "&.Mui-disabled": {
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
}));

export const SearchContainer = styled(Box)(({ theme }) => ({
  padding: "1rem 0", 
  width: "100%",
  display: "flex"
}));

export const CardContainer = styled(Box)(({ theme }) => ({
  padding: "1rem 0", 
  width: "100%",
  display: "flex",
  flexDirection: "column"
}));

export const TableContainer = styled(Box)(({ theme }) => ({
  padding: 0, 
  width: "100%",
  display: "flex",
  overflow: "auto",
  whiteSpace: "nowrap",
  // height:"100%"
}));