// Framework and third-party non-ui
import * as React from "react";
// import { useTranslation } from "react-i18next";

// Hooks, context, and constants

// App components

// JSON & Styles
import {
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalDialog,
  ModalActions,
} from "./Modal-styled";

// Third-party components (buttons, icons, etc.)
import {
  Backdrop,
  Fade,
  IconButton,
  Typography,
  Button,
  Checkbox
} from "@mui/material";
import CloseIcon from "calcite-ui-icons-react/XIcon";

export const DeleteMapWarning = ({ open, setOpen, content }) => {
  /** Internationalization */
  // const { t } = useTranslation();

  /** Styles */
  const textStyle = { color: "#1B1B1B", display: "block" };

  /** State */
  const [selected, setSelected] = React.useState(false);
  const { handleDeleteMaps } = content || {}

  const title = "Warning";
  const message = "You are attempting to delete a map selection. Select confirm to delete map(s)."

  /** Actions */
    const handleContinue = () => { 
        sessionStorage.setItem('usgs-dynamap-storage', JSON.stringify({showDeleteWarning: !selected}));
        handleDeleteMaps();
        setOpen(false);
    };

    const handleClose = () => {
        sessionStorage.setItem('usgs-dynamap-storage', JSON.stringify({showDeleteWarning: !selected}));
        setOpen(false);
    };

    const handleChange = (event) => {
        sessionStorage.setItem('usgs-dynamap-storage', JSON.stringify({showDeleteWarning: !selected}));
        setSelected(event.target.checked)
    }

  return (
    <ModalContainer
      open={open}
      onClose={handleClose}
      aria-labelledby="delete map warning"
      aria-describedby="confirm to delete map(s)"
      role="main"

      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 100,
      }}
    >
      <Fade in={open}>
        <ModalContent>
          {/* Header */}
          <ModalHeader>
            <Typography variant="h5" component="h1" gutterBottom color="textPrimary">
              {title}
            </Typography>
            <IconButton
              style={{ position: "absolute", right: 0 }}
              onClick={handleClose}
              aria-label="close modal button"
            >
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          {/* Content */}
          <ModalDialog>
            <Typography style={textStyle}>{message}</Typography>
          </ModalDialog>
          {/* Hide Message */}
          <ModalDialog>
            <Typography color="textSecondary" align="center">
              <Checkbox
                checked={selected}
                onChange={handleChange}
                inputProps={{"aria-label": "checkbox"}}
              />
              Do not show this message again.
            </Typography>
          </ModalDialog>
          {/* Actions */}
          <ModalActions>
            <Button
                style={{marginRight: "10px"}}
                variant="outlined"
                color="primary"
                onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={handleContinue}
            >
              Confirm
            </Button>
          </ModalActions>
        </ModalContent>
        </Fade>
    </ModalContainer>
  );
};
