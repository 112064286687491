// Framework and third-party non-ui
import * as React from "react";
import { configRequest, notificationRequest } from "utilities/requests";

// import esriRequest from "@arcgis/core/request"

// Hooks, context, and constants
import { useUIContext, useConfigContext, useMapContext } from "contexts";

// App components
import Header from "components/Header";
import Footer from "components/Footer";
import ActionBar from "components/ActionBar";
import Map from "components/Map";
import {
  Notifications,
  ContourDetails,
  ThemeDetails,
  DeleteMapWarning,
  MoveWarning,
  LayerDetails,
  MapLimit,
} from "components/Modal";
import { Loader, LoaderError } from "components/Loader";
import Toolbox from "components/Toolbox";

// JSON & Styles
import config from "config";
import {
  PageLayout,
  ContentWrapper,
  ActionBarWrapper,
  MapWrapper,
} from "./App-styled";

// Third-party components (buttons, icons, etc.)
import { CircularProgress, Backdrop, Typography, Box, useMediaQuery } from "@mui/material";
import { Continue } from "components/Modal/Continue";
import Banner from "components/Banner";
import { OrderOverlay } from "components/Tracker/OrderOverlay";
import { useTheme } from "@mui/styles";
import CubeIcon from "calcite-ui-icons-react/CubeIcon"
import { OrderDetails } from "components/Tracker/OrderDetails";

const Empty = () => {
    return (<div style={{
        color: "#71767A",
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection:"column",
        display:"flex"
    }}>
        <div><CubeIcon/></div>
        <Typography>{"Track your order"}</Typography>
    </div>)
}


const TaskLoader = ({ open, text }) => {
  return (
    <Backdrop open={open} style={{ zIndex: 1003 }}>
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <Box style={{ textAlign: "center" }}>
          <CircularProgress color="inherit" />
        </Box>
        <Box>
          <Typography variant="h6" style={{ color: "black" }}>
            {text}
          </Typography>
        </Box>
      </Box>
    </Backdrop>
  );
};

const App = () => {
  //** State */
  const UIContext = useUIContext();
  const ConfigContext = useConfigContext();
  const MapContext = useMapContext();
  const { setConfig } = ConfigContext;

  const [, setIsMapLimitOpen] = React.useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const [notificationTitle, setNotificationTitle] = React.useState("");

  const [isBannerOpen, setIsBannerOpen] = React.useState(true);
  const [appConfig, setAppConfig] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isConfigError, setIsConfigError] = React.useState(false);

  const [notificationsLoaded, setNotificationsLoaded] = React.useState(false);

  const [orders, setOrders] = React.useState({})
  const [ordersListOrder, setOrdersListOrder] = React.useState([])
  const [selectedOrder, setSelectedOrder] = React.useState(null)


  const appTheme = useTheme();
  const isMobile = useMediaQuery(appTheme.breakpoints.down("sm"));

  //** Actions */
  const handleCloseModal = () => {
    UIContext.setPopup(null);
  };

  //** Effects */

  React.useEffect(() => {
    const setMapExportLimitState = () => {
      const localStorageState = localStorage.getItem("usgs-dynamap-storage");
      if (localStorageState) {
        const { showExportLimit } = JSON.parse(localStorageState);
        showExportLimit && setIsMapLimitOpen(true);
      } else {
        setIsMapLimitOpen(true);
      }
    };
    setMapExportLimitState();
  }, []);

  React.useEffect(() => {
    const getNotifications = async () => {
      if (
        appConfig?.data?.bannerServiceUrl &&
        notifications.length < 1 &&
        !notificationsLoaded
      ) {
        const response = await notificationRequest(
          appConfig.data.bannerServiceUrl
        );

        setNotificationsLoaded(true);

        if (response.success) {
          setNotifications(response.data);
          setNotificationTitle(response.preview)
        }
      }
    };
    getNotifications();

    return () => {};
  }, [notifications, appConfig, notificationsLoaded]);

  React.useEffect(() => {
    const getConfig = async () => {
      const response = await configRequest();
      const { success, ...results } = response;

      if (success) {
        setAppConfig({
          ...config,
          ...results,
        });
        setConfig({
          loading: false,
          config: {
            ...config,
            ...results,
          },
        });
      } else {
        setAppConfig(config);
        setConfig({ loading: false, config: config });
        setIsConfigError(true);
      }

      setIsLoading(false);
    };

    getConfig();
  }, [setConfig]);

  // Set map grid visibility
  React.useEffect(() => {
    const onDrawerViewUpdate = () => {
      MapContext.state.ready &&
        !(
          UIContext.state.drawer.mainView === "product maps" ||
          UIContext.state.drawer.subView === "edit-main" ||
          UIContext.state.drawer.subView === "edit-layers"
        ) &&
        MapContext.setMapType(null);
    };
    onDrawerViewUpdate();

  }, [MapContext, UIContext.state.drawer]);
 
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <React.Suspense fallback={<CircularProgress />}>
          <PageLayout>
            <Header role="banner" />
            {notifications.length > 0 && isBannerOpen && (
              <Banner
                preview={notificationTitle}
                setIsNotificationOpen={setIsNotificationOpen}
                setIsBannerOpen={setIsBannerOpen}
              />
            )}

            {isConfigError ? (
              <LoaderError />
            ) : (
              <ContentWrapper role="main">
                {/* Action Bar */}
                <ActionBarWrapper className="ActionBar">
                  <ActionBar
                    isBannerOpen={notifications.length > 0 && isBannerOpen}
                    orders={orders}
                    setOrders={setOrders}
                    listOrder={ordersListOrder}
                    setListOrder={setOrdersListOrder}
                    selectedOrder={selectedOrder}
                    setSelectedOrder={setSelectedOrder}
                  />
                </ActionBarWrapper>
                {/* Map */}
                <div id="search-widget-container" />

                {ConfigContext.config.loading === false && (
                  <MapWrapper className="Map" id="Map">
                    {UIContext.state.toolbox.isOpen && (
                      <Toolbox
                        initTool="custom"
                        mapTypeName={MapContext.selectedMapTypeName}
                      />
                    )}
              
        {/* If in mobile, wait till we close the drawer before rendering the map,
                     else it will hang because the component will render but the view won't and
                      we are waiting on the view to reset loading to false*/}
                    { !isMobile && <Map />}
                    { (isMobile && !UIContext.state.drawer.isOpen ) && <Map />}

                    {UIContext.state.drawer.mainView === 'track' && !isMobile &&
                      (<OrderOverlay isDrawerOpen={UIContext.state.drawer.isOpen}>
                        {!selectedOrder && <Empty/>}
                        {selectedOrder && 
                        <div style={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '15px',
                          background: 'white',
                        }}>
                        <OrderDetails
                          onReturn={()=>{}}
                          order={orders[selectedOrder]}
                          maps={orders[selectedOrder]?.maps}
                          isMobile={false}
                          productTypes={ConfigContext.config.config.data.themes}
                        />
                        </div>
                        }
                      </OrderOverlay>)
                    }

                  </MapWrapper>
                )}

                {/* Modals */}

                <Notifications
                  open={isNotificationOpen} //isMapLimitOpen
                  setOpen={setIsNotificationOpen}
                  message={notifications}
                />
                <Continue
                  isOpen={UIContext.state.popup?.id === "continue"}
                  onClose={handleCloseModal}
                />
                <ContourDetails
                  open={UIContext.state.popup?.id === "contour details"}
                  setOpen={handleCloseModal}
                />
                <ThemeDetails
                  open={UIContext.state.popup?.id === "theme details"}
                  setOpen={handleCloseModal}
                  content={UIContext.state.popup?.content}
                />
                <LayerDetails
                  isOpen={UIContext.state.popup?.id === "layer details"}
                  onClose={handleCloseModal}
                  title={UIContext.state.popup?.title}
                  content={UIContext.state.popup?.content}
                />
                <DeleteMapWarning
                  open={UIContext.state.popup?.id === "delete map warning"}
                  setOpen={handleCloseModal}
                  content={UIContext.state.popup?.content}
                />
                <MoveWarning
                  isOpen={UIContext.state.popup?.id === "move warning"}
                  onClose={handleCloseModal}
                  title={UIContext.state.popup?.title}
                  content={UIContext.state.popup?.content}
                  // title="Error: Unable to move Extent to this location"
                  // content="OnDemand Topo map products can only be requested within the bounds of a State or Territory of the United States where The National Map data exists."
                />
                <MapLimit
                  isOpen={UIContext.state.popup?.id === "map limit warning"}
                  onClose={handleCloseModal}
                  title={UIContext.state.popup?.title}
                  content={UIContext.state.popup?.content}
                />
              </ContentWrapper>
            )}

            <Footer role="contentinfo" />
          </PageLayout>
          { <TaskLoader
            open={UIContext.state.loading?.status}
            text={UIContext.state.loading?.message}
          />}
        </React.Suspense>
      )}
    </>
  );
};

export default App;
