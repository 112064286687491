// Framework and third-party non-ui
import * as React from "react";
import { useTranslation } from "react-i18next";

// Hooks, context, and constants
import {
  useCartContext,
  useConfigContext,
  useProductsContext,
  useUIContext,
} from "contexts";

// App components
import Items from "./Items";
import Edit from "./Edit";
import Checkout, { Results } from "../../Modal";

// JSON & Styles
import { Layers } from "./Edit/Layers";

import {
  DrawerContainer,
  ContentContainer,
  ButtonControlContainer,
  NavButton,
  ButtonBox,
} from "./Cart-styled";
import { request } from "utilities/requests";

// Third-party components (buttons, icons, etc.)

const verifyMapStructure = (paramArray) => {
  const invalidMaps = paramArray
    .filter((m) => !m.geometry?.rings)
    .map((m) => {
      if (m.customName?.length > 0) return m.customName;
      else return m.mapSheetName;
    });
  return { isValidMaps: invalidMaps.length < 1, invalidMaps };
};

const Cart = () => {
  /** Internationalization */
  const { t } = useTranslation();

  /** Styles */

  /** State */
  const {
    state: { products },
    dispatch,
    getTotalMapCount,
  } = useCartContext();

  const { config } = useConfigContext();

  const {
    data: { formats },
  } = config.config;

  const UIContext = useUIContext();
  const ProductsContext = useProductsContext();

  const [open, setOpen] = React.useState(false);
  const [submitStatus, setSubmitStatus] = React.useState(null);

  const [editableProducts, setEditableProducts] = React.useState(products);
  const [edited, setEdited] = React.useState(false);

  const [exportServiceUrl, setExportServiceUrl] = React.useState(null);

  const initProductCount = Object.keys(editableProducts).length;
  const [selectedProduct, setSelectedProduct] = React.useState(
    initProductCount === 1
      ? editableProducts[Object.keys(editableProducts)[0]]
      : null
  );
  const maps =
    editableProducts && selectedProduct
      ? editableProducts[selectedProduct.productId].maps
      : [];
  const [layers, setLayers] = React.useState(
    maps[Object.keys(maps)[0]]?.customizedLayers
  );

  const [email, setEmail] = React.useState("");

  /** Actions */

  const handleCheckoutButton = () => {
    setOpen(!open);
  };

  const handleCancelButton = () => {
    setEditableProducts(products);
    UIContext.setDrawerContent("cart");
  };

  const handleCancelLayerSelection = () => {
    UIContext.setDrawerContent("edit-main");
    setLayers(maps[Object.keys(maps)[0]]?.customizedLayers);
  };

  const handleSaveLayerSelection = () => {

    const updateTypes = Object.entries(products).map(([id, type]) => {

      const update =  Object.fromEntries(
        Object.entries(type.maps).map(([mapid, map]) => {
          return [
            mapid,
            {
              ...map,
              customizedLayers: layers,
            },
          ];
        })
      );

      return {...type, maps: update}
    })

    let cartUpdates = {}
    updateTypes.forEach(type=> cartUpdates={...cartUpdates, [type.productId]: {...type, maps: type.maps}})

    const updatedCart = {
      ...cartUpdates
    };

    const updatedProducts = Object.entries(ProductsContext.state.products).map(
      ([, product]) => {
        const update = Object.fromEntries(
          Object.entries(product.maps).map(([mapid, map]) => {
            return [
              mapid,
              {
                ...map,
                customizedLayers: layers,
              },
            ];
          })
        );
        return { ...product, maps: update };
      }
    );

    dispatch({
      type: "edit products",
      payload: { products: updatedCart, formats },
    });

    ProductsContext.dispatch({
      type: "edit products",
      payload: { products: updatedProducts },
    });

    setEditableProducts(updatedCart);

    UIContext.setDrawerContent("edit-main");
  };

  const handleSaveButton = () => {
    if (edited) {
      dispatch({
        type: "edit products",
        payload: { products: editableProducts, formats },
      });
    }

    UIContext.setDrawerContent("cart");
  };

  const handleEditButton = (product) => {
    const selectedMaps = editableProducts[product.productId].maps

    setLayers(
      selectedMaps[Object.keys(selectedMaps)[0]]?.customizedLayers
    );
    setSelectedProduct(product)
    UIContext.setDrawerContent("edit-main");
  };

  const handleReturnToSelection = () => {
    UIContext.toggleDrawer({
      isOpen: !(
        UIContext.state.drawer.isOpen &&
        UIContext.state.drawer.mainView === "product type"
      ),
      tab: "product type",
      content: "product type",
    });
  }

  /** Effects */

  React.useEffect(() => {
    const getExportServiceUrl = () => {
      if (!config.loading) {
        setExportServiceUrl(
          config.config.data.singleExportProcessingService?.url
        );
      }
    };

    getExportServiceUrl();
  }, [config]);

  //TODO: reorganize

  const handleExport = async (paramArray) => {
    UIContext.setLoading({
      status: true,
      message: "Exporting, please wait...",
    });
    console.log({
      note: "Export Parameters",
      url: exportServiceUrl,
      payload: paramArray,
    });
    setOpen(false);

    const { isValidMaps, invalidMaps } = verifyMapStructure(paramArray);

    if (!isValidMaps) {
      UIContext.setLoading({ status: false, message: null });
      setSubmitStatus({
        status: "failed",
        message: `The following map(s) have invalid data: ${invalidMaps.join(
          ", "
        )}. Please remove these maps from the cart before attempting to checkout. <br/><br/> ${t(
          "modal.results.content3"
        )}`,
      });
      return;
    }

    // TODO: Update to use requests
    // const payload = formatExportParams(paramArray);
    console.log( exportServiceUrl)
    const response = await request(exportServiceUrl, { products_as_json: paramArray })
    UIContext.setLoading({ status: false, message: null });

    console.log(response)
    const orderId = response.data?.slice(response.data.indexOf(':') + 1)?.trim()

    if(response.success){
      setSubmitStatus({
        status: "success",
        message: `${t(
          "modal.results.content1"
          )} <strong>${email}</strong>. Your order number is <strong>${orderId}</strong>. Please retain this order number for your records and to monitor your request's progress. <br/><br/> ${t(
          "modal.results.content2"
          )} <br/><br/> ${t("modal.results.content3")} `,
      });
      
      dispatch({ type: "reset" });

    }else{

      setSubmitStatus({
        status: "failed",
        message: `An error occurred while exporting maps. Please wait and try again later. <br/><br/> ${t(
          "modal.results.content3"
          )}`,
      });

    }



    // const gpExport = new Geoprocessor(exportServiceUrl);

    //     gpExport
    //       .waitForJobCompletion(jobId, options)
    //       .then((j) => {
    //         const { jobStatus } = j || {};
    //         console.log(`Job ${jobId} completed with status: ${jobStatus}.`);
    //         UIContext.setLoading({ status: false, message: null });
    //         if (jobStatus === "job-succeeded") {
    //           dispatch({ type: "reset" });
    //           setSubmitStatus({
    //             status: "success",
    //             message: `${t(
    //               "modal.results.content1"
    //             )} <strong>${email}</strong>. <br/><br/> ${t(
    //               "modal.results.content2"
    //             )} <br/><br/> ${t("modal.results.content3")} `,
    //           });
    //         } else {
    //           setSubmitStatus({
    //             status: "failed",
    //             message: `An error occurred while exporting maps. Please wait and try again later. <br/><br/> ${t(
    //               "modal.results.content3"
    //             )}`,
    //           });
    //         }
    //       })
    //       .catch((response) => {
    //         console.error(response);
    //         UIContext.setLoading({ status: false, message: null });
    //         setSubmitStatus({
    //           status: "failed",
    //           message: `An error occurred while exporting maps. Please wait and try again later.  <br/><br/> ${t(
    //             "modal.results.content3"
    //           )}`,
    //         });
    //       });
    //   })
    //   .catch((response) => {
    //     console.error(response);
    //     UIContext.setLoading({ status: false, message: null });
    //     setSubmitStatus({
    //       status: "failed",
    //       message: `An error occurred while exporting maps. Please wait and try again later.  <br/><br/> ${t(
    //         "modal.results.content3"
    //       )}`,
    //     });
    //   });
  };

  const disableCheckout = getTotalMapCount() < 1;

  return (
    <DrawerContainer>
      {/* Options Control: Cart, Edit, Options */}
      <ContentContainer p={3}>
        {(UIContext.state.drawer.subView === "cart" ||
          UIContext.state.drawer.subView === undefined) && (
          <Items onEditMaps={handleEditButton}/>
        )}
        {UIContext.state.drawer.subView === "edit-main" && (
          <Edit
            editableProducts={editableProducts}
            setEditableProducts={setEditableProducts}
            setEdited={setEdited}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        )}
        
      {UIContext.state.drawer.subView === "edit-layers" && (
          <Layers
            layers={layers}
            setLayers={setLayers}
            onCancel={handleCancelLayerSelection}
          />
        )}

      </ContentContainer>


      {/* Checkout Control */}
      <ButtonControlContainer>
        {(UIContext.state.drawer.subView === "cart" ||
          UIContext.state.drawer.subView === undefined) && (
          <ButtonBox>
            <NavButton
              onClick={handleReturnToSelection}
              disabled={disableCheckout}
              variant="contained"
              color="primary"
            >
              Select More Maps
            </NavButton>
            <NavButton
              onClick={handleCheckoutButton}
              disabled={disableCheckout}
              variant="contained"
              color="success"
            >
              {t("cart.checkoutButton")}
            </NavButton>
          </ButtonBox>
        )}
        {UIContext.state.drawer.subView === "edit-main" && (
          <ButtonBox>
            <NavButton
              variant="outlined"
              color="primary"
              onClick={handleCancelButton}
            >
              {t("cart.cancelButton")}
            </NavButton>
            <NavButton
              variant="contained"
              color="primary"
              onClick={handleSaveButton}
            >
              {t("cart.saveButton")}
            </NavButton>
          </ButtonBox>
        )}
        {UIContext.state.drawer.subView === "edit-layers" && (
          <ButtonBox>
            <NavButton
              variant="outlined"
              color="primary"
              onClick={handleCancelLayerSelection}
            >
              {t("cart.cancelButton")}
            </NavButton>
            <NavButton
              variant="contained"
              color="primary"
              onClick={handleSaveLayerSelection}
            >
              {t("cart.saveButton")}
            </NavButton>
          </ButtonBox>
        )}
      </ButtonControlContainer>

      {/* Modals */}
      <Checkout
        open={open}
        setOpen={setOpen}
        handleExport={handleExport}
        email={email}
        setEmail={setEmail}
      />
      <Results
        open={submitStatus?.status ? true : false}
        setOpen={setSubmitStatus}
        status={submitStatus?.status}
        message={submitStatus?.message}
      />
    </DrawerContainer>
  );
};

export default Cart;
