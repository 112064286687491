// Framework and third-party non-ui
import * as React from "react";


// Hooks, context, and constants

// App components
import { OrderMapCard } from "components/Card/OrderMapCard";

// JSON & Styles
import { CardContainer } from "./Tracker-styled";
import { Grid } from "@mui/material";

// Third-party components (buttons, icons, etc.)

export const OrderCards = ({maps, productTypes}) => {
    /** Internationalization */

    /** Styles */

    /** State */
    const formattedMaps = maps.map(item => {
        const productType = productTypes[Object.keys(productTypes).find(key=>{
            return productTypes[key]?.displayName === item.type
        })]
        item.displayContour = !productType.deactivateContourSelection

        return item
    })

    /** Actions */

    /** Effects */

    return (<CardContainer sx={{overflow: "auto"}}>

        <Grid container spacing={2}>
            {formattedMaps.map((m, idx) => <Grid key={idx} item sm={12} md={8} lg={6}><OrderMapCard
                
                map={m}
            /></Grid>)}
            
        </Grid>

    </CardContainer>)
}