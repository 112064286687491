// Framework and third-party non-ui
import * as React from "react";
import { useTranslation } from "react-i18next";

// Hooks, context, and constants

// App components
import themeImages from "assets/img/productThemes";

// JSON & Styles
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledCardMedia,
  CardMediaContainer,
  StyledPencilIcon,
  StyledFormControl,
  StyledIconButton,
} from "./Card-Styled";

// Third-party components (buttons, icons, etc.)
import { AccordionActions, Typography, Tooltip, Chip } from "@mui/material";

import AddIcon from "calcite-ui-icons-react/PlusIcon";
import RemoveIcon from "calcite-ui-icons-react/MinusIcon";

const CartItemCard = ({
  product,
  onEditMaps,
  ...props
}) => {

  /** Internationalization */
  const { t } = useTranslation();

  /** Styles */

  /** State */
  const [isExpanded, setIsExpanded] = React.useState(true);

  /** Actions */
  const handleExpandCard = () => {
    setIsExpanded(!isExpanded);
  };

  const handleEditMaps = (event) => {
    event.stopPropagation();
    onEditMaps(product);
  };

  return (
    <StyledAccordion
      expanded={isExpanded}
      onChange={handleExpandCard}
    >
      <StyledAccordionSummary
        id={`ProductCard-${product.productId.replace(/\s/g, "")}`}
        aria-label={`product card - ${product.type.displayName}`}
        aria-controls={`expand-${product.type.displayName.replace(/\s/g, "")}`}
        expandIcon={isExpanded ? <RemoveIcon /> : <AddIcon/>}
        role="form"
        selected={false}
      >
        <StyledFormControl
          aria-label={`select product type ${product.type.displayName}`}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onFocus={(event) => {
            event.stopPropagation();
          }}
          control={<></>}
          label={
            <Typography
              gutterBottom
              variant="body1"
              component="h3"
              style={{
                color: "#1B1B1B",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                margin: 0,
                paddingLeft: "5px",
              }}
            >

                <Chip
                color="primary"
                size="small"
                label={Object.keys(product.maps).length}
                sx={{
                fontWeight: "bold",
                marginRight: "8px",
                height: "20px",
                width: "20px",
                "& .MuiChip-label": {
                overflow: "inherit",
                textOverflow: "inherit",
                whiteSpace: "inherit",
                },
                }}
                />
             
              {product.type.displayName}

              <>  </>
            </Typography>
          }
        />

        <AccordionActions style={{ padding: 0, marginLeft: "auto" }}>
          <Tooltip title={t("product.themeTab.info")} arrow>
            <StyledIconButton
              aria-label={`view more info ${product.type.displayName}`}
              alt="Edit Maps"
              onClick={handleEditMaps}
            >
              <StyledPencilIcon fontSize="small" style={{color: "gray", height: "20px"}} />
            </StyledIconButton>
          </Tooltip>
        </AccordionActions>
      </StyledAccordionSummary>

      <StyledAccordionDetails style={{ display: "block" }}>
      <CardMediaContainer>
            <StyledCardMedia
              component="img"
              alt={product.type.displayName}
              height="128"
              image={themeImages[product.type.image]}
              title={product.type.displayName}
            />
          </CardMediaContainer>
        {props.children}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default CartItemCard;
