import * as React from "react";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "utilities/localStorageTools";

const ProductsContext = React.createContext();

/** 
products = {
id: {type: {...}, maps: {id:{...}}, selectedMaps: [], isSelected: false}, ...
}
*/
const init = () => {
  return {
    products: {},
    status: {},
    error: {},
  };
};

const productsReducer = (state, action) => {
  const { type, payload } = action;
  const {
    products,
    mapTypes,
    maps,
    mapProduct,
    cartProducts,
    contours,
    formats,
    selected,
  } = payload || {};

  switch (type) {
    case "add products": {
      if (!mapTypes || mapTypes.length < 1) {
        throw new Error(`Missing mapTypes input in payload: ${mapTypes}`);
      }
      const newProducts = mapTypes.reduce((obj, mapType) => {
        const productId = mapType.name;
        const product = {
          productId,
          type: mapType,
          maps: {},
          selectedMaps: [],
          layers: {},
          selected: selected,
        };

        if (state.products[productId]) {
          product.maps = state.products[productId].maps
          product.selectedMaps = state.products[productId].selectedMaps
          product.layers = state.products[productId].layers
          product.selected = state.products[productId].selected

          return { ...obj, [productId]: product };
        } else {
          return { ...obj, [productId]: product };
        }
      }, {});

      const updatedProducts = { ...state.products, ...newProducts };
      setLocalStorageItem("products", { ...state, products: updatedProducts });

      return { ...state, products: updatedProducts };
    }
    case "remove products": {
      if (!products || products.length < 1) {
        throw new Error(`Missing products input in payload: ${products}`);
      }

      const remainingProducts = products.reduce((obj, product) => {
        const { productId } = product;
        const { [productId]: remove, ...remainingProducts } = obj;
        return remainingProducts;
      }, state.products);

      setLocalStorageItem("products", {
        ...state,
        products: remainingProducts,
      });
      return { ...state, products: remainingProducts };
    }
    case "edit products": {
      if (!products || products.length < 1) {
        throw new Error(`Missing products input in payload: ${products}`);
      }

      const updatedProducts = products.reduce((obj, product) => {
        const { productId } = product;
        return { ...obj, [productId]: product };
      }, state.products);
      setLocalStorageItem("products", { ...state, products: updatedProducts });
      return { ...state, products: updatedProducts };
    }
    case "add maps": {
      if (!maps || maps.length < 1) {
        throw new Error(`Missing maps input in payload: ${maps}`);
      }
      if (!mapProduct) {
        throw new Error(`Missing mapProduct input in payload: ${mapProduct}`);
      }
      if (!cartProducts) {
        throw new Error(
          `Missing cartProducts input in payload: ${cartProducts}. These are required to ensure unique mapIds.`
        );
      }

      const { productId } = mapProduct;

      const productState = state.products[productId];
      const productType = productState.type;
      const productMaps = productState.maps;

      const updatedMaps = maps.reduce((obj, map) => {
        // build map
        const { custom, cell } = map;
        let initId = custom ? Object.keys(obj).length + 1 : cell?.id;
        let duplicate =
          Object.keys(state.products).find(
            (productId) => state.products[productId]?.maps[`${initId}custom`]
          ) ||
          Object.keys(cartProducts).find(
            (productId) => cartProducts[productId]?.maps[`${initId}custom`]
          );

        // mapId cannot exist in any other product or cart
        while (duplicate) {
          initId = initId + 1;
          const updateId = initId;
          duplicate =
            Object.keys(state.products).find(
              (productId) =>
                state.products[productId]?.maps[`${updateId}custom`]
            ) ||
            Object.keys(cartProducts).find(
              (productId) => cartProducts[productId]?.maps[`${updateId}custom`]
            );
        }
        const mapId = initId;

        const mapSheetName = map?.cell?.mapSheetName;
        const name = custom ? `${mapSheetName} ${mapId}` : mapSheetName;
        const format = formats.find(
          (format) => format.id === productType?.defaultFormat
        );
        const contour = contours.find(
          (contour) => contour.id === productType?.defaultContour
        );
        const includePrint = productType?.defaultIncludePrint;

        const newMap = {
          ...map,
          mapId: custom ? `${mapId}custom` : `${mapId}grid`,
          name,
          format,
          contour,
          includePrint,
          checked: false,
          expanded: true,
        };
        return { ...obj, [newMap.mapId]: newMap };
      }, productMaps);

      const updatedProducts = {
        ...state.products,
        [productId]: { ...productState, maps: updatedMaps },
      };
      setLocalStorageItem("products", { ...state, products: updatedProducts });
      return { ...state, products: updatedProducts };
    }
    case "remove maps": {
      if (!maps || maps.length < 1) {
        throw new Error(`Missing maps input in payload: ${maps}`);
      }
      if (!mapProduct) {
        throw new Error(`Missing mapProduct input in payload: ${mapProduct}`);
      }

      const { productId } = mapProduct;
      const productState = state.products[productId];
      const productMaps = productState.maps;

      const remainingMaps = maps.reduce((obj, map) => {
        const { mapId } = map;
        const { [mapId]: remove, ...remainingMaps } = obj;
        return remainingMaps;
      }, productMaps);

      const updatedProducts = {
        ...state.products,
        [productId]: { ...productState, maps: remainingMaps },
      };
      setLocalStorageItem("products", { ...state, products: updatedProducts });
      return { ...state, products: updatedProducts };
    }
    case "edit maps": {
      if (!maps || maps.length < 1) {
        throw new Error(`Missing maps input in payload: ${maps}`);
      }
      if (!mapProduct) {
        throw new Error(`Missing mapProduct input in payload: ${mapProduct}`);
      }

      const { productId } = mapProduct;
      const productState = state.products[productId];
      const productMaps = productState.maps;

      const updatedMaps = maps.reduce((obj, map) => {
        const { mapId } = map;
        const updatedMaps = { ...obj, [mapId]: map };

        return updatedMaps;
      }, productMaps);

      const updatedProducts = {
        ...state.products,
        [productId]: { ...productState, maps: updatedMaps },
      };
      setLocalStorageItem("products", { ...state, products: updatedProducts });
      return { ...state, products: updatedProducts };
    }
    case "add layer": {
      throw new Error(`Action type not available: ${type}`);
    }
    case "remove layer": {
      throw new Error(`Action type not available: ${type}`);
    }
    case "reset": {
      const store = init();
      setLocalStorageItem("products", store);
      return init();
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

export const ProductsContextProvider = ({ children }) => {
  const storedState = getLocalStorageItem("products");
  const initState =
    storedState?.success && storedState?.data?.products
      ? storedState.data
      : { products: {}, status: {}, error: {} };

  /** State */
  const [state, dispatch] = React.useReducer(productsReducer, initState);

  /** Utility Functions */
  const getProductCount = () => {
    const productCount = Object.keys(state.products).length;
    return productCount;
  };

  const getProductMapCount = (product) => {
    const mapCount = Object.keys(state.products[product.productId].maps).length;
    return mapCount;
  };

  const getTotalMapCount = () => {
    const products = Object.entries(state.products);
    const reducer = (count, entry) => count + Object.keys(entry[1].maps).length;
    const mapCount = products.reduce(reducer, 0);
    return mapCount;
  };

  const getSelectedProducts = () => {
    const products = Object.entries(state.products)
      .map(([, product]) => {
        return product;
      })
      .filter((product) => {
        return product.selected;
      });
    return products;
  };

  const value = {
    state,
    dispatch,
    getProductCount,
    getProductMapCount,
    getTotalMapCount,
    getSelectedProducts,
  };

  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
};

export const useProductsContext = () => {
  const productContext = React.useContext(ProductsContext);
  if (!productContext)
    throw new Error(
      "Cannot use `useProductsContext` outside of a ProductsContextProvider"
    );
  return productContext;
};

/** Asynchronous Actions */

//sample
export const update = async (dispatch, product, updates) => {
  //mod dispatch to search actions.payload.updates
  //will update status{}
  dispatch({ type: "start update", payload: updates });
  try {
    //async operation(s)
    //update product{} and status{}
    const updatedProduct = product; //await asyncModule.asyncFunction(product, updates)
    dispatch({ type: "finish update", payload: { product: updatedProduct } });
  } catch (error) {
    //mod dispatch to search actions.payload.error
    //update status{} and error{}
    dispatch({ type: "fail update", payload: error });
  }
};
