import styled from "styled-components";

export const PageLayout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

export const ContentWrapper = styled.main`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const ActionBarWrapper = styled.div`
  display: flex;
`;

export const MapWrapper = styled.div`
  position: relative;
  background-color: #d6d7d9;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
