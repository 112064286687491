 
 import React, { createContext, useContext } from "react";
 import * as watchUtils from "@arcgis/core/core/watchUtils";
 
 // move to map util
 import Graphic from "@arcgis/core/Graphic";
 import { useUIContext } from "./UIContext";
 import { useTheme } from "@mui/material/styles";
 import { useMediaQuery } from "@mui/material";
 import { useConfigContext } from "./ConfigContext";
 import Basemap from "@arcgis/core/Basemap";
 import TileLayer from "@arcgis/core/layers/TileLayer";
 
 const MapContext = createContext();
 
 // TODO: move to map constants
 
 export const gridMapSymbol = ({ isChecked }) => {
   const type = "simple-fill";
   const style = "solid";
   const color = isChecked ? [255, 165, 0, 0.25] : [255, 165, 0, 0.5];
   const outline = isChecked
     ? { color: [0, 113, 80], width: 3 }
     : { color: [0, 122, 194], width: 1 };
 
   return { type, color, outline, style };
 };
 
 export const customMapSymbol = ({ isChecked }) => {
   const type = "simple-fill";
   const style = "solid";
   const color = isChecked ? [0, 122, 194, 0.25] : [0, 122, 194, 0.5];
   const outline = isChecked
     ? { color: [0, 113, 80], width: 3 }
     : { color: [0, 122, 194], width: 1 };
 
   return { type, color, outline, style };
 };
 
 const mapReducer = (state, action) => {
   const { type, payload } = action;
 
   switch (type) {
     case "add mapview": {
       return {
         ...state,
         mapView: payload.view,
         mapLayers: payload.mapLayers,
         ready: true,
         isLoading: false,
       };
     }
     case "set loading": {
       return {
         ...state,
         isLoading: payload.isLoading,
       };
     }
     default: {
       throw new Error(`Unhandled action type: ${type}`);
     }
   }
 };
 
 export const MapContextProvider = ({ children }) => {
   const [state, dispatch] = React.useReducer(mapReducer, {
     mapView: null,
     ready: false,
     mapLayers: [],
     isLoading: false,
   });
 
   const [selectedMapTypeName, setSelectedMapTypeName] = React.useState(null);
   const [isGridVisible, setIsGridVisible] = React.useState(true);
   const [isGridDisplayScale, setIsGridDisplayScale] = React.useState(false);
   const [selectedMapGeometry, setSelectedMapGeometry] = React.useState(null);
   const [baseMap, setBaseMap] = React.useState(null)
 
   const UIContext = useUIContext();
   const ConfigContext = useConfigContext();
 
   const appTheme = useTheme();
   const isMobile = useMediaQuery(appTheme.breakpoints.down("sm"));
 
   // get default basemap
   
   // useEffect to set the initial basemap and set it in state
   React.useEffect(() => {
 
     // wait for the config to load and make sure we haven't already set the baseMap by modifying it in the map view
     if (!Object.keys(ConfigContext.config.config).length || baseMap){return}
   // if we are creating the initial base map from config, go get the config default basemap
   const {
     data: { basemaps, defaultAppBasemap },
   } = ConfigContext.config.config;
 
   const defaultBaseMap = basemaps.find(
     (basemap) => basemap.value === defaultAppBasemap,
   );
   // create a new baseMap object using the JS API
   const basemap = new Basemap({
     baseLayers: [
       new TileLayer({
         url: defaultBaseMap.url,
         title: defaultBaseMap.value,
       }),
     ],
     title: defaultBaseMap.value,
     id: "0",
   });
   // set that basemap in state
   setBaseMap(basemap)
   // watch config so we fire when config finally loads
   },[Object.keys(ConfigContext.config.config)])
 
   const getLayer = (layerName) => {
     if (!state.ready) {
       return null;
     }
 
     const layer = state.mapLayers.find((l) => l.title === layerName);
 
     return layer;
   };
 
   const getLayerView = (layerName) => {
     if (!state.ready) {
       return null;
     }
 
     const layer = state.mapView.layerViews.items.find(
       (lv) => lv.layer.title === layerName,
     );
 
     return layer;
   };
 
   const getLayerVisibility = () => {
     return isGridVisible;
   };
 
   //Update Graphics
   const setGraphics = ({ type, maps }) => {
     const addGraphic = (mapItem) => {
       // Create a symbol for rendering the graphic
 
       const fillSymbol = mapItem.custom
         ? customMapSymbol({ isChecked: mapItem.checked })
         : gridMapSymbol({ isChecked: mapItem.checked });
 
       // Add the geometry and symbol to a new graphic
       const polygonGraphic = new Graphic({
         geometry: mapItem.geometry,
         symbol: fillSymbol,
         attributes: mapItem,
       });
 
       // const textGraphic = new Graphic({
       //   geometry: map.geometry,
       //   symbol: textSymbol,
       //   attributes: map,
       // })
 
       state.mapView.graphics.addMany([polygonGraphic]);
     };
 
     if (type === "add" && maps) {
       for (const mapItem of maps) {
         addGraphic(mapItem);
       }
     }
 
     if (type === "update" && maps) {
       if (maps) {
         const mapList = Object.values(maps);
         //remove invalid graphics
         let remove = [];
         for (
           let index = 0;
           index < state.mapView?.graphics?.items?.length;
           index++
         ) {
           const id = state.mapView?.graphics?.items[index]?.attributes?.id;
           if (!maps[id]) {
             remove.push(state.mapView?.graphics?.items[index]);
           }
         }
         remove.map((graphic) => {
           state.mapView.graphics.remove(graphic);
           return true;
         });
 
         //add missing graphics
         let add = [];
         for (let index = 0; index < mapList.length; index++) {
           const id = mapList[index].mapId;
           const mapGraphics = state.mapView?.graphics?.items;
           if (!mapGraphics.includes((g) => g.id === id)) {
             add.push(mapList[index]);
           }
         }
 
        add.map((map) => {
           addGraphic(map);
           return true;
         });
       }
     }
   };
 
   const setLayerVisibility = (isVisible) => {
     setIsGridVisible(isVisible);
   };
 
   const setMapType = (layerName, maps) => {
     if (!state.mapView) {return}
     state.mapView.graphics.removeAll();
     //set all visible attributes
     for (const lv of state.mapView.layerViews.items) {
       if (lv.layer.title === layerName) {
         lv.visible = true;
         lv.layer.opacity = isGridVisible ? 0.5 : 0;
         lv.layer.labelsVisible = isGridVisible;
       } else {
         lv.visible = false;
       }
     }
 
     setGraphics({ type: "add", maps: maps });
     setSelectedMapTypeName(layerName);
   };
 
   React.useEffect(() => {
     const updateGridDisplayScale = (gridLayer, mapScale) => {
       if (gridLayer && mapScale < gridLayer.layer.minScale) {
         setIsGridDisplayScale(true);
       }
       if (gridLayer && mapScale > gridLayer.layer.minScale) {
         setIsGridDisplayScale(false);
       }
     };
 
     const getScaleWatchHandler = () => {
       if (!state.ready) {
         return null;
       }
       const gridLayer = state.mapView?.allLayerViews.items.find((layer) => {
         return layer.layer.title === selectedMapTypeName;
       });
 
       updateGridDisplayScale(gridLayer, state.mapView.scale);
       const handler = watchUtils.watch(state.mapView, "scale", (scale) => {
         updateGridDisplayScale(gridLayer, scale);
       });
       return handler;
     };
 
     const watchScaleHandler = getScaleWatchHandler();
 
     return () => {
       if (watchScaleHandler) {
         watchScaleHandler.remove();
       }
     };
   }, [isGridVisible, selectedMapTypeName, state.mapView, state.ready]);
 
   React.useEffect(() => {
     const setMapPadding = () => {
       if (state.mapView?.padding) {
         state.mapView.ui.container.style.inset =
           !isMobile && UIContext.state.drawer.isOpen
             ? "0px 0px 0px 0px"
             : "0px";
         const zoom = state.mapView.zoom;
         state.mapView.padding.left =
           !isMobile && UIContext.state.drawer.isOpen ? 0 : 0;
           console.log('selectedMapGeometry', selectedMapGeometry)
         state.mapView.goTo(selectedMapGeometry || { center: state.mapView.center, zoom })
       }
     };
     setMapPadding();
   }, [UIContext.state.drawer.isOpen, isMobile, state.mapView]);
 
   React.useEffect(() => {
     console.log('selectedMapGeometry', selectedMapGeometry)
   }, [selectedMapGeometry])
   const gridState = { isGridDisplayScale, isGridVisible };
   const value = {
     state,
     gridState,
     dispatch,
     getLayer,
     getLayerView,
     getLayerVisibility,
     setLayerVisibility,
     setMapType,
     setGraphics,
     selectedMapTypeName,
     selectedMapGeometry,
     setSelectedMapGeometry,
     baseMap, 
     setBaseMap
   };
   return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
 };
 
 export const useMapContext = () => {
   const mapContext = useContext(MapContext);
   if (!mapContext)
     throw new Error(
       "Cannot use `useMapContext` outside of a MapContextProvider",
     );
   return mapContext;
 };
 
 
 