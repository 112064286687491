import * as geometryJsonUtils from "@arcgis/core/geometry/support/jsonUtils";
import {cloneDeep} from 'lodash';

const expiration_day = 14;
const app_local_storage_id = "topo-builder-store"

const serializeGeometries = (data, copyData) => {
    const types = data?.products

    if(types){
        for(const type in types){
            const items = types[type]?.maps

            for(const item in items){
                const geometry = items[item]?.geometry;
                // items[item].geometry = geometry.toJSON()
                copyData.products[type].maps[item].geometry = geometry.toJSON()
            }
        }
    }
}

const deSerializeGeometries = (data) => {
    const types = data?.products

    if(types){
        for(const type in types){
            const items = types[type]?.maps
            for(const item in items){
                const geometry = items[item]?.geometry;
                items[item].geometry = geometryJsonUtils.fromJSON(geometry)
            }
        }
    }
}

// Get local storage item if not expired
export const getLocalStorageItem = (id = null) => {
    if(!id){
        console.error("getLocalStorageItem missing id param")
        return {success: false, error: "Missing 'id' param.", data: null}
    }

    try {
        const storage = window.localStorage
        const initState = JSON.parse(storage.getItem(app_local_storage_id))
    
        const { expiration, [id]: data, ...localStorageState } = initState || {};
        const curDate = new Date();

        if(expiration){
            const expirationDate = new Date(JSON.parse(expiration));

            if(curDate > expirationDate){
                storage.removeItem(app_local_storage_id);
                const blankState = { expiration: JSON.stringify(curDate)}
                storage.setItem(app_local_storage_id, JSON.stringify(blankState))
                return {success: true, data: {}}
            }else{
                const newExpirationDate = new Date(curDate)
                newExpirationDate.setDate(curDate.getDate() + expiration_day)
                
                const updateState = { expiration: JSON.stringify(newExpirationDate), [id]: data, ...localStorageState}
                storage.setItem(app_local_storage_id, JSON.stringify(updateState))

                deSerializeGeometries(data)
                return {success: true, data: data}
            }
        }else{
            const blankState = { expiration: JSON.stringify(curDate)}
            storage.setItem(app_local_storage_id, JSON.stringify(blankState))
        }    
      } catch (e) {
        console.error(e);
        return {success: false, error: "Error retrieving data.", data: null}
      }

}

// Set local storage item
export const setLocalStorageItem = (id = null, data={}) => {
    
    if(!id){
        console.error("getLocalStorageItem missing id param")
        return {success: false, error: "Missing 'id' param.", data: null}
    }

    const storage = window.localStorage
    const curDate = new Date();
    const newExpirationDate = new Date(curDate)
    newExpirationDate.setDate(curDate.getDate() + expiration_day)

    try{
        const initState = JSON.parse(storage.getItem(app_local_storage_id))
        const { ...localStorageState } = initState || {};

        const copyData = cloneDeep(data)
        serializeGeometries(data, copyData)

        const updateState = {...localStorageState, expiration: JSON.stringify(newExpirationDate), [id]: copyData }
        storage.setItem(app_local_storage_id, JSON.stringify(updateState))
        return {success: true}
    }
    catch (e) {
        const copyData = cloneDeep(data)
        serializeGeometries(data, copyData)
        const updateState = {expiration: JSON.stringify(newExpirationDate), [id]: copyData }
        storage.setItem(app_local_storage_id, JSON.stringify(updateState))

        console.error(e);
        return {success: false, error: "Error retrieving data."}
    }

}

