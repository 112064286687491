import { createTheme } from "@mui/material/styles";

import publicSans from "@fontsource/public-sans";
//import "@fontsource/open-sans/500.css"; // Weight 500.

// A custom theme for this app
const CustomThemeDefault = createTheme({
  palette: {
    green: {
      main: "#007150",
      medium: "#007150",
    },
    blue: {
      main: "#00264C",
      lightest: "#2D90EC",
      light: "#1D5AAB",
      medium: "#005EA2",
      dark: "#00264C",
    },
    grey: {
      main: "#565C65",
      dark: "#565C65",
      medium: "#71767A",
      mediumLight: "#A9AEB1",
      light: "#D6D7D9",
      lightest: "#E5E5E5",
    },
    black: {
      main: "#1B1B1B",
      primary: "#1B1B1B",
      secondary: "#303030",
    },
    error: {
      main: "#D83933",
    },
    background: {
      default: "#FFFFFF",
      secondary: "#EEEEEE",
    },
    primary: { main: "#005EA2" },
    secondary: { main: "#565C65" },

    text: {
      default: "#1B1B1B",
      primary: "#1D5AAB",
      secondary: "#565C65",
      elements: "#005EA2",
    },
  },
  typography: {
    fontFamily: [publicSans, "sans-serif"].join(","),
    h1: {
      fontSize: "22px",
    },
    text: {
      default: "#1B1B1B",
      primary: "#1D5AAB",
      secondary: "#565C65",
      elements: "#005EA2",
    },
  },
  // panelMargin: unitCalc(CalciteTheme.baseline, 2, "/"),
  // panelPadding: unitCalc(CalciteTheme.baseline, 2, "/"),
  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.3)",
  border: "1px solid #eaeaea",
  overrides: {
    MuiInputBase: {
      root: {
        borderRadius: 0,
      },
    },
    MuiButtonBase: {},
    MuiCssBaseline: {
      "@global": {
        "@font-face": [publicSans],
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple
    },
  },
});

const { breakpoints } = CustomThemeDefault;

const CustomTheme = {
  ...CustomThemeDefault,
  overrides: {
    MuiTypography: {
      // adjust for screen size
      h1: {
        [breakpoints.down("sm")]: {
          fontSize: "1.3rem",
        },
      },
      h2: {
        [breakpoints.down("sm")]: {
          fontSize: "1.2rem",
        },
      },
      h3: {
        [breakpoints.down("sm")]: {
          fontSize: "0.8rem",
        },
      },
      h4: {
        [breakpoints.down("sm")]: {
          fontSize: "0.8rem",
        },
      },
      h5: {
        [breakpoints.down("sm")]: {
          fontSize: "0.8rem",
        },
      },
      h6: {
        [breakpoints.down("sm")]: {
          fontSize: "0.8rem",
        },
      },
      subtitle1: {
        [breakpoints.down("sm")]: {
          fontSize: "1rem",
        },
      },
      subtitle2: {
        [breakpoints.down("sm")]: {
          fontSize: "0.9rem",
        },
      },
      body1: {
        [breakpoints.down("sm")]: {
          fontSize: "1rem",
        },
      },
      body2: {
        [breakpoints.down("sm")]: {
          fontSize: "0.9rem",
        },
      },
    },
  },
  typography: {
    ...CustomThemeDefault.typography,
    h1: {
      ...CustomThemeDefault.typography.h1,
      fontSize: "22px",
      [breakpoints.down("md")]: {
        fontSize: "1.3rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "1.2rem",
      },
    },
    h2: {
      ...CustomThemeDefault.typography.h2,
      [breakpoints.down("md")]: {
        fontSize: "1.3rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "1.2rem",
      },
    },
    h3: {
      ...CustomThemeDefault.typography.h3,
      [breakpoints.down("md")]: {
        fontSize: "1.25rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "1.2rem",
      },
    },
    h4: {
      ...CustomThemeDefault.typography.h4,
      [breakpoints.down("md")]: {
        fontSize: "1.25rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "1.2rem",
      },
    },
    h5: {
      ...CustomThemeDefault.typography.h5,
      [breakpoints.down("md")]: {
        fontSize: "1.25rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "0.85rem",
      },
    },
    h6: {
      ...CustomThemeDefault.typography.h6,
      [breakpoints.down("md")]: {
        fontSize: "1.25rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "0.85rem",
      },
    },
    subtitle1: {
      ...CustomThemeDefault.typography.subtitle1,
      [breakpoints.down("md")]: {
        fontSize: "1rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
    },
    subtitle2: {
      ...CustomThemeDefault.typography.subtitle2,
      [breakpoints.down("md")]: {
        fontSize: "0.9rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "0.8rem",
      },
    },
    body1: {
      ...CustomThemeDefault.typography.body1,
      [breakpoints.down("md")]: {
        fontSize: "1rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
    },
    body2: {
      ...CustomThemeDefault.typography.body2,
      [breakpoints.down("md")]: {
        fontSize: "1rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
    },
    text: {
      ...CustomThemeDefault.typography.text,
      default: "#1B1B1B",
      primary: "#1D5AAB",
      secondary: "#565C65",
      elements: "#005EA2",
    },
  },
};

export default CustomTheme;
