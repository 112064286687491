// Framework and third-party non-ui
import * as React from "react";
// import { useTranslation } from "react-i18next";

// Hooks, context, and constants

// App components

// JSON & Styles
import { StyledFormControlLabel } from "./FolderTree-styled";

// Third-party components (buttons, icons, etc.)
import { Checkbox } from "@mui/material";
import { TreeView, TreeItem } from "@mui/lab";

import ChevronRightIcon from "calcite-ui-icons-react/ChevronRightIcon";
import ChevronDownIcon from "calcite-ui-icons-react/ChevronDownIcon";
import FolderClosedIcon from "calcite-ui-icons-react/FolderIcon";
// import FolderOpenIcon from "calcite-ui-icons-react/FolderOpenIcon";
import FileIcon from "calcite-ui-icons-react/FileIcon";

const traverse = ({ id, children }) => {
  let ids = [id];

  if (Array.isArray(children)) {
    const nodes = children.map((n) => {
      const child = traverse(n);
      return child;
    });

    ids = [...ids, ...nodes.flat(2)];
  }
  return ids;
};

const searchTree = (node, search) => {
  if (node.id === search) {
    return node;
  } else if (Array.isArray(node.children)) {
    let result = null;
    for (let i = 0; i < node.children.length; i++) {
      result = searchTree(node.children[i], search);
      if (result) return result;
    }
    return result;
  }
  return null;
};

const updateNodes = (tree, nodes, field, data) => {
  const node = searchTree(tree, nodes.id);
  node[field] = data;
  if (Array.isArray(node.children)) {
    for (let i = 0; i < node.children.length; i++) {
      updateNodes(node, node.children[i], field, data);
    }
  }
};

export const FolderTree = ({
  isInitExpanded = true,
  tree = null,
  setTree = () => {},
} = {}) => {
  const initExpanded = isInitExpanded && tree ? traverse(tree) : [];

  const handleCheck = (checked, nodes) => {
    const deepCopy = JSON.parse(JSON.stringify(tree));
    updateNodes(deepCopy, nodes, "selected", checked);
    setTree(deepCopy);
  };

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <StyledFormControlLabel
          control={
            <Checkbox
              checked={nodes.selected}
              onChange={(event) =>
                handleCheck(event.currentTarget.checked, nodes)
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          }
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              {nodes.children ? (
                <FolderClosedIcon size={15} />
              ) : (
                <FileIcon size={15} />
              )}

              <div style={{ paddingLeft: 5 }} dangerouslySetInnerHTML={{ __html: nodes.name }}/>
            </div>
          }
          key={nodes.id}
        />
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <TreeView
      defaultCollapseIcon={<ChevronDownIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultExpanded={initExpanded}
    >
      {tree && renderTree(tree)}
    </TreeView>
  );
};
