// Framework and third-party non-ui
import * as React from "react";
// import { useTranslation } from "react-i18next";

// Hooks, context, and constants

// App components

// JSON & Styles
import {
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalDialog,
  ModalActions,
} from "./Modal-styled";

// Third-party components (buttons, icons, etc.)
import {
  Backdrop,
  Fade,
  IconButton,
  Typography,
  Button,
} from "@mui/material";

import CloseIcon from "calcite-ui-icons-react/XIcon";

export const ThemeDetails = ({ open, setOpen, content }) => {
  /** Internationalization */
  // const { t } = useTranslation();

  /** Styles */

  /** State */
  const { theme } = content || {}
  const { displayName, moreDetails  } = theme || {};

  const title = displayName;
  const htmlBody = moreDetails

  /** Actions */
  const handleClose = () => {
    setOpen(false);
  };

  /** Effects */

  return (
    <ModalContainer
      open={open}
      onClose={handleClose}
      aria-labelledby="map type details modal"
      aria-describedby="information on the selected map type"
      role="main"

      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 100,
      }}
    >
      <Fade in={open}>
        <ModalContent>
          {/* Header */}
          <ModalHeader>
            <Typography variant="h5" component="h1" gutterBottom color="textPrimary">
              {title}
            </Typography>
            <IconButton
              style={{ position: "absolute", right: 0 }}
              onClick={handleClose}
              aria-label="close modal button"
            >
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          {/* Content */}
          <ModalDialog>
          <div
          style={{ color: "#565C65" }}
          dangerouslySetInnerHTML={{ __html: htmlBody }}
          />

          </ModalDialog>
          {/* Actions */}
          <ModalActions>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              Close
            </Button>
          </ModalActions>
        </ModalContent>
        </Fade>
    </ModalContainer>
  );
};
