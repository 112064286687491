// Framework and third-party non-ui
import * as React from "react";
// import { useTranslation } from "react-i18next";

// Hooks, context, and constants

// App components

// JSON & Styles
import { Popup } from "./Popup";

// Third-party components (buttons, icons, etc.)
import { Button } from "@mui/material";

// TODO: improve styling

export const MoveWarning = ({ isOpen, onClose, content, title }) => {
  /** Internationalization */
  // const { t } = useTranslation();

  /** State */

  /** Actions */

  /** Effects */

  const handleClosePopup = () => {
    onClose();
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClosePopup}
      title={title}
      control={
        <>
          <Button variant="outlined" onClick={handleClosePopup}>
            Close
          </Button>
        </>
      }
    >
      <div> {content} </div>
    </Popup>
  );
};
