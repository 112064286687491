// Styling Utilities
import { styled } from "@mui/material/styles";

// Components
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  IconButton,
} from "@mui/material";

import InfoIcon from "calcite-ui-icons-react/InformationFIcon";

// Properties
const productPaddingTop = 5;
const buttonContainerHeight = 70;

// Component Exports

export const DrawerContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  paddingTop: productPaddingTop,
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  padding: 10,
  paddingBottom: 72,
  height: "inherit",
  scrollBehavior: "smooth",
  display: "flex",
  flexDirection: "column",
}));

export const ButtonControlContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  alignItems: "center",
  display: "flex",
  width: "100%",
  height: buttonContainerHeight,
  bottom: 0,
  backgroundColor: theme.palette.background.default,
  boxShadow:
    "0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)",
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
}));

export const OptionsContainer = styled(Box)(({ theme }) => ({
  flex: "11 11 1px",
  height: "100%",
  width: "100%",
  overflow: "auto",
}));

export const NavButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    marginRight: theme.spacing(2),
  },
  // variant="contained" color="primary"

  "&.MuiButton-containedSecondary": {
    backgroundColor: theme.palette.green.main,
    "&.Mui-disabled": {
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
}));

export const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.blue.light,
  marginLeft: "auto",
}));

export const StyledList = styled(List)(({ theme }) => ({
  width: "100%",
  overflowX: "hidden",
  overflowY: "auto",
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
}));

export const ListItemFooter = styled(Box)(({ theme }) => ({
  width: "100%",
}));

export const ButtonBox = styled(Box)(({ theme }) => ({
  marginLeft: "auto",
}));

export const CardBox = styled(Box)(({ theme }) => ({
  padding: "10px 5px 0px 5px",
  display: "flex",
  justifyContent: "space-around",
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  padding: 3,
  fontSize: 12,
  fontWeight: 700,
}));

export const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  width: "100%",
  overflowX: "hidden",
  overflowY: "auto",
  padding: theme.spacing(0.5),
  color: theme.palette.black.secondary,
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontWeight: 700,
    color: theme.palette.black.main,
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: "#007150",
  borderRadius: 0,
  justifyContent: "left",
  backgroundColor: "transparent",
  "&:hover": {
    background: "none",
  },
}));
