// Framework and third-party non-ui
import * as React from "react";
import { useTranslation } from "react-i18next";

// Hooks, context, and constants
import { useUIContext, useMapContext } from "contexts";

// App components
import { ProductCard, MapCard } from "components/Card";

// JSON & Styles
import { TitleContainer, OptionsContainer } from "../Cart-styled";
import {
  StyledList,
  StyledListItem,
  CardBox,
  ListItemFooter,
  CustomButton,
} from "./Edits-styled";

// Third-party components (buttons, icons, etc.)
import {
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import LayersIcon from "calcite-ui-icons-react/LayersIcon";

const Edit = ({
  editableProducts,
  setEditableProducts,
  setEdited,
  selectedProduct,
  setSelectedProduct,
}) => {
  /** Internationalization */
  const { t } = useTranslation();

  /** Styles */

  /** State */
  // const [expanded, setExpanded] = React.useState(selectedProduct?.productId)

  const UIContext = useUIContext();
  const MapContext = useMapContext();

  /** Actions */
  const handleEditMaps = ({ maps, mapProduct }) => {
    // name, format, contour

    setEdited(true);

    const { productId } = mapProduct;
    const productState = editableProducts[productId];
    const productMaps = productState.maps;

    const updatedMaps = maps.reduce((obj, map) => {
      const { mapId } = map;
      const updatedMaps = { ...obj, [mapId]: map };

      return updatedMaps;
    }, productMaps);

    const updatedProducts = {
      ...editableProducts,
      [productId]: { ...productState, maps: updatedMaps },
    };

    setEditableProducts(updatedProducts);
  };

  const handleRemoveMaps = ({ maps, mapProduct }) => {
    setEdited(true);

    const { productId } = mapProduct;
    const productState = editableProducts[productId];
    const productMaps = productState.maps;

    const remainingMaps = maps.reduce((obj, map) => {
      const { mapId } = map;
      const { [mapId]: remove, ...remainingMaps } = obj;
      return remainingMaps;
    }, productMaps);

    const updatedProducts = {
      ...editableProducts,
      [productId]: { ...productState, maps: remainingMaps },
    };
    setEditableProducts(updatedProducts);
  };

  const handleSelectProduct = (product) => {
    if (product.productId === selectedProduct?.productId) {
      //compress and remove grid
      setSelectedProduct(null);
      //remove grid from map in effect
    } else {
      //highlight and compress others
      setSelectedProduct(product);
      //add grid to map in effect
    }
  };

  const handleToggleMap = (map, mapProduct) => {
    const expanded = !map.expanded;
    const updatedMap = { ...map, expanded };

    const updatedProducts = {
      ...editableProducts,
      [mapProduct.productId]: {
        ...mapProduct,
        maps: { ...mapProduct.maps, [updatedMap.mapId]: updatedMap },
      },
    };
    setEditableProducts(updatedProducts);

    // dispatch({type: "edit maps", payload:{maps: [updatedMap], mapProduct: mapProduct}})
  };

  const handleViewTypeDetails = (productType) => {
    UIContext.setPopup({
      id: "theme details",
      content: { theme: productType },
    });
  };

  const handleToggleGridVisibility = () => {
    MapContext.setLayerVisibility(!MapContext.gridState.isGridVisible);
  };

  const handleZoomToGrid = () => {
    const layers = MapContext.state.mapView.layerViews.items;
    const gridLayer = layers.find(
      (layer) => layer.layer.title === selectedProduct?.productId
    );
    if (gridLayer) {
      MapContext.state.mapView.scale = gridLayer.layer.minScale;
      MapContext.state.mapView.zoom = MapContext.state.mapView.zoom + 1;
      MapContext.setLayerVisibility(true);
    }
  };

  const handleEditLayers = (product) => {
    product && setSelectedProduct(product);
    UIContext.setDrawerContent("edit-layers");
  };

  /** Effects */

  React.useEffect(() => {
    if (selectedProduct) {
      
    const { productId } = selectedProduct;
    const productState = editableProducts[productId];
    const productMaps = productState.maps;

      // MapContext.setGraphics({
      //   type: "update",
      //   maps: Object.values(editableProducts[selectedProduct.type?.name].maps),
      // });
      MapContext.setMapType(
        selectedProduct.type?.name,
        Object.values(
          productMaps
        )
      );

    } else {
      MapContext.setMapType(null, []);
    }

    return () => {};
  }, [MapContext, UIContext, editableProducts, selectedProduct]);

  let scrollKey = null
  return (
    <>
      {/* Title */}
      <TitleContainer>
        <Typography variant="h6" component="h2" color="textPrimary">
          {t("cart.edits.title")}
        </Typography>
        {/* <Tooltip title={t("cart.edits.tooltip")} arrow aria-label={null}>
        <div style={{marginLeft: "auto"}}>
          <StyledInfoIcon />
        </div>
      </Tooltip> */}
      </TitleContainer>
      {/* Subtitle */}
      <TitleContainer>
        <Typography color="textSecondary">{t("cart.edits.note")}</Typography>
      </TitleContainer>

      <TitleContainer>
        <FormGroup
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                color="primary"
                inputProps={{ "aria-label": "controlled" }}
                onChange={handleToggleGridVisibility}
                checked={MapContext.gridState.isGridVisible}
                disabled={!MapContext.gridState.isGridDisplayScale}
              />
            }
            label={
              <div style={{
                color: MapContext.gridState.isGridDisplayScale ? 
                MapContext.gridState.isGridVisible ? '#1D5AAB' : 'grey'
                : '#1D5AAB',
                cursor: 'pointer',
              }} onClick={()=>
              {
                if(!MapContext.gridState.isGridDisplayScale){
                  handleZoomToGrid();
                  MapContext.setLayerVisibility(false);
                }
              }
              }>
                {MapContext.gridState.isGridDisplayScale ? "Display Grid" : "Zoom in to display Grid"}
              </div>
            }
          ></FormControlLabel>
        </FormGroup>
      </TitleContainer>

      {/* Items */}
      <OptionsContainer>
        <StyledList>
          {Object.keys(editableProducts).map((productId) => {
            const product = editableProducts[productId];
            const { type, maps } = product;
            const selected = product.productId === selectedProduct?.productId;

            if(selected){
            return (
              <StyledListItem alignItems="flex-start" key={productId}>
                {/* Product with Type and Maps */}
                <ProductCard
                  key={productId}
                  id={productId}
                  selected={selected}
                  theme={type}
                  handleToggleSelection={() => {
                    handleSelectProduct(product);
                  }}
                  handleViewTypeDetails={handleViewTypeDetails}
                  isCollapsible={false}
                >
                  <CardBox>
                    {Boolean(Object.keys(product.maps).length) && <CustomButton
                      style={{ marginLeft: "auto" }}
                      onClick={() => {
                        handleEditLayers(selectedProduct);
                      }}
                      variant="text"
                    >
                      <LayersIcon size={20} style={{ paddingRight: "4px" }} />
                      Edit Layers
                    </CustomButton>}
                  </CardBox>
                  {Object.keys(maps).map((mapId) => {
                    scrollKey = productId
                    const productMap = maps[mapId];
                    return (
                      <MapCard
                        key={mapId}
                        expanded={productMap.expanded}
                        map={productMap}
                        product={product}
                        handleToggleMap={() => {
                          handleToggleMap(productMap, product);
                        }}
                        mode="edit"
                        handleRemoveMaps={handleRemoveMaps}
                        handleEditMaps={handleEditMaps}
                      />
                    );
                  })}
                </ProductCard>
              </StyledListItem>
            );}
            else return null
          })}
        </StyledList>
      </OptionsContainer>

      <TitleContainer>
        {scrollKey ? (
          <ListItemFooter>
            <CustomButton
              variant="contained"
              disableElevation
              style={{ width: "100%", borderRadius: 0 }}
              onClick={() => {
                document.getElementById(scrollKey).scrollIntoView();
              }}
            >
              Back to Top
            </CustomButton>
          </ListItemFooter>
        ) : (
          <></>
        )}
        </TitleContainer>
    </>
  );
};
export default Edit;
