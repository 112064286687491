// Styling Utilities
import { styled } from "@mui/material/styles";

// Components
import { FormControlLabel, FormGroup } from "@mui/material";

// Properties

// Component Exports
export const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  width: "100%",
  overflowX: "hidden",
  overflowY: "auto",
  padding: theme.spacing(0.5),
  color: theme.palette.black.secondary,
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontWeight: 700,
    color: theme.palette.black.main,
  },
}));
