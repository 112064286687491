// Framework and third-party non-ui
import * as React from "react";

// Hooks, context, and constants

// App components

// JSON & Styles
import {
  StyledFooter,
  LinkContainer,
  LinkBoxTop,
  LinkBoxBottom,
  StyledLink,
  StyledLinkFirst,
} from "./Footer-styled";

// Third-party components (buttons, icons, etc.)

const Footer = () => {
  /** Internationalization */

  return (
    <StyledFooter role="contentinfo">
      <LinkContainer>
        <LinkBoxTop>
          <StyledLinkFirst href="https://www.doi.gov/privacy" target="_blank" 
                          rel="noopener noreferrer">
            DOI Privacy Policy
          </StyledLinkFirst>
          <StyledLink href="https://www.usgs.gov/policies-and-notices" target="_blank" 
                          rel="noopener noreferrer">
            Legal
          </StyledLink>
          <StyledLink href="https://www.usgs.gov/accessibility-and-us-geological-survey" target="_blank" 
                          rel="noopener noreferrer">
            Accessibility
          </StyledLink>
          <StyledLink href="https://www.usgs.gov/sitemap" target="_blank" 
                          rel="noopener noreferrer">Site Map</StyledLink>
          <StyledLink href="https://answers.usgs.gov/" target="_blank" 
                          rel="noopener noreferrer">Contact USGS</StyledLink>
        </LinkBoxTop>
        <LinkBoxBottom >
          <StyledLinkFirst href="https://www.doi.gov/" role="link" target="_blank" 
                          rel="noopener noreferrer">
            U.S. Department of the Interior
          </StyledLinkFirst>
          <StyledLink href="https://www.doioig.gov/" role="link" target="_blank" 
                          rel="noopener noreferrer">
            DOI Inspector General
          </StyledLink>
          <StyledLink href="https://www.whitehouse.gov/" role="link" target="_blank" 
                          rel="noopener noreferrer">
            White House
          </StyledLink>
          <StyledLink href="https://www.whitehouse.gov/omb/management/egov/" role="link" target="_blank" 
                          rel="noopener noreferrer">
            E-gov
          </StyledLink>
          <StyledLink href="https://www.doi.gov/pmb/eeo/no-fear-act" role="link" target="_blank" 
                          rel="noopener noreferrer">
            No Fear Act
          </StyledLink>
          <StyledLink href="https://www.usgs.gov/about/organization/science-support/foia" role="link" target="_blank" 
                          rel="noopener noreferrer">
            FOIA
          </StyledLink>
        </LinkBoxBottom>
      </LinkContainer>
    </StyledFooter>
  );
};

export default Footer;
