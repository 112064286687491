// Framework and third-party non-ui
import { Link, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import * as React from "react";

// Hooks, context, and constants

// App components

// JSON & Styles
import { TableContainer } from "./Tracker-styled";

// Third-party components (buttons, icons, etc.)

export const OrderTable = ({maps}) => {
    /** Internationalization */

    /** Styles */

    /** State */
    const fields = [
        {id: 'name', name: 'Product Name'},
        {id: 'type', name: 'Product Type'},
        {id: 'format', name: 'Format'},
        {id: 'id', name: 'Selection Type'},
        {id: 'url', name: 'Download Link', isLink: true},
        {id: 'layersRemoved', name: 'Layers Removed', isLayers: true},
    ]

    /** Actions */

    /** Effects */

    return (
    <TableContainer>
    <Table
        stickyHeader
        aria-label='maps table'
        size='small'
    >
    <TableHead>
        <TableRow>
            {fields.map(field=><TableCell  sx={{backgroundColor: 'rgba(0, 94, 162, 1)', color: 'white'}} key={field.id}> {field.name} </TableCell>)}
        </TableRow>
    </TableHead>
    <TableBody>
        {maps.map((m, idx)=><TableRow key={idx}>
            {fields.map(field=>{ 

                if(field.isLink){
                    return <TableCell key={m.id+'-' +field.id} >
                        <div style={{display: 'flex', width: 200}}>
                        <Link title={m[field.id]} href={m[field.id]} target="_blank" rel="noopener noreferrer" style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis"
                        }}> 
                        {m[field.id]} 
                        </Link>
                        </div>
                    </TableCell>
                }

                if(field.isLayers){
                    return <TableCell key={m.id+'-' +field.id} >
                        <div 
                        title={m[field.id]} 
                        style={{
                            display: 'flex', 
                            width: 200, 
                        }}>
                            <div style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis"
                            }}>
                              {m[field.id]}   
                            </div>     
                        </div>
                    </TableCell>
                }
                return <TableCell key={m.id+'-' +field.id}>
                {m[field.id]}
            </TableCell>
        })}
        </TableRow>)}
    </TableBody>

    </Table>
    </TableContainer>)
}