// Framework and third-party non-ui
import * as React from "react";
import { useTranslation } from "react-i18next";
import UserGuide from "assets/guides/USGS topoBuilder Application User Guide.pdf";

// Hooks, context, and constants
import { useConfigContext } from "contexts";
// App components
import usgsLogo from "assets/img/USGS_ID_white.png";

// JSON & Styles
import {
  StyledAppBar,
  StyledToolbar,
  StyledSubToolbar,
  StyledTypography,
  HeaderBox,
  TitleBox,
  SubtitleBox,
  LinkBox,
} from "./Header-styled";

// Third-party components (buttons, icons, etc.)
import { Typography, Link } from "@mui/material";

const Header = () => {
  /** Internationalization */
  const { t } = useTranslation();

  /** Styles */

  /** State */

  const { config } = useConfigContext();
  const { release } = config.config;

  /** Actions */

  /** Effects */

  return (
    <StyledAppBar aria-label="topoBuilder header">
      <StyledToolbar>
        <img
          src={usgsLogo}
          alt="USGS Title Logo"
          style={{ maxHeight: "50px" }}
        />
      </StyledToolbar>
      <StyledSubToolbar>
        <HeaderBox>
          <TitleBox>
            <StyledTypography variant="h6" component="h1" color="inherit">
              {t("header.title")}
            </StyledTypography>
          </TitleBox>
          <SubtitleBox>
            <StyledTypography variant="h6" component="h2" color="inherit">
              <Link
                href={release?.notes}
                color="inherit"
                variant="h6"
                target="_blank"
                rel="noopener noreferrer"
                title="topoBuilder Release Notes"
              >
                v: {release?.version}
              </Link>
            </StyledTypography>
          </SubtitleBox>
        </HeaderBox>
        <LinkBox>
          <Typography>
            <Link
              href={UserGuide}
              color="inherit"
              variant="h6"
              target="_blank"
              rel="noopener noreferrer"
              title="topoBuilder User Guide"
            >
              User Guide
            </Link>
          </Typography>
        </LinkBox>
      </StyledSubToolbar>
    </StyledAppBar>
  );
};
export default Header;
