// Framework and third-party non-ui
import * as React from "react";

// Hooks, context, and constants

// App components

// JSON & Styles
import { OrderCardTitle, OrderCardContent, StyledOrderCard, StyledConnector, StyledStep } from "./Card-Styled";

// Third-party components (buttons, icons, etc.)
import { CardActionArea, Stepper, Typography, Stack, IconButton } from "@mui/material";
import CloseIcon from "calcite-ui-icons-react/XIcon";

export const OrderStatusCard = ({order, isSelected, onSelect, isMobile, onRemove=()=>{}}) => {
    /** Internationalization */

    /** Styles */

    /** State */
    const status = {
        "Confirmed": 1,
        "Processing": 2, 
        "Delivered": 3,
        "Expired": 4
    }

    /** Actions */
    const handleSelect = () => {
        onSelect(order)
    }

    const handleRemove = (event) => {
        onRemove(order)
    }

    /** Effects */

    return (
        <StyledOrderCard selected={isSelected}>
            <OrderCardTitle
                action= { 
                    <IconButton title={"Remove from list"} style={{borderRadius: 0}} onClick={handleRemove}> <CloseIcon/></IconButton>
                }
                title={<div onClick={handleSelect}>{order.name}</div>}
                titleTypographyProps={{variant: isMobile ? "h4" : "h6"}}
            />
            <CardActionArea onClick={handleSelect}>
               
                <OrderCardContent>

                    <div style={{display: 'flex', color:"#171717", paddingBottom: '0.5rem'}}>
                        <Typography style={{color: '#71767A', paddingRight: 5}}>{'Order date:'}</Typography>
                        <Typography>{order.date}</Typography>
                    </div>
                    
                    <Typography style={{display: 'flex', color:"#171717",  paddingBottom: '0.25rem'}}>{order.status} {`(${order.progress}%)`}</Typography>
                    <Stack sx={{width: '100%', height: 10}} spacing={4}>
                    <Stepper
                        alternativeLabel
                        connector={<StyledConnector/>}
                        activeStep={status[order.status]}
                    >
                        {Object.keys(status).map((key, idx) =>  <StyledStep key={key} id={idx}/>)}
                    </Stepper>
                    </Stack>
                </OrderCardContent>
            </CardActionArea>
        </StyledOrderCard>
    );
}