// Framework and third-party non-ui
import * as React from "react";
import { useTranslation } from "react-i18next";
import { validateInput } from "utilities/validateInput";
// import { gpRequest } from "utilities/requests";

// Hooks, context, and constants
import { useCartContext } from "contexts";

// App components

// JSON & Styles
import {
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalDialog,
  ModalActions,
  StyledList,
} from "./Modal-styled";

// Third-party components (buttons, icons, etc.)
import {
  Backdrop,
  Fade,
  IconButton,
  Typography,
  TextField,
  Button,
  Link,
} from "@mui/material";

import CloseIcon from "calcite-ui-icons-react/XIcon";

// TODO: improve styling
const searchTreeForUnselected = (node) => {
  if (!node){return []}
  console.log('node', node)
  if (!node.children && !node.selected) {
    const { id } = node;
    return [id];
  } else if (Array.isArray(node.children)) {
    let result = [];
    for (let i = 0; i < node.children.length; i++) {
      const response = searchTreeForUnselected(node.children[i]);
      result = [...result, ...response];
    }
    return result;
  }
  return [];
};

//for each map
const getProductParams = ({
  theme,
  map,
  format,
  name,
  email,
  layersToRemove,
}) => {
  const customName = map?.cell?.mapSheetName === map.name ? "" : map.name;

  const params = {
    email: email,
    makeMapScript: theme.makeMapScript,
    toolName: theme.toolName,
    // mxd: theme.mxd,
    // gridXml: theme.gridXml,
    exporter: format.value,
    exportOption: "Export", //hard set
    geometry: map?.geometry,
    contourSmoothingScale: map?.contour?.scale,
    // mxd_ak: theme.mxd_ak,
    // mxd_pr: theme.mxd_pr,
    // mxd_pt: theme.mxd_pt,
    // mxd_usfs: theme.mxd_usfs,
    // mxd_ak_usfs: theme.mxd_ak_usfs,
    // mxd_pr_usfs: theme.mxd_pr_usfs,
    // gridXml_20: theme.gridXml_20,
    // gridXml_25: theme.gridXml_25,
    // gridXml_zipper: theme.gridXml_zipper,
    usgsLayoutRules: theme.usgsLayoutRules,
    // productionPDFXML: theme.productionPDFXML,
    // keep_mxd_backup: theme.keep_mxd_backup, //product.theme
    cell_id: map?.cell?.id, //data1
    // metadata_xml: theme.metadata_xml,
    // ustopo_legend: theme.ustopo_legend,
    primary_state: map?.cell?.state, //data2
    SmoothnessLevel: map?.contour?.id,
    mapSheetName: map?.cell?.mapSheetName, //data0
    customName: customName,
    productName: theme.name,
    Job_AOI_Name: name,
    layersToRemove: layersToRemove,
  };
  return params;
};

const Checkout = ({ open, setOpen, handleExport, email, setEmail }) => {
  /** Internationalization */
  const { t } = useTranslation();

  /** Styles */

  /** State */
  // const { config } = useConfigContext();

  // const {
  //   data: { emailLimitServiceUrl },
  // } = config.config;

  const {
    state: { products },
  } = useCartContext();
  const [productList, setProductList] = React.useState([]);
  const [paramArray, setParamArray] = React.useState([]);

  const [checkoutDisabled, setCheckoutDisabled] = React.useState(true);
  const [isValidEmail, setIsValidEmail] = React.useState(true);
  const [emailLimitCheck, setEmailLimitCheck] = React.useState({
    message: "",
    loading: false,
  });

  const emailLabelText = "Enter an Email";
  const emailHelperText =
    isValidEmail === true ? "" : "Error: Please enter a valid email.";

  React.useEffect(() => {
    const getList = () => {
      if (products) {
        const list = Object.values(products);
        setProductList(list);
      }
    };
    getList();
  }, [products]);

  // TODO: update per map and parameterize by config
  React.useEffect(() => {
    const setParams = () => {
      if (productList && productList.length > 0) {
        let paramList = [];
        for (let index = 0; index < productList.length; index++) {
          const product = productList[index];
          const mapList = Object.values(product.maps);
          for (let mapIndex = 0; mapIndex < mapList.length; mapIndex++) {
            // const layersToRemove = mapList[mapIndex]?.customizedLayers
            //   ?.filter((layer) => !layer.isChecked)
            //   .map((layer) => layer.name);
            const layersToRemove = searchTreeForUnselected(
              mapList[mapIndex]?.customizedLayers
            );

            const param = getProductParams({
              theme: product.type,
              map: mapList[mapIndex],
              format: mapList[mapIndex].format,
              name: product.name,
              email: email,
              layersToRemove: layersToRemove,
            });
            paramList.push(param);
          }
        }

        // Prints params
        let paramString = "";
        for (let par = 0; par < paramList.length; par++) {
          const productString = JSON.stringify(paramList[par]);
          if (par !== 0) {
            paramString += ",";
          }
          paramString += productString;
        }
        const params = "[" + paramString + "]";

        console.log(paramList);
        console.log(params);

        setParamArray(paramList);
      }
    };
    setParams();
  }, [email, productList]);

  // const handleEmailLimitCheck = async (email, mapCount) => {
  //   const url = emailLimitServiceUrl;
  //   const payload = [{ email: email }];
  //   const params = {
  //     Email_as_json: payload,
  //   };
  //   const options = {
  //     "env:outSR": "",
  //     "env:processSR": "",
  //     returnZ: false,
  //     returnM: false,
  //     returnTrueCurves: false,
  //     context: "",
  //     returnFeatureCollection: false,
  //   };

  //   const response = await gpRequest(url, params, options);
  //   if (response.error) {
  //     return {
  //       error: true,
  //       withinLimit: null,
  //       limit: null,
  //     };
  //   } else {
  //     const { exportsleft } = response.results?.value || {};
  //     const remainingMaps = exportsleft < 0 ? 0 : exportsleft;

  //     console.log({ exportsleft, dataType: typeof exportsleft, remainingMaps });
  //     return {
  //       error: false,
  //       withinLimit: remainingMaps >= mapCount || exportsleft > 9998,
  //       limit: remainingMaps,
  //     };
  //   }
  // };

  const handleSubmit = async () => {
    const validate = validateInput(email, "email");
    setIsValidEmail(validate);

    if (validate === true) {
      setCheckoutDisabled(true);
      setEmailLimitCheck({ message: "Processing your order.", loading: true });

      const validate = validateInput(email, "email");
      setIsValidEmail(validate);
      if (validate) {
        setCheckoutDisabled(true);
        // const mapCount = productList
        //   .map((product) => Object.keys(product.maps).length)
        //   .reduce((a, b) => a + b, 0);
        const emailLimitResponse = null; //await handleEmailLimitCheck(email, mapCount);
        const isWithinLimit = true; //emailLimitResponse.withinLimit;

        if (isWithinLimit) {
          setCheckoutDisabled(false);
          setEmailLimitCheck({
            message: "",
            loading: false,
          });
          handleExport(paramArray);
        } else {
          if (emailLimitResponse.error) {
            setEmailLimitCheck({
              message: `User map export limit exceeded check failed. Error retrieving map export limit for account.`,
              loading: false,
            });
          } else {
            setEmailLimitCheck({
              message: `User map export limit exceeded. Current map exports remaining: ${emailLimitResponse.limit}.`,
              loading: false,
            });
          }
        }
      } else {
        setCheckoutDisabled(true);
      }
    } else {
      setCheckoutDisabled(true);
    }
  };

  const handleEmailInput = async (event) => {
    if (checkoutDisabled) {
      setCheckoutDisabled(false);
      setEmailLimitCheck({ message: "", loading: false });
      setIsValidEmail(true);
    }
    setEmail(event.target.value);
  };

  return (
    <ModalContainer
      aria-label="checkout modal"
      role="main"
      open={open}
      onClose={() => {
        setEmail("");
        setOpen(!open);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalContent>
          {/* Header */}
          <ModalHeader>
            <Typography
              variant="h5"
              component="h1"
              gutterBottom
              color="textPrimary"
            >
              {t("cart.checkout")}
            </Typography>
            <IconButton
              style={{ position: "absolute", right: 0 }}
              onClick={() => {
                setEmail("");
                setOpen(!open);
              }}
              aria-label="close modal button"
            >
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          {/* Content */}
          <ModalDialog>
            <TextField
              error={isValidEmail !== true}
              helperText={emailHelperText}
              id="email-input"
              inputProps={{ "aria-label": "email input" }}
              label={emailLabelText}
              variant="outlined"
              style={{ width: "100%" }}
              onChange={handleEmailInput}
            />

            <StyledList></StyledList>
          </ModalDialog>
          {/* Actions */}
          <ModalActions>
            <div style={{ alignSelf: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={checkoutDisabled}
              >
                {t("cart.checkoutButton")}
              </Button>
            </div>
            <div style={{ marginLeft: "1rem", alignSelf: "center" }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
                component="body1"
              >
                {emailLimitCheck.message}
                <br />

                {emailLimitCheck?.message.length > 0 && (
                  <>
                    {" "}
                    Download US Topo:{" "}
                    <Link
                      href="https://www.usgs.gov/programs/national-geospatial-program/topographic-maps"
                      color="inherit"
                      variant="subtitle2"
                      underline="always"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      USGS Topographic Map
                    </Link>
                  </>
                )}
              </Typography>
            </div>
          </ModalActions>
        </ModalContent>
      </Fade>
    </ModalContainer>
  );
};

export default Checkout;
