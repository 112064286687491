// Styling Utilities
import { styled } from "@mui/material/styles";

// Components
import {
  Box,
  Button,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import InfoIcon from "calcite-ui-icons-react/InformationFIcon";

// Properties
const buttonContainerHeight = 70;

// Component Exports

export const DrawerContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  padding: 10,
  paddingBottom: 60,
  height: "inherit",
}));

export const ButtonControlContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  alignItems: "center",
  display: "flex",
  width: `calc(100% - ${theme.spacing(18)})`,
  [theme.breakpoints.down("sm")]: {
    width: `calc(100% - ${theme.spacing(5)})`,
  },
  height: buttonContainerHeight,
  bottom: 0,
  backgroundColor: theme.palette.background.default,
  boxShadow:
    "0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)",
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
}));

export const OptionsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  overflow: "auto",
  height: "95%",
  marginTop: "3%",
}));

export const NavButton = styled(Button)(({ theme }) => ({
  right: 0,
  position: "absolute",
  marginRight: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    marginRight: theme.spacing(2),
  },
  // variant="contained" color="primary"
}));

export const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.blue.light,
  marginLeft: "auto",
}));

export const StyledList = styled(List)(({ theme }) => ({
  width: "100%",
  overflowX: "hidden",
  overflowY: "auto",
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&.MuiListItem-root": {
    display: "flex",
    flexFlow: "column",
    alignItems: "flex-start",
  },
}));

export const StyledListItemTitle = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.black.main,
  fontWeight: "bold",
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.black.main,
}));

export const StyledSectionHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  fontWeight: 700,
  textDecoration: "underline",
  padding: "10px 0 0 0",
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  fontWeight: 400,
  textDecoration: "none",
  display: "flex",
  width: "100%",
  padding: "5px 0 5px 0",
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.main,
  fontWeight: 400,
  paddingBottom: 10,
}));
