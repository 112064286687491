// Framework and third-party non-ui
import React from "react";

// Hooks, context, and constants

// App components

// JSON & Styles
import { StyledMapLoader } from "./Map-styled";

// Third-party components (buttons, icons, etc.)
import { CircularProgress, Container } from "@mui/material";

const Loader = () => {
  return (
    <Container
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        height: "50px",
        width: "50px",
        transform: "translate(-50%, -50%)",
        zIndex: 3,
      }}
    >
      <CircularProgress />
    </Container>
  );
};

const MapLoader = () => {
  return (
    <>
      <StyledMapLoader />
      <Loader />
    </>
  );
};

export default MapLoader;
