// Framework and third-party non-ui
import * as React from "react";

// Hooks, context, and constants

// App components
import {
  BannerContainer,
  BannerContent,
  CloseButton,
  InfoButton,
  StyledCloseIcon,
  StyledInfoIcon,
  StyledCollapse,
} from "./Banner-styled";

// JSON & Styles

// Third-party components (buttons, icons, etc.)
import { Divider } from "@mui/material";

const Banner = ({ preview, setIsNotificationOpen, setIsBannerOpen }) => {
  /** Internationalization */

  /** Styles */

  /** State */
  const [isExpanded, setIsExpanded] = React.useState(preview ? true : false);

  /** Actions */

  const handleClose = () => {
    setIsExpanded(false);
    setIsBannerOpen(false);
  };

  const handleDisplayInfo = () => {
    setIsExpanded(false);
    setIsNotificationOpen(true);
    setIsBannerOpen(false);
  };

  /** Effects */
  React.useEffect(() => {
    if (preview) {
      setIsExpanded(true);
    }
  }, [preview]);

  return (
    <StyledCollapse in={isExpanded} timeout="auto" unmountOnExit>
      <BannerContainer>
        <BannerContent>
          <div
            style={{ color: "#303030" }}
            dangerouslySetInnerHTML={{ __html: preview }}
          />
        </BannerContent>
        <Divider orientation="vertical" />
        <InfoButton title="Open Notification" onClick={handleDisplayInfo}>
          <StyledInfoIcon />
        </InfoButton>
        <Divider orientation="vertical" />
        <CloseButton title="Close Banner" onClick={handleClose}>
          <StyledCloseIcon />
        </CloseButton>
      </BannerContainer>
    </StyledCollapse>
  );
};

export default Banner;
