// Framework and third-party non-ui
import * as React from "react";

// Hooks, context, and constants

// App components

// JSON & Styles

// Third-party components (buttons, icons, etc.)
import { CircularProgress, Container } from "@mui/material";

const Loader = () => {
    return <Container  style={{ display: "flex", flexDirection: "column", height: "100vh", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress/>
    </Container>
}

export default Loader