
// Framework and third-party non-ui
import * as React from "react";

import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

import { initMap, initView } from "data/map";

// Hooks, context, and constants
import { useMapContext, useConfigContext, useProductsContext } from "contexts";

// App components

// JSON & Styles
import { MapContainer } from "./Map-styled";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useUIContext } from "contexts";

import MapLoader from "./MapLoader";

// Third-party components (buttons, icons, etc.)

let pos = {};

const Map = () => {
  /** Internationalization */

  /** Styles */
  const appTheme = useTheme();
  const isMobile = useMediaQuery(appTheme.breakpoints.down("sm"));

  /** State */
  const UIContext = useUIContext();
  const ConfigContext = useConfigContext();
  const ProductsContext = useProductsContext();
  const [mapView, setMapView] = React.useState(null);

  
  const mapRef = React.useRef();
  const MapContext = useMapContext();

  /** Actions */

  /** Effects */

  React.useEffect(() => {
    const loadMap = async () => {

      UIContext.setLoading({ status: true, message: null });
      console.log("loading map", MapContext.state.isLoading);
      const map = initMap(MapContext);
      const { view, featureLayers } = initView({
        mapItem: map,
        mapRef: mapRef,
        config: ConfigContext.config.config,
        isMobile,
        MapContext
      });

      const layerViews = featureLayers.map((fl) => {
        return view.whenLayerView(fl);
      });

      await Promise.all(layerViews);

      view.when(() => {
        setMapView(view);
        MapContext.dispatch({
          type: "add mapview",
          payload: { view, mapLayers: featureLayers },
        });

        view.on("layerview-create-error", (event) => {
          console.warn(event, view, map);

          const { layer } = event || {};
          if (featureLayers.find((fl) => fl.url === layer.url)) {
            console.error(`Failed to load map type: ${layer.title}`);
          }
          if (layer && layer.title !== "MapIndex24K") {
            map.basemap = "topo-vector";
            // TODO: modify basemap method
            const featureLayer = new MapImageLayer({ url: layer.url });
            map.add(featureLayer);
          }
        });
      });

      UIContext.setLoading({ status: false, message: null });
    };

    loadMap();
  }, []);

  React.useEffect(() => {
    const products = ProductsContext.state.products;
    const selected = Object.keys(products)?.find((id) => products[id].selected);

    const addPointerEvents = () => {
      if (!mapView || !selected) return {};

      const th = selected === "100K Topographic Map" ? 230 : 58;
      const tw = selected === "100K Topographic Map" ? 370 : 46;

      const zoomHandle = mapView.watch("scale", () => {
        const gridOutline = document.getElementById("cursor-grid");
        const customToolButton = document.querySelector(
          "[aria-label='custom grid selection button']",
        );
        const customSelected = customToolButton?.getAttribute("aria-pressed");
        if (
          mapView.allLayerViews.items.length > 1 &&
          MapContext.gridState.isGridDisplayScale &&
          customSelected === "true"
        ) {
          const multiplier = 1155581.1085772559 / mapView.scale;
          const height = th * multiplier;
          const width = tw * multiplier;

          if (gridOutline) {
            gridOutline.style.left = pos.x - 0.5 * width + "px";
            gridOutline.style.top = pos.y - 0.5 * height + "px";
            gridOutline.style.height = height + "px";
            gridOutline.style.width = width + "px";
          } else {
            const newOutline = document.createElement("div");
            newOutline.style.position = "absolute";
            // newOutline.style.transform = "translate(-50%, -50%)";
            newOutline.style.height = height + "px";
            newOutline.style.width = width + "px";
            newOutline.style.border = "4px solid rgba(0, 122, 194, 0.25)";
            newOutline.style.pointerEvents = "none";

            newOutline.style.left = pos.x - 0.5 * width + "px";
            newOutline.style.top = pos.y - 0.5 * height + "px";

            newOutline.setAttribute("id", "cursor-grid");
            mapRef.current.append(newOutline);
          }
        } else {
          gridOutline?.remove();
        }
      });

      const moveHandle = mapView.on("pointer-move", (evt) => {
        pos.x = evt.x;
        pos.y = evt.y;

        // todo: cleanup detection
        const gridOutline = document.getElementById("cursor-grid");
        const customToolButton = document.querySelector(
          "[aria-label='custom grid selection button']",
        );
        const customSelected = customToolButton?.getAttribute("aria-pressed");

        if (
          mapView.allLayerViews.items.length > 1 &&
          MapContext.gridState.isGridDisplayScale &&
          customSelected === "true"
        ) {
          const multiplier = 1155581.1085772559 / mapView.scale;
          const height = th * multiplier;
          const width = tw * multiplier;

          if (gridOutline) {
            gridOutline.style.left = evt.x - 0.5 * width + "px";
            gridOutline.style.top = evt.y - 0.5 * height + "px";
            gridOutline.style.height = height + "px";
            gridOutline.style.width = width + "px";
          } else {
            const newOutline = document.createElement("div");
            newOutline.style.position = "absolute";
            // newOutline.style.transform = "translate(-50%, -50%)";
            newOutline.style.height = height + "px";
            newOutline.style.width = width + "px";
            newOutline.style.border = "4px solid rgba(0, 122, 194, 0.25)";
            newOutline.style.pointerEvents = "none";

            newOutline.style.left = evt.x - 0.5 * width + "px";
            newOutline.style.top = evt.y - 0.5 * height + "px";

            newOutline.setAttribute("id", "cursor-grid");
            mapRef.current.append(newOutline);
          }
        } else {
          gridOutline?.remove();
        }
      });

      const leaveHandle = mapView.on("pointer-leave", (evt) => {
        const gridOutline = document.getElementById("cursor-grid");
        gridOutline?.remove();
      });

      const init = () => {
        const gridOutline = document.getElementById("cursor-grid");
        const customToolButton = document.querySelector(
          "[aria-label='custom grid selection button']",
        );
        const customSelected = customToolButton?.getAttribute("aria-pressed");
        if (
          mapView.allLayerViews.items.length > 1 &&
          MapContext.gridState.isGridDisplayScale &&
          customSelected === "true"
        ) {
          const multiplier = 1155581.1085772559 / mapView.scale;
          const height = th * multiplier;
          const width = tw * multiplier;

          if (gridOutline) {
            gridOutline.style.left = pos.x - 0.5 * width + "px";
            gridOutline.style.top = pos.y - 0.5 * height + "px";
            gridOutline.style.height = height + "px";
            gridOutline.style.width = width + "px";
          } else {
            const newOutline = document.createElement("div");
            newOutline.style.position = "absolute";
            // newOutline.style.transform = "translate(-50%, -50%)";
            newOutline.style.height = height + "px";
            newOutline.style.width = width + "px";
            newOutline.style.border = "4px solid rgba(0, 122, 194, 0.25)";
            newOutline.style.pointerEvents = "none";

            newOutline.style.left = pos.x - 0.5 * width + "px";
            newOutline.style.top = pos.y - 0.5 * height + "px";

            newOutline.setAttribute("id", "cursor-grid");
            mapRef.current.append(newOutline);
          }
        } else {
          gridOutline?.remove();
        }
      };

      init();

      return { moveHandle, leaveHandle, zoomHandle };
    };

    const { moveHandle, leaveHandle, zoomHandle } = addPointerEvents();

    return () => {
      moveHandle?.remove();
      leaveHandle?.remove();
      zoomHandle?.remove();
    };
  }, [
    mapView,
    ProductsContext.state.products,
    MapContext.gridState.isGridDisplayScale,
  ]);

  return (
    <>
      {MapContext.state.isLoading && <MapLoader />}
      <MapContainer ref={mapRef}></MapContainer>
    </>
  );
};

export default Map;

