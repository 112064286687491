// Framework and third-party non-ui
import { OrderStatusCard } from "components/Card/OrderStatusCard";
import * as React from "react";

// Hooks, context, and constants

// App components

// JSON & Styles

// Third-party components (buttons, icons, etc.)

export const OrdersList = ({orders, onSelectOrder, selected, isMobile, onRemove, listOrder}) => {
    /** Internationalization */

    /** Styles */

    /** State */

    /** Actions */
    const handleSelectOrder = (order) => {
        onSelectOrder(order)
    }

    /** Effects */

    return (<div style={{height: "100%", overflow: 'auto'}}>
        {orders.map(order=>{
            const isSelected = selected === order.id
            return (
                <OrderStatusCard 
                    key={order.id}
                    id={order.id}
                    onSelect={handleSelectOrder}
                    isSelected={isSelected}
                    order={order}
                    isMobile={isMobile}
                    onRemove={onRemove}
                />
            );
        })}
    </div>)
}