// Framework and third-party non-ui
import * as React from "react";
import { useTranslation } from "react-i18next";
import UserGuide from "assets/guides/USGS topoBuilder Application User Guide.pdf";
import OnDemandUserGuide from "assets/guides/USGS OnDemand Topo User Guide.pdf";

// Hooks, context, and constants

// App components

// JSON & Styles
import {
  DrawerContainer,
  ContentContainer,
  TitleContainer,
  OptionsContainer,
  StyledSectionHeader,
  StyledLink,
  StyledText,
} from "./Help-styled";

// Third-party components (buttons, icons, etc.)
import { Typography, Link } from "@mui/material";

export const Help = () => {
  /** Internationalization */
  const { t } = useTranslation();

  /** Styles */

  /** State */

  /** Actions */

  /** Effects */

  // TODO: Move all links and text to strings file
  return (
    <DrawerContainer>
      <ContentContainer p={3}>
        {/* Title */}
        <TitleContainer>
          <Typography variant="h6" component="h2" color="textPrimary">
            {t("help.title")}
          </Typography>
        </TitleContainer>
        {/* Subtitle */}
        <TitleContainer>
          <Typography color="textSecondary">{t("help.note")}</Typography>
        </TitleContainer>
        <OptionsContainer>
          <StyledSectionHeader>topoBuilder Information</StyledSectionHeader>
          <>
            <StyledLink
              href={UserGuide}
              target="_blank"
              rel="noopener noreferrer"
            >
              topoBuilder Application User Guide
            </StyledLink>
            <StyledLink
              href={OnDemandUserGuide}
              target="_blank"
              rel="noopener noreferrer"
            >
              OnDemand Topo User Guide
            </StyledLink>
            <StyledLink
              href="https://www.usgs.gov/programs/national-geospatial-program/topobuilder"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about topoBuilder
            </StyledLink>
            <StyledLink
              href="https://www.usgs.gov/programs/national-geospatial-program/known-issues-topobuilder-and-ondemand-topo"
              target="_blank"
              rel="noopener noreferrer"
            >
              topoBuilder Known Issues
            </StyledLink>
            <StyledLink
              href="https://usgs.gov/programs/national-geospatial-program/topobuilder-release-notes"
              target="_blank"
              rel="noopener noreferrer"
            >
              topoBuilder Release Notes
            </StyledLink>
          </>

          <StyledSectionHeader>
            USGS Topographic Maps Information
          </StyledSectionHeader>
          <>
            <StyledLink
              href={t("help.helpLinks.faq.link")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("help.helpLinks.faq.title")}
            </StyledLink>
            <StyledLink
              href={t("help.helpLinks.usgsTopo.link")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("help.helpLinks.usgsTopo.title")}
            </StyledLink>
            <StyledLink
              href={t("help.helpLinks.nationalMap.link")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("help.helpLinks.nationalMap.title")}
            </StyledLink>
            <StyledLink
              href={t("help.helpLinks.usgsHistorical.link")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("help.helpLinks.usgsHistorical.title")}
            </StyledLink>
          </>

          <StyledSectionHeader>Want to provide feedback?</StyledSectionHeader>
          <StyledText>
            Contact our Help Desk at{" "}
            <Link href="mailto:tnm_help@usgs.gov">tnm_help@usgs.gov</Link>
          </StyledText>
          {/* <StyledText> {t("help.helpLinks.feedback.content")} </StyledText> */}
        </OptionsContainer>
      </ContentContainer>
    </DrawerContainer>
  );
};
