// Framework and third-party non-ui
import * as React from "react";

// Hooks, context, and constants

// App components

// JSON & Styles
import {
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalDialog,
  ModalActions,
} from "./Modal-styled";

// Third-party components (buttons, icons, etc.)
import { Backdrop, Fade, IconButton, Typography, Button } from "@mui/material";
import CloseIcon from "calcite-ui-icons-react/XIcon";

// TODO: email validation
// TODO: checkout note
// TODO: improve styling

export const Results = ({ open, setOpen, status, message }) => {
  /** Internationalization */

  /** Styles */

  /** State */

  const title =
    status === "success"
      ? "Export Succeeded"
      : status === "failed"
      ? "Export Failed"
      : "";

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <ModalContainer
      aria-labelledby="export results modal"
      aria-describedby="export results modal"
      role="main"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalContent>
          {/* Header */}
          <ModalHeader>
            <Typography
              variant="h5"
              component="h1"
              gutterBottom
              color="textPrimary"
            >
              {title}
            </Typography>
            <IconButton
              style={{ position: "absolute", right: 0 }}
              onClick={handleClose}
              aria-label="close modal button"
            >
              <CloseIcon />
            </IconButton>
          </ModalHeader>
          {/* Content */}
          <ModalDialog>
            <Typography
              color="text.default"
              style={{ fontWeight: 500, paddingBottom: 10 }}
            >
              <div dangerouslySetInnerHTML={{ __html: message }} />
            </Typography>
          </ModalDialog>
          {/* Actions */}
          <ModalActions>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Close
            </Button>
          </ModalActions>
        </ModalContent>
      </Fade>
    </ModalContainer>
  );
};
