// Styling Utilities
import { styled } from "@mui/material/styles";

// Components
import { Collapse, Container, IconButton, Paper } from "@mui/material";

import InfoIcon from "calcite-ui-icons-react/PopupIcon";
import CloseIcon from "calcite-ui-icons-react/XIcon";
// Properties

// Component Exports

export const StyledCollapse = styled(Collapse)(({ theme }) => ({
  "&.MuiCollapse-root": {
    minHeight: "50px !important",
  },
}));

export const BannerContainer = styled(Paper)(({ theme }) => ({
  display: "flex",

  height: 50,
  zIndex: 10,
  position: "relative",
  borderRadius: 0,
  background: theme.palette.grey.lightest,
  "&:hover": {},
  "&.Mui-selected": {},
}));

export const BannerContent = styled(Container)(({ theme }) => ({
  display: "flex",
  flexFlow: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  "&.MuiContainer-root": {
    maxWidth: "100%",
  },
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 0,
  //   background: theme.palette.error.main,
}));

export const InfoButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 0,
  //   background: theme.palette.blue.light,
}));

export const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.blue.dark,
}));

export const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}));
